import { useContext, useEffect, useMemo } from "react"
import { MainContext } from "../../controllers/main"
import EpisodeMobile from "../../components/episode/EpisodeMobile"
import EpisodeDesktop from "../../components/episode/EpisodeDesktop"
import { JourneysContext } from "../../controllers/journeys"
import { useParams } from "react-router-dom"
import { registerPageEvent } from "../../services/utils/utils"

const Episode = () => {
  const { episodeId, slideId } = useParams()
  const { isMobile } = useContext(MainContext)
  const { journey } = useContext(JourneysContext)

  // register google analytics event
  useEffect(() => {
    registerPageEvent("episode")
  }, [])

  // current episode
  const currentEpisode = journey?.episodes.find(
    (episode) => episode.id === episodeId
  )!
  const currentEpisodeIndex = journey?.episodes.findIndex(
    (episode) => episode.id === episodeId
  )!

  // current slide and slides info
  const currentSlideIndex = parseInt(slideId!)
  const currentSlide = currentEpisode?.slides[currentSlideIndex]
    ? currentEpisode?.slides[currentSlideIndex]
    : currentEpisode?.quiz[currentSlideIndex - currentEpisode.slides.length]!
  const currentSlideQuizResult = currentSlide
    ? currentEpisode?.result.quiz.find(
        (item) => item.slideId === currentSlide.id
      )
    : undefined
  const numberOfSlides = currentEpisode!.slides.length
  const numberOfQuiz = currentEpisode!.quiz.length
  const totalSlides = numberOfSlides + numberOfQuiz

  // next episode (the first that has to be completed or the next one if all episodes are completed)
  const nextEpisode = useMemo(() => {
    return journey!.episodes.find((episode) => !episode.result.completed)
      ? journey!.episodes.find((episode) => !episode.result.completed)!
      : journey!.episodes[currentEpisodeIndex + 1]!
  }, [currentEpisodeIndex, journey])

  return isMobile ? (
    <EpisodeMobile
      currentSlide={currentSlide}
      currentSlideIndex={currentSlideIndex}
      numberOfSlides={numberOfSlides}
      numberOfQuiz={numberOfQuiz}
      totalSlides={totalSlides}
      currentEpisode={currentEpisode}
      currentEpisodeIndex={currentEpisodeIndex}
      nextEpisode={nextEpisode}
      currentSlideQuizResult={currentSlideQuizResult}
    />
  ) : (
    <EpisodeDesktop
      currentSlide={currentSlide}
      currentSlideIndex={currentSlideIndex}
      currentSlideQuizResult={currentSlideQuizResult}
      numberOfSlides={numberOfSlides}
      numberOfQuiz={numberOfQuiz}
      totalSlides={totalSlides}
      currentEpisode={currentEpisode}
      currentEpisodeIndex={currentEpisodeIndex}
      nextEpisode={nextEpisode}
    />
  )
}

export default Episode
