import { Grid, IconButton, Stack } from "@mui/material"
import { colors } from "../../../services/config/colors"
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react"
import { ActionsContext } from "../../../controllers/actions"
import { getDaysInMonth } from "../../../services/utils/utils"
import chevronRightPrimaryIcon from "../../../assets/icons/chevron-right-primary.svg"
import calendarPrimaryIcon from "../../../assets/icons/calendar-primary.svg"
import Text from "../../global/common/Text"
import months from "../../../services/config/months"
import days from "../../../services/config/days"
import CalendarDay from "./CalendarDay"
import { MainContext } from "../../../controllers/main"
import { useTranslation } from "react-i18next"

const Calendar = ({
  daysDisabled,
  setDaysDisabled,
  setDialogOpen,
}: {
  daysDisabled: boolean
  setDaysDisabled: Dispatch<SetStateAction<boolean>>
  setDialogOpen?: Dispatch<SetStateAction<boolean>>
}) => {
  const { t } = useTranslation()
  const { isMobile } = useContext(MainContext)
  const { currentDate, historyAvailability } = useContext(ActionsContext)

  // current date selected in calendar
  const [currentMonth, setCurrentMonth] = useState<number>(
    currentDate.getMonth()
  )
  const [currentYear, setCurrentYear] = useState<number>(
    currentDate.getFullYear()
  )

  useEffect(() => {
    setCurrentMonth(currentDate.getMonth())
    setCurrentYear(currentDate.getFullYear())
  }, [currentDate])

  // get current days
  const [currentDays, setCurrentDays] = useState<(Date | string)[]>([])

  useEffect(() => {
    setCurrentDays(getDaysInMonth(currentMonth, currentYear))
  }, [currentMonth]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Stack
      style={{
        width: "100%",
        height: isMobile ? 305 : "100%",
        backgroundColor: colors.backgroundWhite,
        borderRadius: 16,
        boxShadow: "0px 0px 18.85726px 0px rgba(147, 160, 167, 0.16)",
        paddingInline: 12,
        position: "relative",
      }}
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
      }}
      aria-label={t("calendar")}
    >
      {/* month and controls row */}
      <Stack
        direction="row"
        style={{
          width: "100%",
          height: 24,
          marginTop: 20,
        }}
        alignItems="center"
        justifyContent="center"
      >
        <IconButton
          style={{
            height: 32,
            width: 32,
            padding: 0,
            opacity:
              currentMonth === historyAvailability.lastAvailablePeriod.month &&
              currentYear === historyAvailability.lastAvailablePeriod.year
                ? 0.3
                : 1,
            transition: "150ms",
          }}
          disabled={
            currentMonth === historyAvailability.lastAvailablePeriod.month &&
            currentYear === historyAvailability.lastAvailablePeriod.year
          }
          aria-disabled={
            currentMonth === historyAvailability.lastAvailablePeriod.month &&
            currentYear === historyAvailability.lastAvailablePeriod.year
              ? "true"
              : "false"
          }
          onClick={() => {
            if (currentMonth - 1 < 0) {
              setCurrentMonth(11)
              setCurrentYear(currentYear - 1)
            } else {
              setCurrentMonth(currentMonth - 1)
            }
          }}
          aria-label={t("previous_month")}
        >
          <img
            src={chevronRightPrimaryIcon}
            style={{
              width: 7,
              transform: "rotate(180deg)",
            }}
            alt=""
          />
        </IconButton>
        <img
          src={calendarPrimaryIcon}
          style={{ width: 16, height: 16, marginLeft: 4 }}
          alt=""
        />
        <Text
          fontWeight={600}
          fontSize={14}
          lineHeight="17px"
          color={colors.primary}
          textAlign="center"
          style={{ width: 130 }}
        >
          {`${t(months[currentMonth])} ${currentYear}`}
        </Text>
        <IconButton
          style={{
            height: 32,
            width: 32,
            padding: 0,
            opacity: currentMonth === new Date().getMonth() ? 0.3 : 1,
            transition: "150ms",
          }}
          disabled={currentMonth === new Date().getMonth()}
          aria-disabled={
            currentMonth === new Date().getMonth() ? "true" : "false"
          }
          onClick={() => {
            if (currentMonth + 1 > 11) {
              setCurrentMonth(0)
              setCurrentYear(currentYear + 1)
            } else {
              setCurrentMonth(currentMonth + 1)
            }
          }}
          aria-label={t("next_month")}
        >
          <img
            src={chevronRightPrimaryIcon}
            style={{
              width: 7,
            }}
            alt=""
          />
        </IconButton>
      </Stack>
      {/* days row */}
      <Stack
        direction="row"
        style={{
          marginTop: 16,
          height: 18,
        }}
        justifyContent="space-between"
      >
        {days.map((day) => (
          <Text
            key={day}
            fontWeight={600}
            fontSize={12}
            color={colors.textSecondary}
            textAlign="center"
            style={{
              textTransform: "uppercase",
              width: "100%",
            }}
            ariaHidden
          >
            {t(day).slice(0, 3)}
          </Text>
        ))}
      </Stack>
      {/* calendar */}
      <Grid container columns={7} spacing={0} style={{ rowGap: 3 }}>
        {currentDays.map((day, index) => (
          <CalendarDay
            key={index}
            day={day}
            disabled={daysDisabled}
            setDisabled={setDaysDisabled}
            setDialogOpen={setDialogOpen}
          />
        ))}
      </Grid>
    </Stack>
  )
}

export default Calendar
