import { ButtonBase, Stack } from "@mui/material"
import { colors } from "../../services/config/colors"
import BreadcrumbDesktop from "../global/desktop/BreadcrumbDesktop"
import Title from "../global/common/Title"
import Text from "../global/common/Text"
import AppearFromSide from "../animations/AppearFromSide"
import {
  desktopColumnsGap,
  desktopMaxWidth,
  footerHeight,
} from "../../services/config/constants"
import PaddingContainerDesktop from "../global/desktop/PaddingContainerDesktop"
import { useTranslation } from "react-i18next"
import FootprintCategoryCardDesktop from "./desktop/FootprintCategoryCardDesktop"
import Mission from "../../models/mission"
import FootprintMissionCard from "./common/FootprintMissionCard"
import FootprintCompensateCardDesktop from "./desktop/FootprintCompensateCardDesktop"
import { AnimatePresence, motion } from "framer-motion"
import { useContext, useRef, useState } from "react"
import chevronRightCircularIcon from "../../assets/icons/chevron-right-circular.svg"
import chevronLeftCircularIcon from "../../assets/icons/chevron-left-circular.svg"
import FootprintDoughnutChart from "./common/FootprintDoughnutChart"
import { FootprintContext } from "../../controllers/footprint"
import { TagsContext } from "../../controllers/tags"
import { FootprintSector } from "../../services/config/enum"

const FootprintDesktop = ({
  footprintMissions,
}: {
  footprintMissions: Mission[]
}) => {
  const { t } = useTranslation()
  const { userFootprint, userFormsList } = useContext(FootprintContext)
  const { mainCategories } = useContext(TagsContext)

  // selected footprint missiion for carousel
  const [selectedMission, setSelectedMission] = useState<number>(0)
  const canGoForward = useRef<boolean>(true)
  const canGoBackward = useRef<boolean>(true)

  return (
    <Stack
      style={{ width: "100%", minHeight: `calc(100% - ${footerHeight}px)` }}
    >
      {/* navbar background */}
      <div
        style={{
          width: "100%",
          height: 177,
          backgroundColor: colors.background,
        }}
      />
      {/* breadcrumb */}
      <BreadcrumbDesktop
        items={[{ label: t("carbon_footprint") }]}
        selectedItem={0}
        style={{ marginTop: 22 }}
      />
      {/* main container */}
      <PaddingContainerDesktop>
        <Stack style={{ width: "100%", maxWidth: desktopMaxWidth }}>
          {/* header */}
          <AppearFromSide fade>
            <Title
              fontSize={36}
              lineHeight="46px"
              style={{ marginTop: 22 }}
              component="h1"
            >
              {t("carbon_footprint")}
            </Title>
          </AppearFromSide>
          <AppearFromSide fade delay={0.05}>
            <Text
              fontSize={28}
              lineHeight="46px"
              fontWeight={400}
              style={{ marginTop: 8 }}
            >
              {t("discover_your_emissions")}
            </Text>
          </AppearFromSide>
          <AppearFromSide fade delay={0.1}>
            <Title
              fontSize={26}
              lineHeight="46px"
              style={{ marginTop: 50 }}
              component="h2"
            >
              {t("your_annual_emissions")}
            </Title>
          </AppearFromSide>
          <Stack
            direction="row"
            style={{
              width: "100%",
              height: 459,
              gap: desktopColumnsGap * 2,
            }}
          >
            {/* chart */}
            <AppearFromSide
              fade
              delay={0.15}
              style={{ width: "45.5%", minWidth: "45.5%", height: "100%" }}
            >
              <FootprintDoughnutChart />
            </AppearFromSide>
            {/* missions */}
            <AppearFromSide
              fade
              delay={0.15}
              x={100}
              style={{ width: "100%", height: "100%", paddingTop: 25 }}
            >
              <Stack
                style={{
                  width: "100%",
                  height: "100%",
                  position: "relative",
                }}
              >
                {footprintMissions.length ? (
                  <div style={{ width: "100%", height: 207 }}>
                    <AnimatePresence>
                      <motion.div
                        key={selectedMission}
                        initial={{ scale: 0.8, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        exit={{ scale: 0.8, opacity: 0 }}
                        transition={{
                          scale: { duration: 0.4, type: "spring" },
                          opacity: { duration: 0.1 },
                        }}
                        style={{
                          width: "100%",
                          height: "100%",
                          position: "absolute",
                        }}
                      >
                        {footprintMissions.length ? (
                          <FootprintMissionCard
                            mission={footprintMissions[selectedMission]}
                          />
                        ) : null}
                      </motion.div>
                    </AnimatePresence>
                  </div>
                ) : null}
                {/* footprint missions carousel controls */}
                {footprintMissions.length > 1 ? (
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{
                      width: "100%",
                      height: 51,
                      gap: 35,
                      marginTop: 12,
                    }}
                  >
                    <ButtonBase
                      style={{ width: 51, height: 51, borderRadius: 30 }}
                      onClick={() => {
                        if (canGoBackward.current) {
                          if (selectedMission !== 0) {
                            setSelectedMission((current) => (current -= 1))
                          } else {
                            setSelectedMission(footprintMissions.length - 1)
                          }

                          canGoBackward.current = false
                          const timeoutId = setTimeout(() => {
                            canGoBackward.current = true
                            clearTimeout(timeoutId)
                          }, 200)
                        }
                      }}
                      aria-label={t("previous_mission")}
                    >
                      <img
                        src={chevronLeftCircularIcon}
                        style={{ height: 51 }}
                        alt=""
                      />
                    </ButtonBase>
                    <Stack
                      direction="row"
                      alignItems="center"
                      style={{ height: 17, gap: 9 }}
                    >
                      {footprintMissions.map((mission, index) => (
                        <ButtonBase
                          key={index}
                          disabled={selectedMission === index}
                          style={{
                            width: selectedMission === index ? 22 : 15,
                            height: selectedMission === index ? 17 : 11.6,
                            backgroundColor:
                              selectedMission === index
                                ? colors.primary
                                : colors.background,
                            borderRadius: 5,
                            transition: "200ms",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          onClick={() => {
                            setSelectedMission(index)
                          }}
                          aria-label={t("slide_number", { count: index + 1 })}
                        >
                          <Text
                            fontSize={16}
                            fontWeight={700}
                            color={colors.textWhite}
                            style={{
                              opacity: selectedMission === index ? 1 : 0,
                              transition: "200ms",
                            }}
                          >
                            {index + 1}
                          </Text>
                        </ButtonBase>
                      ))}
                    </Stack>
                    <ButtonBase
                      style={{ width: 51, height: 51, borderRadius: 30 }}
                      onClick={() => {
                        if (canGoForward.current) {
                          if (
                            selectedMission !==
                            footprintMissions.length - 1
                          ) {
                            setSelectedMission((current) => (current += 1))
                          } else {
                            setSelectedMission(0)
                          }

                          canGoForward.current = false
                          const timeoutId = setTimeout(() => {
                            canGoForward.current = true
                            clearTimeout(timeoutId)
                          }, 200)
                        }
                      }}
                      aria-label={t("next_mission")}
                    >
                      <img
                        src={chevronRightCircularIcon}
                        style={{ height: 51 }}
                        alt=""
                      />
                    </ButtonBase>
                  </Stack>
                ) : null}
                {userFootprint ? (
                  <FootprintCompensateCardDesktop
                    style={
                      footprintMissions.length
                        ? { position: "absolute", bottom: 32 }
                        : { position: "absolute", top: 0 }
                    }
                  />
                ) : null}
              </Stack>
            </AppearFromSide>
          </Stack>
          <AppearFromSide fade delay={0.2} style={{ marginTop: 34 }}>
            <Stack
              direction="row"
              alignItems="center"
              style={{
                width: "100%",
                position: "relative",
              }}
            >
              <Title fontSize={26} lineHeight="35px" component="h2">
                {t("categories")}
              </Title>
              <Text
                fontSize={16}
                fontWeight={400}
                style={{ position: "absolute", right: 0 }}
              >
                {t("numbers_expressed_in_tons_co2e")}
              </Text>
            </Stack>
          </AppearFromSide>
          <AppearFromSide
            fade
            delay={0.25}
            style={{ marginTop: 26, marginBottom: 95 }}
          >
            <Stack
              direction="row"
              alignItems="center"
              style={{
                width: "100%",
                height: 180,
                gap: desktopColumnsGap,
              }}
            >
              <FootprintCategoryCardDesktop
                mainCategory={mainCategories[0]}
                sector={FootprintSector.home}
                value={userFootprint ? userFootprint.footprintHome : undefined}
                givenAnswers={
                  userFormsList.some((item) => item.sector === "home")
                    ? 4
                    : userFootprint
                    ? 2
                    : 0
                }
                totalAnswers={4}
              />
              <FootprintCategoryCardDesktop
                mainCategory={mainCategories[1]}
                sector={FootprintSector.shopping}
                value={
                  userFootprint ? userFootprint.footprintShopping : undefined
                }
                givenAnswers={
                  userFormsList.some((item) => item.sector === "shopping")
                    ? 6
                    : userFootprint
                    ? 2
                    : 0
                }
                totalAnswers={6}
              />
              <FootprintCategoryCardDesktop
                mainCategory={mainCategories[2]}
                sector={FootprintSector.transport}
                value={
                  userFootprint ? userFootprint.footprintTransport : undefined
                }
                givenAnswers={
                  userFormsList.some((item) => item.sector === "transport")
                    ? 10
                    : userFootprint
                    ? 4
                    : 0
                }
                totalAnswers={10}
              />
              <FootprintCategoryCardDesktop
                mainCategory={mainCategories[3]}
                sector={FootprintSector.food}
                value={userFootprint ? userFootprint.footprintFood : undefined}
                givenAnswers={userFootprint ? 1 : 0}
                totalAnswers={1}
              />
            </Stack>
          </AppearFromSide>
        </Stack>
      </PaddingContainerDesktop>
    </Stack>
  )
}

export default FootprintDesktop
