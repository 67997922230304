import ReactDOM from "react-dom/client"
import "./styles/index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { MainController } from "./controllers/main"
import { BrowserRouter } from "react-router-dom"
import { Shadows, ThemeProvider, createTheme } from "@mui/material"
import { colors } from "./services/config/colors"
import { MotionConfig } from "framer-motion"
import { Amplify } from "aws-amplify"
import "./services/i18n/i18n"
import ReactGA from "react-ga4"

// google analytics
ReactGA.initialize("G-LY24Y362WK", {
  testMode: process.env.REACT_APP_ENVIRONMENT === "dev",
})

// amplify configuration for sso
Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: process.env.REACT_APP_USER_POOL_ID!,
      userPoolClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID!,
      loginWith: {
        oauth: {
          domain: process.env.REACT_APP_DOMAIN!,
          scopes: [
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin",
          ],
          responseType: "code",
          redirectSignIn: [
            window.location.hostname === "localhost"
              ? "http://localhost:3000"
              : process.env.REACT_APP_REDIRECT_SIGN_IN!,
          ],
          redirectSignOut: [
            window.location.hostname === "localhost"
              ? "http://localhost:3000"
              : process.env.REACT_APP_REDIRECT_SIGN_IN!,
          ],
        },
      },
    },
  },
})

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <BrowserRouter>
    <ThemeProvider
      theme={createTheme({
        palette: {
          mode: "light",
          primary: {
            main: colors.primary,
          },
          secondary: {
            main: colors.primary,
          },
        },
        shadows: Array(25).fill("none") as Shadows,
      })}
    >
      <MainController>
        <MotionConfig reducedMotion="user">
          <App />
        </MotionConfig>
      </MainController>
    </ThemeProvider>
  </BrowserRouter>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
