import { ButtonBase, Stack } from "@mui/material"
import { colors } from "../../services/config/colors"
import BreadcrumbDesktop from "../global/desktop/BreadcrumbDesktop"
import Title from "../global/common/Title"
import Text from "../global/common/Text"
import footprintDoughnutImage from "../../assets/images/footprint-doughnut.svg"
import chevronRightPrimaryIcon from "../../assets/icons/chevron-right-primary.svg"
import pointsStarIcon from "../../assets/icons/points-star.svg"
import rocketIcon from "../../assets/icons/rocket.svg"
import trophyIcon from "../../assets/icons/trophy.svg"
import FadeFromTop from "../animations/FadeFromTop"
import { useContext } from "react"
import LeaderboardTop3 from "./common/LeaderboardTop3"
import {
  desktopColumnsGap,
  desktopMaxWidth,
  footerHeight,
  italianFootprintAverage,
} from "../../services/config/constants"
import AppearFromSide from "../animations/AppearFromSide"
import PaddingContainerDesktop from "../global/desktop/PaddingContainerDesktop"
import { UsersContext } from "../../controllers/users"
import { useTranslation } from "react-i18next"
import LeaderboardListItem from "./common/LeaderboardListItem"
import PointsHistoryCardDesktop from "./desktop/PointsHistoryCardDesktop"
import { useNavigate } from "react-router-dom"
import { scrollWindowToTop } from "../../services/utils/utils"
import { MissionsContext } from "../../controllers/missions"
import { FootprintContext } from "../../controllers/footprint"
import settingsIcon from "../../assets/icons/settings.svg"
import { MainContext } from "../../controllers/main"
import { ChallengeContext } from "../../controllers/challenge"

const PerformanceDesktop = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { user } = useContext(MainContext)
  const { leaderboard } = useContext(UsersContext)
  const { challenge } = useContext(ChallengeContext)
  const { completedMissionsCount } = useContext(MissionsContext)
  const { userFootprint, formsList } = useContext(FootprintContext)

  return (
    <Stack
      style={{ width: "100%", minHeight: `calc(100% - ${footerHeight}px)` }}
    >
      {/* navbar background */}
      <div
        style={{
          width: "100%",
          height: 177,
          backgroundColor: colors.background,
        }}
      />
      {/* breadcrumb */}
      <BreadcrumbDesktop
        items={[{ label: t("performance") }]}
        selectedItem={0}
        style={{ marginTop: 22 }}
      />
      {/* heading for screen readers only */}
      <Title
        component="h1"
        style={{
          width: 1,
          height: 1,
          overflow: "hidden",
          clip: "rect(1px, 1px, 1px, 1px)",
        }}
      >
        {t("Performance")}
      </Title>
      {/* recap card */}
      <PaddingContainerDesktop>
        <Stack
          direction="row"
          style={{
            width: "100%",
            maxWidth: desktopMaxWidth,
            height: "auto",
            backgroundColor: colors.background,
            marginTop: 20,
            borderRadius: 16,
            padding: 60,
            gap: 13,
          }}
        >
          {/* first column */}
          <Stack
            style={{
              width: "30%",
              minWidth: "30%",
              maxWidth: "30%",
              height: "auto",
              position: "relative",
            }}
          >
            <FadeFromTop>
              <div
                style={{
                  width: 120,
                  height: 120,
                  borderRadius: "100%",
                  backgroundImage: `url(${
                    user!.profileImage
                  }), url(https://cdn.aworld.io/users/default/profile.jpg)`,
                  backgroundSize: "105%, 100%",
                  backgroundPosition: "center",
                  position: "relative",
                }}
              />
            </FadeFromTop>
            <FadeFromTop delay={0.025} style={{ marginTop: 14 }}>
              <Title
                fontSize={30}
                lineHeight="38px"
                style={{
                  width: "90%",
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  wordWrap: "break-word",
                }}
              >
                {user?.first_name} {user?.last_name}
              </Title>
            </FadeFromTop>
            <FadeFromTop delay={0.05} style={{ marginTop: 10 }}>
              <Text fontSize={18} lineHeight="25px" fontWeight={400}>
                {t("user_active_since")} {user?.active_since}
              </Text>
            </FadeFromTop>
            <FadeFromTop
              delay={0.05}
              style={{ position: "absolute", bottom: 0 }}
            >
              <ButtonBase
                disableRipple
                aria-label={t("settings")}
                onClick={() => {
                  scrollWindowToTop()
                  navigate("/performance/settings")
                }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  style={{ height: 28, gap: 11 }}
                >
                  <img
                    src={settingsIcon}
                    style={{ width: 24, height: 24 }}
                    alt=""
                  />
                  <Title
                    fontSize={20}
                    lineHeight="28px"
                    color={colors.primary}
                    style={{ textDecoration: "underline" }}
                  >
                    {t("settings")}
                  </Title>
                </Stack>
              </ButtonBase>
            </FadeFromTop>
          </Stack>
          {/* second column */}
          <Stack
            style={{
              width: "100%",
              maxWidth: "100%",
              height: "auto",
              gap: 30,
            }}
          >
            <FadeFromTop style={{ width: "100%" }}>
              <ButtonBase
                style={{
                  width: "100%",
                  borderRadius: 16,
                  border: "1px solid " + colors.primary,
                }}
                onClick={() => {
                  scrollWindowToTop()
                  if (
                    formsList.length &&
                    formsList.some((item) => !item.isRefine)
                  ) {
                    navigate("/footprint/form")
                  } else {
                    navigate("/footprint")
                  }
                }}
                aria-label={t("your_carbon_footprint")}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  style={{
                    width: "100%",
                    height: 92,
                    borderRadius: 16,
                    backgroundColor: colors.backgroundWhite,
                    paddingLeft: 22,
                    gap: 22,
                    position: "relative",
                  }}
                >
                  <img
                    src={footprintDoughnutImage}
                    style={{ height: 58 }}
                    alt=""
                  />
                  <Stack style={{ gap: 10 }}>
                    <Title fontSize={20} lineHeight="24px">
                      {t("your_carbon_footprint")}
                    </Title>
                    <Text fontSize={16} fontWeight={400}>
                      {userFootprint
                        ? `${Math.abs(
                            Math.round(
                              ((userFootprint.footprint -
                                italianFootprintAverage) /
                                ((userFootprint.footprint +
                                  italianFootprintAverage) /
                                  2)) *
                                100
                            )
                          )}% ${
                            userFootprint.footprint > italianFootprintAverage
                              ? t("above_average")
                              : t("below_average")
                          }`
                        : t("answer_the_survey")}
                    </Text>
                  </Stack>
                  <Stack
                    justifyContent="flex-end"
                    style={{ position: "absolute", right: 85 }}
                  >
                    <Title
                      fontSize={26}
                      lineHeight="30px"
                      color={colors.primary}
                      textAlign="right"
                    >
                      {userFootprint
                        ? userFootprint.footprint.toString().replace(".", ",")
                        : "--"}
                    </Title>
                    <Text fontSize={16} lineHeight="30px" fontWeight={400}>
                      {t("tons")} CO2e
                    </Text>
                  </Stack>
                  <img
                    src={chevronRightPrimaryIcon}
                    style={{ height: 20, position: "absolute", right: 28 }}
                    alt=""
                  />
                </Stack>
              </ButtonBase>
            </FadeFromTop>
            <Stack
              direction="row"
              alignItems="center"
              style={{
                width: "100%",
                height: 160,
                gap: desktopColumnsGap,
              }}
            >
              <FadeFromTop
                style={{ width: "100%", height: "100%" }}
                delay={0.05}
              >
                <Stack
                  alignItems="center"
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: 16,
                    border: "1px solid " + colors.primary,
                    backgroundColor: colors.backgroundWhite,
                  }}
                >
                  <img
                    src={pointsStarIcon}
                    style={{ height: 30, marginTop: 21 }}
                    alt=""
                  />
                  <Title
                    fontSize={26}
                    lineHeight="28px"
                    color={colors.primary}
                    style={{ marginTop: 16 }}
                  >
                    {user?.points}
                  </Title>
                  <Text
                    fontSize={16}
                    lineHeight="18px"
                    fontWeight={400}
                    style={{ marginTop: 5 }}
                  >
                    {t("points_extended")}
                  </Text>
                </Stack>
              </FadeFromTop>
              <FadeFromTop
                style={{ width: "100%", height: "100%" }}
                delay={0.05}
              >
                <Stack
                  alignItems="center"
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: 16,
                    border: "1px solid " + colors.primary,
                    backgroundColor: colors.backgroundWhite,
                  }}
                >
                  <img
                    src={rocketIcon}
                    style={{ height: 30, marginTop: 21 }}
                    alt=""
                  />
                  <Title
                    fontSize={26}
                    lineHeight="28px"
                    color={colors.primary}
                    style={{ marginTop: 16 }}
                  >
                    {completedMissionsCount}
                  </Title>
                  <Text
                    fontSize={16}
                    lineHeight="18px"
                    fontWeight={400}
                    textAlign="center"
                    style={{ marginTop: 5, width: 100 }}
                  >
                    {completedMissionsCount === 1
                      ? t("completed_mission")
                      : t("completed_missions")}
                  </Text>
                </Stack>
              </FadeFromTop>
              <FadeFromTop
                style={{ width: "100%", height: "100%" }}
                delay={0.05}
              >
                <Stack
                  alignItems="center"
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: 16,
                    border: "1px solid " + colors.primary,
                    backgroundColor: colors.backgroundWhite,
                  }}
                >
                  <img
                    src={trophyIcon}
                    style={{ height: 30, marginTop: 21 }}
                    alt=""
                  />
                  <Title
                    fontSize={26}
                    lineHeight="28px"
                    color={colors.primary}
                    style={{ marginTop: 16 }}
                  >
                    {(challenge!.currentAmount
                      ? Math.round(
                          ((completedMissionsCount / challenge!.currentAmount) *
                            100 +
                            Number.EPSILON) *
                            100
                        ) / 100
                      : 0
                    )
                      .toString()
                      .replace(".", ",")}
                    %
                  </Title>
                  <Text
                    fontSize={16}
                    lineHeight="18px"
                    fontWeight={400}
                    textAlign="center"
                    style={{ marginTop: 5, width: 100 }}
                  >
                    {t("challenge_contribution")}
                  </Text>
                </Stack>
              </FadeFromTop>
            </Stack>
          </Stack>
        </Stack>
      </PaddingContainerDesktop>
      {/* performance and leaderboard section */}
      <PaddingContainerDesktop>
        <Stack
          direction="row"
          style={{
            width: "100%",
            maxWidth: desktopMaxWidth,
            height: "auto",
            marginTop: 60,
            marginBottom: 62,
          }}
        >
          {/* performance */}
          <AppearFromSide
            delay={0.1}
            fade
            style={{
              width: "48%",
              minWidth: "48%",
            }}
          >
            <Stack
              style={{
                width: "100%",
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                style={{ width: "100%", position: "relative" }}
              >
                <Title fontSize={26} lineHeight="46px" component="h2">
                  {t("missions")}
                </Title>
              </Stack>
              <PointsHistoryCardDesktop />
              {completedMissionsCount > 5 ? (
                <ButtonBase
                  style={{
                    width: 82,
                    height: 24.5,
                    position: "relative",
                    marginTop: 25,
                    alignSelf: "flex-end",
                  }}
                  onClick={() => {
                    scrollWindowToTop()
                    navigate("/performance/missions")
                  }}
                  aria-label={t("see_all")}
                >
                  <Text
                    fontSize={18}
                    fontWeight={700}
                    color={colors.primary}
                    style={{
                      textDecorationLine: "underline",
                      position: "absolute",
                      right: 0,
                      cursor: "pointer",
                    }}
                  >
                    {t("see_all")}
                  </Text>
                </ButtonBase>
              ) : null}
            </Stack>
          </AppearFromSide>
          {/* divider */}
          <div style={{ width: "100%" }} />
          {/* leaderboard */}
          <AppearFromSide
            x={100}
            delay={0.1}
            fade
            style={{
              width: "40%",
              minWidth: "40%",
            }}
          >
            <Stack
              style={{
                width: "100%",
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                style={{ width: "100%", position: "relative" }}
              >
                <Title fontSize={26} lineHeight="46px" component="h2">
                  {t("leaderboard")}
                </Title>
              </Stack>
              <LeaderboardTop3 style={{ marginTop: 20 }} />
              {leaderboard.length > 3 ? (
                <Stack
                  style={{
                    width: "100%",
                    height: 383,
                    borderRadius: 16,
                    backgroundColor: colors.backgroundWhite,
                    boxShadow: "0px 0px 35.68513px 0px rgba(17, 67, 97, 0.08)",
                    marginTop: 26,
                    overflow: "hidden",
                  }}
                >
                  {leaderboard.slice(3, 8).map((item, index) => (
                    <Stack key={index} style={{ height: "20%" }}>
                      {index > 0 &&
                      item.sub !== user!.sub &&
                      leaderboard[index + 2].sub !== user!.sub ? (
                        <div
                          style={{
                            width: "100%",
                            height: 2,
                            backgroundColor: colors.backgroundLight,
                          }}
                        />
                      ) : null}
                      <LeaderboardListItem
                        place={index + 4}
                        name={`${item.first_name} ${item.last_name}`}
                        points={item.points}
                        isUser={item.sub === user!.sub}
                        profileImage={item.profileImage}
                      />
                    </Stack>
                  ))}
                </Stack>
              ) : (
                <Text fontSize={18} fontWeight={400} style={{ marginTop: 34 }}>
                  {t("leaderboard_empty_state")}.
                </Text>
              )}
              {leaderboard.length > 8 ? (
                <ButtonBase
                  style={{
                    width: 82,
                    height: 24.5,
                    position: "relative",
                    marginTop: 25,
                    alignSelf: "flex-end",
                  }}
                  onClick={() => {
                    scrollWindowToTop()
                    navigate("/performance/leaderboard")
                  }}
                  aria-label={t("see_all")}
                >
                  <Text
                    fontSize={18}
                    fontWeight={700}
                    color={colors.primary}
                    style={{
                      textDecorationLine: "underline",
                      position: "absolute",
                      right: 0,
                      cursor: "pointer",
                    }}
                  >
                    {t("see_all")}
                  </Text>
                </ButtonBase>
              ) : null}
            </Stack>
          </AppearFromSide>
        </Stack>
      </PaddingContainerDesktop>
    </Stack>
  )
}

export default PerformanceDesktop
