import { Dispatch, SetStateAction, useContext } from "react"
import Text from "../../global/common/Text"
import { ActionsContext } from "../../../controllers/actions"
import { colors } from "../../../services/config/colors"
import { ButtonBase } from "@mui/material"
import months from "../../../services/config/months"
import { useTranslation } from "react-i18next"

const CalendarRowDayMobile = ({
  day,
  dayLabel,
  loading,
  setLoading,
}: {
  day: Date
  dayLabel: string
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
}) => {
  const { t } = useTranslation()
  const {
    currentDate,
    currentHistory,
    setCurrentDate,
    setSelectedActions,
    getActionsDetailedHistory,
    historyAvailability,
  } = useContext(ActionsContext)

  // if it is current date or not
  const isCurrentDate =
    currentDate.toLocaleDateString() === day.toLocaleDateString()

  // if it is current day or not
  const isCurrentDay =
    day.toLocaleDateString() === new Date().toLocaleDateString()

  // if an action has been logged in this day or not
  const actionLogged = currentHistory.find(
    (item) =>
      new Date(item.date).toLocaleDateString() === day.toLocaleDateString()
  )

  return (
    <div
      style={{
        height: 80,
        width: 40,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Text
        fontSize={12}
        fontWeight={500}
        lineHeight="16px"
        color={
          isCurrentDate
            ? colors.primary
            : isCurrentDay
            ? colors.text
            : colors.textSecondary
        }
        style={{ marginBottom: 8, transition: "150ms" }}
        ariaHidden
      >
        {dayLabel}
      </Text>
      <ButtonBase
        disabled={
          day > new Date() ||
          (historyAvailability.firstUnavailablePeriod.month ===
            new Date(day).getMonth() &&
            historyAvailability.firstUnavailablePeriod.year ===
              new Date(day).getFullYear())
        }
        style={{ width: "100%", height: 56, borderRadius: 24 }}
        onClick={async () => {
          if (!loading) {
            setSelectedActions([])
            setCurrentDate(day)

            // get detailed history for selected day but only if there is not empty
            if (
              currentHistory.find(
                (item) =>
                  new Date(item.date).toLocaleDateString() ===
                  day.toLocaleDateString()
              )
            ) {
              setLoading(true)

              await getActionsDetailedHistory(day)

              setLoading(false)
            }
          }
        }}
        aria-label={
          typeof day === "string"
            ? ""
            : isCurrentDay
            ? `${t("today")}, ${day.getDate()} ${t(months[day.getMonth()])}`
            : isCurrentDate
            ? `${day.getDate()} ${t(months[day.getMonth()])}`
            : t("go_to_specific_day", {
                day: day.getDate(),
                month: months[day.getMonth()],
              })
        }
        role="radio"
        aria-checked={isCurrentDate ? "true" : "false"}
      >
        <div
          style={{
            width: "100%",
            height: 56,
            backgroundColor:
              day > new Date() ||
              (historyAvailability.firstUnavailablePeriod.month ===
                new Date(day).getMonth() &&
                historyAvailability.firstUnavailablePeriod.year ===
                  new Date(day).getFullYear())
                ? colors.backgroundLight
                : isCurrentDate
                ? colors.primary
                : colors.backgroundWhite,
            borderRadius: 24,
            display: "flex",
            flexDirection: "column",
            border:
              isCurrentDate ||
              day > new Date() ||
              (historyAvailability.firstUnavailablePeriod.month ===
                new Date(day).getMonth() &&
                historyAvailability.firstUnavailablePeriod.year ===
                  new Date(day).getFullYear())
                ? "1.5px solid rgba(0,0,0,0)"
                : actionLogged
                ? `1.5px solid ${colors.primary}`
                : `solid 1.5px ${colors.stroke}`,
            alignItems: "center",
            transition: "background-color 120ms",
          }}
        >
          <Text
            color={
              day > new Date() ||
              (historyAvailability.firstUnavailablePeriod.month ===
                new Date(day).getMonth() &&
                historyAvailability.firstUnavailablePeriod.year ===
                  new Date(day).getFullYear())
                ? colors.textSecondary
                : isCurrentDate
                ? colors.textWhite
                : colors.text
            }
            fontSize={12}
            fontWeight={500}
            lineHeight="16px"
            style={{ marginTop: 12 }}
          >
            {day.getDate()}
          </Text>
          {actionLogged && isCurrentDate ? (
            <div
              style={{
                width: 8,
                height: 8,
                borderRadius: 4,
                backgroundColor: colors.backgroundWhite,
                marginTop: 10,
              }}
            />
          ) : actionLogged ? (
            <div
              style={{
                width: 8,
                height: 8,
                borderRadius: 4,
                backgroundColor: colors.primary,
                marginTop: 10,
              }}
            />
          ) : null}
        </div>
      </ButtonBase>
    </div>
  )
}

export default CalendarRowDayMobile
