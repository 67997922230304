import { Skeleton, Stack } from "@mui/material"
import Action from "../../../models/action"
import ActionCardHorizontal from "../common/ActionCardHorizontal"
import { useContext, useEffect } from "react"
import { ActionsContext } from "../../../controllers/actions"
import { colors } from "../../../services/config/colors"

const ActionsCarouselMobile = ({ loading }: { loading: boolean }) => {
  const { actions, currentCarouselPage, setCurrentCarouselPage } =
    useContext(ActionsContext)

  // scroll to the right page of the carousel
  useEffect(() => {
    if (currentCarouselPage > 0) {
      const carousel = document.getElementById("actions-carousel")!
      carousel.scrollTo({
        left: (window.innerWidth - 56.5) * currentCarouselPage,
        behavior: "auto",
      })
    }
  }, [])

  // https://stackoverflow.com/questions/54895665/how-could-i-use-map-to-render-a-list-of-items-but-grouped-at-every-3-items
  const group = (items: any[], n: number) =>
    items.reduce((acc, x, i) => {
      const idx = Math.floor(i / n)
      acc[idx] = [...(acc[idx] || []), x]
      return acc
    }, [])

  return (
    <Stack
      style={{
        width: "100%",
        maxWidth: "100%",
      }}
      alignItems="center"
    >
      <Stack
        id="actions-carousel"
        direction="row"
        style={{
          maxWidth: "100%",
          height: 318,
          overflowX: loading ? "hidden" : "scroll",
          paddingInline: 16,
          paddingTop: 18,
          marginTop: -18,
          gap: 12,
          scrollSnapType: "x mandatory",
        }}
        onScroll={() => {
          const carousel = document.getElementById("actions-carousel")
          setCurrentCarouselPage(
            Math.round(carousel!.scrollLeft / (window.innerWidth - 56.5))
          )
        }}
      >
        {group(actions.slice(0, 9), 3).map(
          (children: Action[], index: number) => (
            <Stack
              key={index}
              direction="column"
              style={{
                minWidth: "87.2vw",
                gap: 8,
                scrollSnapAlign: "center",
              }}
            >
              {children.map((item, index) =>
                loading ? (
                  <Skeleton
                    key={index}
                    variant="rectangular"
                    animation="wave"
                    style={{
                      width: "87.2vw",
                      minWidth: "87.2vw",
                      minHeight: 88,
                      height: 88,
                      borderRadius: 10,
                      padding: 12,
                    }}
                  />
                ) : (
                  <ActionCardHorizontal key={index} action={item} />
                )
              )}
            </Stack>
          )
        )}
      </Stack>
      <Stack direction="row" style={{ height: 8, gap: 4, marginTop: -8 }}>
        {group(actions.slice(0, 9), 3).map((action: Action, index: number) => (
          <div
            key={index}
            style={{
              width: currentCarouselPage === index ? 16 : 8,
              height: 8,
              borderRadius: 4,
              backgroundColor:
                currentCarouselPage === index
                  ? colors.primary
                  : colors.disabled,
              transition: "250ms",
              filter: loading ? "grayscale(1)" : "none",
              opacity: loading ? 0.3 : 1,
            }}
          />
        ))}
      </Stack>
    </Stack>
  )
}

export default ActionsCarouselMobile
