import { Stack } from "@mui/material"
import Action from "../../../models/action"
import { colors } from "../../../services/config/colors"
import Text from "../../global/common/Text"
import { useContext } from "react"
import { MainContext } from "../../../controllers/main"
import clockGrayIcon from "../../../assets/icons/clock-gray.svg"
import { ActionsContext } from "../../../controllers/actions"
import { useTranslation } from "react-i18next"

const ActionHistoryCard = ({
  index,
  action,
  hour,
}: {
  index: number
  action: Action
  hour: string
}) => {
  const { t } = useTranslation()
  const { isMobile } = useContext(MainContext)
  const { currentDetailedHistory } = useContext(ActionsContext)

  return (
    <Stack
      direction="row"
      style={{
        width: "100%",
        minHeight: 88,
        height: 88,
        backgroundColor: colors.backgroundWhite,
        borderRadius: 10,
        boxShadow: "0px 0px 24px 0px rgba(17, 67, 97, 0.08)",
        padding: 12,
        position: "relative",
      }}
    >
      <div
        style={{
          minWidth: 64,
          width: 64,
          height: 64,
          borderRadius: 10,
          backgroundColor: action.category.backColor,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src={action.image} style={{ width: 52, height: 52 }} alt="" />
      </div>
      <Text
        textAlign="left"
        fontSize={isMobile ? 16 : 18}
        fontWeight={500}
        lineHeight="20px"
        style={{
          marginLeft: 12,
          maxHeight: 40,
          display: "-webkit-box",
          WebkitLineClamp: 2,
          WebkitBoxOrient: "vertical",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        {action.document.title}
      </Text>
      <Stack
        direction="row"
        alignItems="center"
        style={{
          height: 16,
          position: "absolute",
          bottom: 12,
          left: 88,
          gap: 4,
        }}
      >
        <img src={clockGrayIcon} style={{ width: 16 }} alt="" />
        <Text
          fontSize={isMobile ? 10 : 12}
          fontWeight={400}
          color={colors.textSecondary}
        >
          {t("logged_at", { time: hour })}{" "}
          {action.timesPerDay > 1
            ? `· ${t("count_of_total", {
                count:
                  currentDetailedHistory
                    .slice(0, index)
                    .filter((item) => item.action.id === action.id).length + 1,
                total: action.timesPerDay,
              })}`
            : null}
        </Text>
      </Stack>
    </Stack>
  )
}

export default ActionHistoryCard
