import { ButtonBase, Stack } from "@mui/material"
import { colors } from "../../../services/config/colors"
import Text from "../../global/common/Text"
import Title from "../../global/common/Title"
import { motion, useReducedMotion } from "framer-motion"
import { Dispatch, SetStateAction, useContext, useState } from "react"
import { MissionsContext } from "../../../controllers/missions"
import { useTranslation } from "react-i18next"
import trophyWhiteIcon from "../../../assets/icons/trophy-white.svg"
import checkWhiteIcon from "../../../assets/icons/check-white.svg"
import PointsBadge from "../../global/common/PointsBadge"
import {
  MissionPeriod,
  MissionSubType,
  MissionType,
} from "../../../services/config/enum"
import { JourneysContext } from "../../../controllers/journeys"
import { ActionsContext } from "../../../controllers/actions"
import bellIcon from "../../../assets/icons/bell.svg"
import starsIcon from "../../../assets/icons/stars.png"
import {
  daysBetweenDates,
  hoursUntilMidnight,
} from "../../../services/utils/utils"

const MissionCardMobile = ({
  index,
  slidesLength,
  color,
  title,
  titleColor = colors.text,
  goButtonColor,
  image,
  current,
  target,
  points,
  satisfied,
  missionType,
  missionSubType,
  missionTypeId,
  period,
  setBottomSheetOpen,
  onClick,
  end,
  currentPerDay,
  targetPerDay,
  setComeBackAlertOpen,
  setCarouselControlsHidden,
  updatedAt,
}: {
  index: number
  slidesLength: number
  color: string
  title: string
  titleColor?: string
  goButtonColor: string
  image: string
  current: number
  target: number
  points: number
  satisfied: boolean
  missionType: MissionType
  missionSubType: MissionSubType
  missionTypeId: string
  period: MissionPeriod
  setBottomSheetOpen: Dispatch<SetStateAction<boolean>>
  onClick: () => void
  end?: string
  currentPerDay?: number
  targetPerDay?: number
  setComeBackAlertOpen: Dispatch<SetStateAction<boolean>>
  setCarouselControlsHidden: Dispatch<SetStateAction<boolean>>
  updatedAt?: string
}) => {
  const { t } = useTranslation()
  const shouldReduceMotion = useReducedMotion()
  const { currentSlide } = useContext(MissionsContext)
  const { journey, dailyEpisode } = useContext(JourneysContext)
  const { actions } = useContext(ActionsContext)

  // animation
  const [cardOpen, setCardOpen] = useState<boolean>(false)

  return (
    <motion.div
      style={{
        width: 256,
        height: "100%",
        maxHeight: 340,
        display: "flex",
        justifyContent: "center",
        position: "relative",
      }}
      onClick={() => {
        if (Math.abs(currentSlide % slidesLength) === index && !satisfied) {
          if (
            (currentPerDay &&
              targetPerDay &&
              currentPerDay >= targetPerDay &&
              updatedAt &&
              new Date(updatedAt).toLocaleDateString() ===
                new Date().toLocaleDateString()) ||
            (missionType === MissionType.LEARN &&
              current < target &&
              updatedAt &&
              new Date(updatedAt).toLocaleDateString() ===
                new Date().toLocaleDateString())
          ) {
            setComeBackAlertOpen(true)
            return
          }

          if (shouldReduceMotion) {
            onClick()
          } else {
            setBottomSheetOpen(false)
            setCarouselControlsHidden(true)
            setCardOpen(true)
            setTimeout(() => {
              onClick()
            }, 220)
          }
        }
      }}
      animate={cardOpen ? "open" : "closed"}
      variants={{
        open: { scale: 5 },
        closed: { scale: 1 },
      }}
      transition={{ scale: { type: "spring", duration: 0.5 } }}
      initial={false}
      aria-hidden={
        Math.abs(currentSlide % slidesLength) === index ? "false" : "true"
      }
    >
      {satisfied ? (
        <Stack
          alignItems="center"
          style={{
            width: "100%",
            height: "calc(100% - 23px)",
            borderRadius: 20,
            backgroundColor: color,
            paddingTop: 22,
            paddingInline: 16,
            position: "relative",
            boxShadow: "0px 4.8px 19px rgba(22, 59, 99, 0.20)",
          }}
        >
          <img src={trophyWhiteIcon} style={{ width: 40 }} alt="" />
          <Title
            fontSize={18}
            lineHeight="21px"
            color={colors.textWhite}
            textAlign="center"
            style={{ marginTop: 18 }}
          >
            {t("mission_completed").toUpperCase() + "!"}
          </Title>
          <Text
            fontSize={16}
            fontWeight={500}
            lineHeight="19px"
            color={colors.textWhite}
            textAlign="center"
            style={{ width: "100%", marginTop: 8, fontStyle: "italic" }}
            paddingLeft={16}
            paddingRight={16}
          >
            {title}
          </Text>
          <PointsBadge
            points={points}
            style={{ position: "absolute", bottom: 91 }}
          />
          <Text
            fontSize={14}
            lineHeight="16px"
            fontWeight={400}
            textAlign="center"
            color={colors.textWhite}
            style={{ position: "absolute", bottom: 31 }}
            paddingLeft={32}
            paddingRight={32}
          >
            {period === MissionPeriod.DAILY
              ? t("come_back_tomorrow")
              : period === MissionPeriod.WEEKLY
              ? t("come_back_next_week")
              : period === MissionPeriod.CUSTOM && end
              ? t("come_back_next_month", {
                  date: new Date(end).toLocaleDateString(),
                })
              : null}
          </Text>
        </Stack>
      ) : (
        <div
          style={{
            width: "100%",
            height: "calc(100% - 23px)",
            borderRadius: 20,
            backgroundColor: colors.backgroundWhite,
          }}
        >
          <ButtonBase
            disabled={
              Math.abs(currentSlide % slidesLength) !== index ? true : false
            }
            style={{
              width: "100%",
              height: "100%",
              backgroundImage: `url(${image})`,
              backgroundSize: "cover",
              borderRadius: 20,
              boxShadow: "0px 4.8px 19px rgba(22, 59, 99, 0.20)",
              opacity: cardOpen ? 0 : 1,
              transition: "200ms",
              position: "relative",
            }}
            aria-label={t("go_to_specific_mission", { mission: title })}
          >
            <Stack
              style={{
                width: "100%",
                height: "100%",
                borderRadius: 20,
                paddingInline: 21,
                position: "relative",
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                style={{
                  width: "auto",
                  height: 28,
                  position: "absolute",
                  top: 19.5,
                  right: 21,
                  gap: 10,
                }}
              >
                {/* period badge */}
                {period === MissionPeriod.CUSTOM &&
                end &&
                daysBetweenDates(new Date(), new Date(end), true) <=
                  0 ? null : (
                  <Stack
                    direction="row"
                    alignItems="center"
                    style={{
                      width: "auto",
                      height: "100%",
                      paddingInline: 8,
                      backgroundColor: colors.backgroundWhite,
                      borderRadius: 4,
                      border: "1px solid " + colors.secondary,
                      gap: 6,
                    }}
                  >
                    {period === MissionPeriod.DAILY ? (
                      <img src={bellIcon} style={{ height: 14 }} alt="" />
                    ) : period === MissionPeriod.CUSTOM ? (
                      <img src={starsIcon} style={{ height: 18 }} alt="" />
                    ) : null}
                    {period === MissionPeriod.DAILY ? (
                      <Title fontSize={14} color={colors.secondary}>
                        {`-${hoursUntilMidnight()} ${t("hours")}`}
                      </Title>
                    ) : period === MissionPeriod.WEEKLY ? (
                      <Title fontSize={14} color={colors.secondary}>
                        {t(period.toLowerCase())}
                      </Title>
                    ) : period === MissionPeriod.CUSTOM && end ? (
                      <Title fontSize={14} color={colors.secondary}>
                        -{daysBetweenDates(new Date(), new Date(end), true)}{" "}
                        {t("days")}
                      </Title>
                    ) : null}
                  </Stack>
                )}
                {/* points badge */}
                <PointsBadge
                  points={points}
                  style={{
                    height: "100%",
                  }}
                />
              </Stack>
              <Title
                fontSize={16}
                lineHeight="19px"
                color={titleColor}
                style={{
                  width: "100%",
                  marginTop: 66,
                }}
              >
                {title}
              </Title>
              {/* journey */}
              {missionSubType === MissionSubType.JOURNEY && (
                <div
                  style={{
                    width: "56%",
                    height: 37,
                    display: "flex",
                    alignItems: "center",
                    marginTop: 16,
                  }}
                >
                  <Title
                    fontSize={15}
                    lineHeight="18px"
                    color={colors.textWhite}
                    style={{
                      display: "-webkit-box",
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: "vertical",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                  >
                    {journey?.title}
                  </Title>
                </div>
              )}
              {missionSubType === MissionSubType.JOURNEY && (
                <div
                  style={{
                    width: 100,
                    height: 100,
                    borderRadius: 18,
                    backgroundColor: color,
                    position: "absolute",
                    right: 66,
                    top: 155,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={journey?.image}
                    style={{
                      width: 88,
                      height: 88,
                      borderRadius: 14,
                      objectFit: "cover",
                    }}
                    alt=""
                  />
                </div>
              )}
              {missionSubType === MissionSubType.JOURNEY && (
                <Stack
                  style={{
                    width: 30,
                    position: "absolute",
                    top: 94.5,
                    right: 12,
                    gap: 10,
                  }}
                >
                  {journey?.episodes.slice(0, 5).map((episode) => (
                    <img
                      key={episode.id}
                      src={episode.image}
                      style={{
                        width: 30,
                        height: 30,
                        minHeight: 30,
                        borderRadius: 6,
                      }}
                      alt={t("episode")}
                    />
                  ))}
                </Stack>
              )}
              {/* daily episode daily */}
              {missionSubType === MissionSubType.DAILYEPISODE &&
              period === MissionPeriod.DAILY &&
              dailyEpisode ? (
                <Stack
                  alignItems="center"
                  style={{
                    width: "100%",
                    position: "absolute",
                    top: 105,
                    left: 0,
                    gap: 6,
                  }}
                >
                  <img
                    src={dailyEpisode?.image}
                    style={{
                      width: 116,
                      height: 106,
                      borderRadius: 14,
                      objectFit: "cover",
                    }}
                    alt=""
                  />
                  <Title
                    fontSize={15}
                    lineHeight="18px"
                    color={colors.textWhite}
                    textAlign="center"
                    style={{
                      width: "55%",
                      display: "-webkit-box",
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: "vertical",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                  >
                    {dailyEpisode?.title}
                  </Title>
                </Stack>
              ) : null}
              {/* daily episode weekly and custom */}
              {missionSubType === MissionSubType.DAILYEPISODE &&
              (period === MissionPeriod.WEEKLY ||
                period === MissionPeriod.CUSTOM) &&
              dailyEpisode ? (
                <Stack
                  direction="row"
                  alignItems="center"
                  style={{
                    width: "calc(100% - 60px)",
                    height: 120,
                    position: "absolute",
                    top: 130,
                    gap: 8,
                  }}
                >
                  <img
                    src={dailyEpisode?.image}
                    style={{
                      width: "60%",
                      height: "100%",
                      borderRadius: 14,
                      objectFit: "cover",
                    }}
                    alt=""
                  />
                  <Stack style={{ width: "40%", height: "100%", gap: 8 }}>
                    <div
                      style={{
                        width: "100%",
                        height: "calc(50% - 4px)",
                        borderRadius: 14,
                        backgroundImage: `url(${
                          dailyEpisode?.slides.find(
                            (slide) => slide.slideType === "TitleSlide"
                          )?.background
                        })`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    >
                      <img
                        src={
                          (dailyEpisode?.slides as any[]).filter(
                            (item) => item.image
                          )[0].image.url
                        }
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: 14,
                          objectFit: "cover",
                        }}
                        alt=""
                      />
                    </div>
                    <div
                      style={{
                        width: "100%",
                        height: "calc(50% - 4px)",
                        borderRadius: 14,
                        backgroundImage: `url(${
                          dailyEpisode?.slides.find(
                            (slide) => slide.slideType === "TitleSlide"
                          )?.background
                        })`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    >
                      <img
                        src={
                          (dailyEpisode?.slides as any[]).filter(
                            (item) => item.image
                          )[1] &&
                          (dailyEpisode?.slides as any[]).filter(
                            (item) => item.image
                          )[1].image
                            ? (dailyEpisode?.slides as any[]).filter(
                                (item) => item.image
                              )[1].image.url
                            : dailyEpisode!.image
                        }
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: 14,
                          objectFit: "cover",
                        }}
                        alt=""
                      />
                    </div>
                  </Stack>
                </Stack>
              ) : null}
              {/* single action */}
              {actions.some((action) => action.id === missionTypeId) && (
                <img
                  src={
                    actions.find((action) => action.id === missionTypeId)?.image
                  }
                  style={{
                    width: 160,
                    height: 160,
                    objectFit: "cover",
                    position: "absolute",
                    top: 110,
                    left: 0,
                    right: 0,
                    marginInline: "auto",
                  }}
                  alt=""
                />
              )}
            </Stack>
            {/* go button */}
            <Title
              fontSize={14}
              color={goButtonColor}
              style={{
                position: "absolute",
                bottom: 30,
                textDecoration: "underline",
              }}
            >
              {t("go")}
            </Title>
          </ButtonBase>
        </div>
      )}
      {/* completion dot */}
      <div
        style={{
          width: 46,
          height: 46,
          borderRadius: "100%",
          backgroundColor: satisfied ? color : colors.backgroundWhite,
          border: "2px solid " + (satisfied ? colors.backgroundWhite : color),
          position: "absolute",
          bottom: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {satisfied ? (
          <img
            src={checkWhiteIcon}
            style={{ width: 24, transform: "scale(1.1)" }}
            alt={t("mission_completed")}
          />
        ) : (
          <Text
            fontSize={14}
            lineHeight="19.6px"
            fontWeight={700}
            color={color}
            ariaLabel={t("mission_progression_count", {
              count: current,
              total: target,
            })}
          >
            {`${current}/${target}`}
          </Text>
        )}
      </div>
    </motion.div>
  )
}

export default MissionCardMobile
