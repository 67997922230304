import { Stack } from "@mui/material"
import { Dispatch, SetStateAction, useContext } from "react"
import { MainContext } from "../../controllers/main"
import OnboardingPostSignInMobile from "./OnboardingPostSignInMobile"

const OnboardingPostSignInDesktop = ({
  setOpen,
  withBackground = true,
}: {
  setOpen?: Dispatch<SetStateAction<boolean>>
  withBackground?: boolean
}) => {
  const { windowHeight } = useContext(MainContext)

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      style={{
        width: "100%",
        height: windowHeight,
        backgroundColor: withBackground ? "#252525" : "rgb(37, 37, 37, 0.5)",
      }}
    >
      <div style={{ width: 505, height: windowHeight - 72 }}>
        <OnboardingPostSignInMobile
          height="100%"
          borderRadius={20}
          continueButtonBottom={58}
          continueButtonWidth={330}
          setOpen={setOpen}
        />
      </div>
    </Stack>
  )
}

export default OnboardingPostSignInDesktop
