import { LinearProgress, Stack } from "@mui/material"
import { desktopMaxWidth, footerHeight } from "../../services/config/constants"
import { colors } from "../../services/config/colors"
import BreadcrumbDesktop from "../global/desktop/BreadcrumbDesktop"
import { useTranslation } from "react-i18next"
import PaddingContainerDesktop from "../global/desktop/PaddingContainerDesktop"
import AppearFromSide from "../animations/AppearFromSide"
import Title from "../global/common/Title"
import { useNavigate } from "react-router-dom"
import LeaderboardTop3 from "../performance/common/LeaderboardTop3"
import { useContext, useEffect, useRef } from "react"
import { UsersContext } from "../../controllers/users"
import LeaderboardListItemCard from "./desktop/LeaderboardListItemCard"
import FadeFromTop from "../animations/FadeFromTop"
import { MainContext } from "../../controllers/main"

const LeaderboardDesktop = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { user } = useContext(MainContext)
  const { leaderboard, leaderboardNextToken, getLeaderboard } =
    useContext(UsersContext)

  // leaderboard loading and next token refs
  const loading = useRef<boolean>(false)
  const nextToken = useRef<string | null>(leaderboardNextToken)

  // update next token ref when state changes
  useEffect(() => {
    nextToken.current = leaderboardNextToken
  }, [leaderboardNextToken])

  // add event listener on main container to check scroll and paginate leaderboard list
  useEffect(() => {
    const onContainerScroll = async () => {
      const container = document.getElementById("main-container")!

      if (
        container.scrollTop + window.innerHeight + 400 >=
          container.scrollHeight &&
        nextToken.current &&
        !loading.current
      ) {
        loading.current = true
        await getLeaderboard(false)
        loading.current = false
      }
    }

    const container = document.getElementById("main-container")!
    container.addEventListener("scroll", onContainerScroll)

    return () => {
      container.removeEventListener("scroll", onContainerScroll)
    }
  }, [getLeaderboard])

  return (
    <Stack
      style={{ width: "100%", minHeight: `calc(100% - ${footerHeight}px)` }}
    >
      {/* navbar background */}
      <div
        style={{
          width: "100%",
          height: 177,
          backgroundColor: colors.background,
        }}
      />
      {/* breadcrumb */}
      <BreadcrumbDesktop
        items={[
          {
            label: t("performance"),
            onClick: () => {
              navigate("/performance")
            },
          },
          {
            label: t("leaderboard"),
          },
        ]}
        selectedItem={1}
        style={{ marginTop: 22 }}
      />
      {/* main container */}
      <PaddingContainerDesktop>
        <Stack style={{ width: "100%", maxWidth: desktopMaxWidth }}>
          {/* header */}
          <AppearFromSide fade>
            <Title
              fontSize={36}
              lineHeight="46px"
              style={{ marginTop: 22 }}
              component="h1"
            >
              {t("leaderboard")}
            </Title>
          </AppearFromSide>
          <Stack
            alignItems="center"
            style={{
              width: "57.14%",
              height: "auto",
              marginBottom: 60,
            }}
          >
            {/* top 3 */}
            <FadeFromTop style={{ width: "90%", marginTop: 44 }}>
              <LeaderboardTop3 />
            </FadeFromTop>
            {/* list */}
            <AppearFromSide fade style={{ width: "100%", marginTop: 40 }}>
              <Stack
                style={{
                  width: "100%",
                  gap: 16,
                  position: "relative",
                  paddingBottom: 4,
                }}
              >
                {leaderboard.slice(3).map((item, index) => (
                  <LeaderboardListItemCard
                    key={index}
                    place={index + 4}
                    name={`${item.first_name} ${item.last_name}`}
                    points={item.points}
                    isUser={item.sub === user!.sub}
                    profileImage={item.profileImage}
                  />
                ))}
                {loading.current && (
                  <LinearProgress
                    style={{
                      width: "100%",
                      position: "absolute",
                      bottom: 0,
                      borderRadius: 14,
                    }}
                  />
                )}
              </Stack>
            </AppearFromSide>
          </Stack>
        </Stack>
      </PaddingContainerDesktop>
    </Stack>
  )
}

export default LeaderboardDesktop
