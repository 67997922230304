import { Stack } from "@mui/material"
import { CSSProperties } from "react"
import PaddingContainerDesktop from "./PaddingContainerDesktop"
import awLogoWhite from "../../../assets/images/aw-logo-white.svg"
import { desktopMaxWidth } from "../../../services/config/constants"
// import { useTranslation } from "react-i18next"
// import facebookLogo from "../../../assets/images/facebook-logo.svg"
// import linkedinLogo from "../../../assets/images/linkedin-logo.svg"
// import instagramLogo from "../../../assets/images/instagram-logo.svg"
// import emailLogo from "../../../assets/images/email-logo.svg"
// import Text from "../common/Text"
// import { colors } from "../../../services/config/colors"

const FooterDesktop = ({ style }: { style?: CSSProperties }) => {
  // const { t } = useTranslation()

  return (
    <footer>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        style={{
          width: "100%",
          height: 150,
          minHeight: 150,
          gap: 50,
          backgroundColor: "#274150",
          ...style,
        }}
      >
        <PaddingContainerDesktop>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            style={{
              width: "100%",
              height: 30,
              maxWidth: desktopMaxWidth,
              position: "relative",
            }}
          >
            <img
              src={awLogoWhite}
              style={{ width: 180, height: 30, position: "absolute", left: 0 }}
              alt=""
            />
            {/* <Stack alignItems="center">
              <Text fontSize={12} lineHeight="16.5px" color={colors.textWhite}>
                Copyright 2023@ Aworld Srl Società Benefit · PI: 11867110014.
                Via Agostino da Montefeltro, 2, 10134 Torino TO.{" "}
                {t("all_rights_reserved")}.
              </Text>
              <ButtonBase
                disableRipple
                aria-label={t("privacy_policy")}
                style={{ width: "fit-content" }}
                onClick={() => {
                  window.open("https://aworld.org/privacy-policy-app")
                }}
              >
                <Text
                  fontSize={12}
                  lineHeight="16.5px"
                  color={colors.textWhite}
                  style={{ textDecoration: "underline" }}
                >
                  {t("privacy_policy")}
                </Text>
              </ButtonBase>
            </Stack> */}
            {/* <Stack
              direction="row"
              role="region"
              style={{ height: 30, position: "absolute", right: 0, gap: 20 }}
            >
              <ButtonBase
                aria-label="Facebook"
                onClick={() => {
                  window.open("https://www.facebook.com/aworld.actnow")
                }}
              >
                <img
                  src={facebookLogo}
                  style={{ width: 30, height: 30 }}
                  alt="Facebook"
                />
              </ButtonBase>
              <ButtonBase
                aria-label="Linkedin"
                onClick={() => {
                  window.open("https://it.linkedin.com/company/aworldorg")
                }}
              >
                <img
                  src={linkedinLogo}
                  style={{ width: 30, height: 30 }}
                  alt="Linkedin"
                />
              </ButtonBase>
              <ButtonBase
                aria-label="Instagram"
                onClick={() => {
                  window.open("https://www.instagram.com/aworld.actnow")
                }}
              >
                <img
                  src={instagramLogo}
                  style={{ width: 30, height: 30 }}
                  alt="Instagram"
                />
              </ButtonBase>
              <ButtonBase
                aria-label="Email"
                onClick={() => {
                  window.open(
                    "mailto:marco@aworld.org;%20alex@aworld.org;%20stefania.f@aworld.org;%20beatrice.a@aworld.org;%20angela.b@aworld.org;%20laura.p@aworld.org;%20andrea.g@aworld.org;%20giuliana.c@aworld.org;%20filadelfo.a@aworld.org"
                  )
                }}
              >
                <img
                  src={emailLogo}
                  style={{ width: 30, height: 30 }}
                  alt="Email"
                />
              </ButtonBase>
            </Stack> */}
          </Stack>
        </PaddingContainerDesktop>
      </Stack>
    </footer>
  )
}

export default FooterDesktop
