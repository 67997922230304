import { ButtonBase, Stack } from "@mui/material"
import BreadcrumbDesktop from "../global/desktop/BreadcrumbDesktop"
import { useNavigate } from "react-router-dom"
import Episode from "../../models/episode"
import { colors } from "../../services/config/colors"
import closeEpisodeIcon from "../../assets/icons/close-episode.svg"
import Slide from "../../models/slide"
import EpisodeMobile from "./EpisodeMobile"
import chevronLeftCircularIcon from "../../assets/icons/chevron-left-circular.svg"
import chevronRightCircularIcon from "../../assets/icons/chevron-right-circular.svg"
import { useContext } from "react"
import { JourneysContext } from "../../controllers/journeys"
import QuizResult from "../../models/quizResult"
import { useTranslation } from "react-i18next"
import { focusElement } from "../../services/utils/utils"

const EpisodeDesktop = ({
  currentSlide,
  currentSlideIndex,
  currentSlideQuizResult,
  numberOfSlides,
  numberOfQuiz,
  totalSlides,
  currentEpisode,
  currentEpisodeIndex,
  nextEpisode,
}: {
  currentSlide: Slide
  currentSlideIndex: number
  currentSlideQuizResult?: QuizResult
  numberOfSlides: number
  numberOfQuiz: number
  totalSlides: number
  currentEpisode: Episode
  currentEpisodeIndex: number
  nextEpisode?: Episode
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { journey } = useContext(JourneysContext)

  return (
    <Stack
      alignItems="center"
      style={{
        width: "100%",
        height: "100%",
        minHeight: "100%",
        maxHeight: "100%",
        position: "relative",
        zIndex: 100,
        backgroundColor: "#1A1A1A",
      }}
    >
      {/* header */}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        style={{
          width: "100%",
          height: 52,
          backgroundColor: "#000000",
          paddingInline: 30,
          position: "relative",
        }}
      >
        <BreadcrumbDesktop
          items={[
            {
              label: t("journey"),
              onClick: () => {
                navigate("/journey")
              },
            },
            {
              label: currentEpisode.title,
            },
          ]}
          selectedItem={1}
          withPadding={false}
          textColor={colors.textWhite}
        />
        <ButtonBase
          style={{ width: 38, height: 38, position: "absolute", right: 16 }}
          onClick={() => {
            navigate("/journey")
          }}
          aria-label={t("go_back")}
        >
          <img
            src={closeEpisodeIcon}
            style={{ width: 38, height: 38 }}
            alt=""
          />
        </ButtonBase>
      </Stack>
      {/* episode */}
      <Stack
        alignItems="center"
        direction="row"
        style={{
          height: "calc(100% - 52px)",
          gap: 35,
        }}
      >
        <ButtonBase
          disabled={
            (!currentEpisodeIndex && !currentSlideIndex) ||
            !currentSlide ||
            (currentSlide &&
              currentSlide.slideType === "QuizSlide" &&
              !currentEpisode.result.quiz.some(
                (item) => item.slideId === currentSlide.id
              )) ||
            (currentSlide &&
              currentSlide.slideType === "QuizSlide" &&
              !currentEpisode.result.completed)
          }
          style={{
            width: 50,
            height: 50,
            borderRadius: 25,
            backgroundColor: colors.backgroundWhite,
          }}
          onClick={() => {
            focusElement("slide-container")

            const pathname = window.location.pathname

            // go to previous episode
            if (currentSlideIndex === 0) {
              navigate(
                "/journey/" +
                  journey?.episodes[currentEpisodeIndex - 1].id +
                  "/0"
              )
              return
            }

            // go to previous slide
            if (currentSlideIndex > 0) {
              navigate(
                pathname.slice(0, pathname.lastIndexOf("/")) +
                  "/" +
                  (currentSlideIndex - 1)
              )
            }
          }}
          aria-label={t("previous_slide")}
        >
          <img
            src={chevronLeftCircularIcon}
            style={{
              width: "100%",
              height: "100%",
              transition: "150ms",
              opacity:
                (!currentEpisodeIndex && !currentSlideIndex) ||
                !currentSlide ||
                (currentSlide &&
                  currentSlide.slideType === "QuizSlide" &&
                  !currentEpisode.result.quiz.some(
                    (item) => item.slideId === currentSlide.id
                  )) ||
                (currentSlide &&
                  currentSlide.slideType === "QuizSlide" &&
                  !currentEpisode.result.completed)
                  ? 0.3
                  : 1,
            }}
            alt=""
          />
        </ButtonBase>
        <div
          id="slide-container"
          style={{
            width: 375,
            height: "calc(100% - 60px)",
            maxHeight: 780,
          }}
          aria-label={
            currentSlideIndex === totalSlides
              ? t("end_of_episode_feedback")
              : t("slide_count_of_total", {
                  count: currentSlideIndex + 1,
                  total: totalSlides,
                })
          }
          role="region"
          aria-roledescription={t("content")}
          tabIndex={0}
        >
          <EpisodeMobile
            currentSlide={currentSlide}
            currentSlideIndex={currentSlideIndex}
            numberOfSlides={numberOfSlides}
            numberOfQuiz={numberOfQuiz}
            totalSlides={totalSlides}
            currentEpisode={currentEpisode}
            currentEpisodeIndex={currentEpisodeIndex}
            nextEpisode={nextEpisode}
            currentSlideQuizResult={currentSlideQuizResult}
          />
        </div>
        <ButtonBase
          disabled={
            (currentSlide &&
              currentSlide.slideType === "QuizSlide" &&
              !currentEpisode.result.quiz.some(
                (item) => item.slideId === currentSlide.id
              )) ||
            (!nextEpisode &&
              currentSlideIndex ===
                currentEpisode.slides.length + currentEpisode.quiz.length)
          }
          style={{
            width: 50,
            height: 50,
            borderRadius: 25,
            backgroundColor: colors.backgroundWhite,
          }}
          onClick={() => {
            focusElement("slide-container")

            const pathname = window.location.pathname

            // go to next episode
            if (
              currentSlideIndex ===
                currentEpisode.slides.length + currentEpisode.quiz.length &&
              nextEpisode
            ) {
              navigate("/journey/" + nextEpisode!.id + "/0")
              return
            }

            // go to next slide
            if (currentSlideIndex + 1 <= totalSlides) {
              navigate(
                pathname.slice(0, pathname.lastIndexOf("/")) +
                  "/" +
                  (currentSlideIndex + 1)
              )
            }
          }}
          aria-label={t("next_slide")}
        >
          <img
            src={chevronRightCircularIcon}
            style={{
              width: "100%",
              height: "100%",
              transition: "150ms",
              opacity:
                (currentSlide &&
                  currentSlide.slideType === "QuizSlide" &&
                  !currentEpisode.result.quiz.some(
                    (item) => item.slideId === currentSlide.id
                  )) ||
                (!nextEpisode &&
                  currentSlideIndex ===
                    currentEpisode.slides.length + currentEpisode.quiz.length)
                  ? 0.3
                  : 1,
            }}
            alt=""
          />
        </ButtonBase>
      </Stack>
    </Stack>
  )
}

export default EpisodeDesktop
