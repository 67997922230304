import { useContext, useEffect, useMemo, useState } from "react"
import ActionsMobile from "../../components/actions/ActionsMobile"
import { MainContext } from "../../controllers/main"
import ActionsDesktop from "../../components/actions/ActionsDesktop"
import { ActionsContext } from "../../controllers/actions"
import { MissionsContext } from "../../controllers/missions"
import { MissionSubType, MissionType } from "../../services/config/enum"
import {
  registerPageEvent,
  scrollWindowToTop,
} from "../../services/utils/utils"
import { useNavigate } from "react-router-dom"

const Actions = () => {
  const navigate = useNavigate()
  const { isMobile } = useContext(MainContext)
  const { currentDate, setSelectedCategory } = useContext(ActionsContext)
  const { missions } = useContext(MissionsContext)

  // register google analytics event
  useEffect(() => {
    registerPageEvent("actions")
  }, [])

  // no actions selected alert
  const [noActionsAlertOpen, setNoActionsAlertOpen] = useState<boolean>(false)

  // if currentDate === today or not
  const isCurrentDate = useMemo(() => {
    if (currentDate.toLocaleDateString() === new Date().toLocaleDateString()) {
      return true
    }

    return false
  }, [currentDate])

  // loading for detailed history get
  const [loading, setLoading] = useState<boolean>(false)

  // actions missions
  const actionsMissions = useMemo(() => {
    return missions
      .filter((mission) => mission.missionType === MissionType.ACT)
      .map((mission) => {
        return {
          title: mission.title,
          progress: `${mission.current}/${mission.target}`,
          points: mission.points,
          satisfied: mission.satisfied,
          onClick: () => {
            if (
              mission.missionSubType === MissionSubType.ALL ||
              mission.missionSubType === MissionSubType.FIXED
            ) {
              setSelectedCategory("catDefault")
            } else {
              setSelectedCategory(
                "cat" +
                  mission.missionSubType.charAt(0) +
                  mission.missionSubType.toLowerCase().slice(1)
              )
            }
            scrollWindowToTop()
            navigate("/actions/explore")
          },
        }
      })
  }, [missions]) // eslint-disable-line react-hooks/exhaustive-deps

  return isMobile ? (
    <ActionsMobile
      noActionsAlertOpen={noActionsAlertOpen}
      setNoActionsAlertOpen={setNoActionsAlertOpen}
      isCurrentDate={isCurrentDate}
      loading={loading}
      setLoading={setLoading}
      actionsMissions={actionsMissions}
    />
  ) : (
    <ActionsDesktop
      noActionsAlertOpen={noActionsAlertOpen}
      setNoActionsAlertOpen={setNoActionsAlertOpen}
      isCurrentDate={isCurrentDate}
      loading={loading}
      setLoading={setLoading}
      actionsMissions={actionsMissions}
    />
  )
}

export default Actions
