import axios from "axios"
import { shoudlShowApiLogs } from "../config/constants"

// .env variables
const baseUrl = process.env.REACT_APP_API_URL

// get method
export const get = async (path: string, headers?: object) => {
  if (shoudlShowApiLogs) {
    console.log(`GET ${baseUrl}` + path)
  }

  let token = localStorage.getItem(
    Object.keys(localStorage).find((key) => key.includes("accessToken"))!
  )

  const config = {
    method: "get",
    url: baseUrl + path,
    headers: {
      Authorization: "Bearer " + token,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "0",
    },
  }

  if (headers) {
    config.headers = {
      ...config.headers,
      ...headers,
    }
  }

  return axios(config)
}

// post method
export const post = async (path: string, body?: object, headers?: object) => {
  if (shoudlShowApiLogs) {
    if (body) {
      console.log(`POST ${baseUrl}` + path, body)
    } else {
      console.log(`POST ${baseUrl}` + path)
    }
  }

  let token = localStorage.getItem(
    Object.keys(localStorage).find((key) => key.includes("accessToken"))!
  )

  const config = {
    method: "post",
    url: baseUrl + path,
    headers: {
      Authorization: "Bearer " + token,
    },
    data: body,
  }

  if (headers) {
    config.headers = {
      ...config.headers,
      ...headers,
    }
  }

  return axios(config)
}

// put method
export const put = async (path: string, body?: object, headers?: object) => {
  if (shoudlShowApiLogs) {
    if (body) {
      console.log(`PUT ${baseUrl}` + path, body)
    } else {
      console.log(`PUT ${baseUrl}` + path)
    }
  }

  let token = localStorage.getItem(
    Object.keys(localStorage).find((key) => key.includes("accessToken"))!
  )

  const config = {
    method: "put",
    url: baseUrl + path,
    headers: {
      Authorization: "Bearer " + token,
    },
    data: body,
  }

  if (headers) {
    config.headers = {
      ...config.headers,
      ...headers,
    }
  }

  return axios(config)
}

// delete method
export const deleteMethod = async (path: string, headers?: object) => {
  if (shoudlShowApiLogs) {
    console.log(`DELETE ${baseUrl}` + path)
  }

  let token = localStorage.getItem(
    Object.keys(localStorage).find((key) => key.includes("accessToken"))!
  )

  const config = {
    method: "delete",
    url: baseUrl + path,
    headers: {
      Authorization: "Bearer " + token,
    },
  }

  if (headers) {
    config.headers = {
      ...config.headers,
      ...headers,
    }
  }

  return axios(config)
}
