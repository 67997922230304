import { Grid, Skeleton, Stack } from "@mui/material"
import { useContext } from "react"
import { ActionsContext } from "../../../controllers/actions"
import FadeFromTop from "../../animations/FadeFromTop"
import actionsListBackgroundImage from "../../../assets/images/actions-list-background.png"
import {
  desktopColumnsGap,
  desktopMaxWidth,
  desktopPadding,
} from "../../../services/config/constants"

const HistoryGridSkeletonDesktop = () => {
  const { currentHistory, currentDate } = useContext(ActionsContext)

  // array of skeleton, calculated based on the global history to know how many skeletons show
  const skeletonsArray = Array(
    currentHistory
      .find(
        (item) =>
          new Date(item.date).toLocaleDateString() ===
          currentDate.toLocaleDateString()
      )
      ?.counters.reduce(
        (accumulator, currentValue) => accumulator + currentValue.amount,
        0
      )
  ).fill("")

  return (
    <Stack
      alignItems="center"
      style={{
        backgroundImage: `url(${actionsListBackgroundImage})`,
        backgroundPosition: "top",
        backgroundSize: "cover",
        width: "100%",
        marginTop: 38,
        minHeight: 466,
        paddingBottom: 68,
        paddingInline: desktopPadding,
      }}
    >
      <FadeFromTop
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid
          container
          columns={3}
          columnSpacing={`${desktopColumnsGap}px`}
          rowSpacing="8px"
          style={{
            width: `calc(100% + ${desktopColumnsGap}px)`,
            maxWidth: `calc(${desktopMaxWidth}px + ${desktopColumnsGap}px)`,
          }}
        >
          {skeletonsArray.map((item, index) => (
            <Grid key={index} item xs={1}>
              <Skeleton
                variant="rectangular"
                animation="wave"
                style={{ width: "100%", height: 88, borderRadius: 10 }}
              />
            </Grid>
          ))}
        </Grid>
      </FadeFromTop>
    </Stack>
  )
}

export default HistoryGridSkeletonDesktop
