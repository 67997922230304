import { ButtonBase, Stack } from "@mui/material"
import { colors } from "../../services/config/colors"
import awLogo from "../../assets/images/aw-logo.svg"
import partnerLogo from "../../assets/images/partner-logo.png"
import partnerLogoAlt from "../../assets/images/partner-logo-alt.png"
import { useContext, useRef, useState } from "react"
import { MainContext } from "../../controllers/main"
import Title from "../global/common/Title"
import Text from "../global/common/Text"
import introImage from "../../assets/images/onboarding/intro.png"
import earthImage from "../../assets/images/onboarding/earth.png"
import Button from "../global/common/Button"
import { Trans, useTranslation } from "react-i18next"
import checkWhiteIcon from "../../assets/icons/check-white.svg"
import FadeFromTop from "../animations/FadeFromTop"
import FadeFromBottom from "../animations/FadeFromBottom"
import { focusElement } from "../../services/utils/utils"
import { countriesWithDoublePrivacyCheck } from "../../services/config/constants"
import {
  privacyPolicyEn,
  privacyPolicyIt,
  privacyPolicyEs,
} from "../../services/data/privacyPolicy"

const OnboardingMobile = ({
  height,
  borderRadius,
  continueButtonBottom,
  continueButtonWidth,
}: {
  height?: number | string
  borderRadius?: number
  continueButtonBottom?: number
  continueButtonWidth?: number
}) => {
  const { t } = useTranslation()
  const { windowHeight, federatedSignIn, isMobile, country, lang } =
    useContext(MainContext)

  // current page
  const [currentPage, setCurrentPage] = useState<number>(0)

  // can click continue button
  const canClick = useRef<boolean>(true)

  // terms and privacy accepted
  const [privacy1Accepted, setPrivacy1Accepted] = useState<boolean>(false)
  const [privacy2Accepted, setPrivacy2Accepted] = useState<boolean>(
    country && countriesWithDoublePrivacyCheck.includes(country) ? false : true
  )

  // loading for federated signin
  const [loading, setLoading] = useState<boolean>(false)

  // if user is using screen reader, based on "onFocusVisible" event
  const screenReaderEnabled = useRef<boolean>(false)

  return (
    <Stack
      alignItems="center"
      style={{
        width: "100%",
        height: height ?? windowHeight,
        maxHeight: height ?? windowHeight,
        backgroundColor: colors.background,
        position: "relative",
        overflowY: "scroll",
        borderRadius: borderRadius,
      }}
    >
      {/* content */}
      <Stack
        id="onboarding-container"
        direction="row"
        style={{
          width: "100%",
          maxWidth: "100%",
          height:
            currentPage === 2
              ? document.getElementById("privacy-container")!.scrollHeight + 26
              : 800,
          minHeight:
            currentPage === 2
              ? document.getElementById("privacy-container")!.scrollHeight + 26
              : 800,
          overflow: "hidden",
          paddingTop: 26,
        }}
        tabIndex={0}
      >
        {/* first page */}
        <Stack
          style={{
            width: "100%",
            minWidth: "100%",
            paddingInline: isMobile ? 28 : 60,
          }}
          alignItems="center"
          aria-hidden={currentPage === 0 ? "false" : "true"}
        >
          {/* header */}
          <FadeFromTop>
            <Stack direction="row" style={{ gap: 30 }}>
              <img
                src={awLogo}
                style={{ height: 18 }}
                alt={t("aworld_logo_alt")}
              />
              <img
                src={country === "US" ? partnerLogo : partnerLogoAlt}
                style={{ width: 50 }}
                alt={t("partner_logo_alt")}
              />
            </Stack>
          </FadeFromTop>
          <FadeFromTop delay={0.05} style={{ marginTop: 30 }}>
            <Title
              fontSize={isMobile ? 26 : 28}
              textAlign="center"
              component="h1"
            >
              {t("onboarding_welcome")}
            </Title>
          </FadeFromTop>
          <FadeFromTop delay={0.1} style={{ marginTop: 12 }}>
            <Text
              fontSize={isMobile ? 18 : 20}
              textAlign="center"
              fontWeight={500}
              component="h2"
            >
              {t("onboarding_subtitle")}
            </Text>
          </FadeFromTop>
          <FadeFromTop delay={0.15} style={{ marginTop: 42 }}>
            <img
              src={introImage}
              style={{ width: 203, height: 214 }}
              alt={t("onboarding_image_alt")}
            />
          </FadeFromTop>
          <FadeFromTop delay={0.2} style={{ marginTop: 42 }}>
            <Text fontSize={isMobile ? 18 : 20} textAlign="center">
              <Trans i18nKey="onboarding_description">
                <strong style={{ fontWeight: 500 }}>
                  Agisci insieme a Enel e AWorld.
                </strong>{" "}
                Completa le missioni, contribuisci alla challenge collettiva e{" "}
                <strong style={{ fontWeight: 500 }}>
                  scopri come anche tu puoi fare la differenza
                </strong>{" "}
                per aiutare il Pianeta!
              </Trans>
            </Text>
          </FadeFromTop>
        </Stack>
        {/* second page */}
        <Stack
          style={{
            width: "100%",
            minWidth: "100%",
            paddingInline: 28,
            position: "relative",
          }}
          alignItems="center"
          aria-hidden={currentPage === 1 ? "false" : "true"}
        >
          <img
            src={earthImage}
            style={{
              width: "100%",
              maxWidth: 375,
              position: "absolute",
              top: 350,
              right: 0,
            }}
            alt=""
          />
          <Stack direction="row" style={{ gap: 30 }}>
            <img
              src={awLogo}
              style={{ height: 18 }}
              alt={t("aworld_logo_alt")}
            />
            <img
              src={country === "US" ? partnerLogo : partnerLogoAlt}
              style={{ width: 50 }}
              alt={t("partner_logo_alt")}
            />
          </Stack>
          <Title
            fontSize={isMobile ? 26 : 28}
            textAlign="center"
            style={{ marginTop: 30 }}
            component="h1"
          >
            {t("the_collective_challenge").charAt(0).toUpperCase() +
              t("the_collective_challenge").slice(1).toLowerCase()}
          </Title>
          <Text
            fontSize={isMobile ? 18 : 20}
            textAlign="center"
            fontWeight={400}
            style={{ marginTop: 12 }}
            component="h2"
          >
            {t("how_does_it_work")}
          </Text>
          <Text
            fontSize={isMobile ? 18 : 20}
            fontWeight={500}
            style={{ width: "100%", marginTop: 60 }}
          >
            {t("onboarding_challenge_title")}
          </Text>
          <Text fontSize={isMobile ? 18 : 20} style={{ width: "100%" }}>
            {t("onboarding_challenge_description")}
          </Text>
          <Text
            fontSize={isMobile ? 18 : 20}
            fontWeight={500}
            style={{ width: "100%", marginTop: 25 }}
          >
            {t("complete_the_missions")}
          </Text>
          <Text fontSize={isMobile ? 18 : 20} style={{ width: "100%" }}>
            {t("onboarding_leaderboard_description")}
          </Text>
        </Stack>
        {/* third page */}
        <Stack
          id="privacy-container"
          style={{
            width: "100%",
            minWidth: "100%",
            paddingInline: 28,
          }}
          alignItems="center"
          aria-hidden={currentPage === 2 ? "false" : "true"}
        >
          <Stack direction="row" style={{ gap: 30 }}>
            <img
              src={awLogo}
              style={{ height: 18 }}
              alt={t("aworld_logo_alt")}
            />
            <img
              src={country === "US" ? partnerLogo : partnerLogoAlt}
              style={{ width: 50 }}
              alt={t("partner_logo_alt")}
            />
          </Stack>
          <Title
            fontSize={isMobile ? 26 : 28}
            textAlign="center"
            style={{ marginTop: 30 }}
            component="h1"
          >
            {t("we_need_your_consent")}
          </Title>
          <Text
            fontSize={isMobile ? 18 : 20}
            textAlign="center"
            fontWeight={400}
            style={{ marginTop: 12 }}
            component="h2"
          >
            {t("privacy_policy_heading")}
          </Text>
          <div
            dangerouslySetInnerHTML={{
              __html:
                lang === "es"
                  ? privacyPolicyEs
                  : lang === "it"
                  ? privacyPolicyIt
                  : privacyPolicyEn,
            }}
            style={{
              fontSize: 18,
              lineHeight: "25px",
              fontWeight: 300,
              marginTop: 60,
            }}
          />
          <Stack
            style={{
              width: "100%",
              marginTop: 40,
              paddingBottom: continueButtonBottom
                ? continueButtonBottom + 70
                : 128,
              gap: 20,
            }}
          >
            <ButtonBase
              disabled={loading}
              disableRipple
              style={{
                width: "100%",
              }}
              onClick={() => {
                setPrivacy1Accepted((current) => !current)
              }}
              tabIndex={currentPage === 2 ? 0 : -1}
              aria-hidden={currentPage === 2 ? "false" : "true"}
              aria-live="assertive"
              role="checkbox"
              aria-checked={privacy1Accepted ? "true" : "false"}
            >
              <Stack
                direction="row"
                style={{ width: "100%", gap: 14 }}
                alignItems="center"
              >
                <div
                  style={{
                    width: 24,
                    minWidth: 24,
                    height: 24,
                    borderRadius: "100%",
                    backgroundColor: privacy1Accepted
                      ? colors.primary
                      : colors.background,
                    border: "2px solid " + colors.primary,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    transition: "150ms",
                  }}
                >
                  <img
                    src={checkWhiteIcon}
                    style={{
                      width: 13,
                      opacity: privacy1Accepted ? 1 : 0,
                      transition: "150ms",
                    }}
                    alt=""
                  />
                </div>
                <Text fontSize={13} lineHeight="18px" fontWeight={400}>
                  {t("first_privacy_consent")}
                </Text>
              </Stack>
            </ButtonBase>
            {country && countriesWithDoublePrivacyCheck.includes(country) ? (
              <ButtonBase
                disabled={loading}
                disableRipple
                style={{
                  width: "100%",
                }}
                onClick={() => {
                  setPrivacy2Accepted((current) => !current)
                }}
                tabIndex={currentPage === 2 ? 0 : -1}
                aria-hidden={currentPage === 2 ? "false" : "true"}
                aria-live="assertive"
                role="checkbox"
                aria-checked={privacy2Accepted ? "true" : "false"}
              >
                <Stack
                  direction="row"
                  style={{ width: "100%", gap: 14 }}
                  alignItems="center"
                >
                  <div
                    style={{
                      width: 24,
                      minWidth: 24,
                      height: 24,
                      borderRadius: "100%",
                      backgroundColor: privacy2Accepted
                        ? colors.primary
                        : colors.background,
                      border: "2px solid " + colors.primary,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      transition: "150ms",
                    }}
                  >
                    <img
                      src={checkWhiteIcon}
                      style={{
                        width: 13,
                        opacity: privacy2Accepted ? 1 : 0,
                        transition: "150ms",
                      }}
                      alt=""
                    />
                  </div>
                  <Text fontSize={13} lineHeight="18px" fontWeight={400}>
                    {t("second_privacy_consent")}
                  </Text>
                </Stack>
              </ButtonBase>
            ) : null}
          </Stack>
        </Stack>
      </Stack>
      {/* continue button */}
      <FadeFromBottom
        style={{
          width: continueButtonWidth ?? "100%",
          position: "fixed",
          bottom: continueButtonBottom ?? 22,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          loading={loading}
          disabled={
            currentPage === 2 && (!privacy1Accepted || !privacy2Accepted)
          }
          title={t("continue")}
          width={continueButtonWidth ? "100%" : "calc(100% - 56px)"}
          onClick={() => {
            if (canClick.current) {
              if (currentPage !== 2) {
                const container = document.getElementById(
                  "onboarding-container"
                )
                container?.scrollTo({
                  left: isMobile
                    ? window.innerWidth * (currentPage + 1)
                    : 505 * (currentPage + 1),
                  // behavior: screenReaderEnabled.current ? "auto" : "smooth",
                  behavior: "auto",
                })
                setCurrentPage((current) => (current += 1))

                if (screenReaderEnabled.current) {
                  focusElement("onboarding-container")
                }

                canClick.current = false
                setTimeout(() => {
                  canClick.current = true
                }, 500)
              } else {
                setLoading(true)
                federatedSignIn(
                  process.env.REACT_APP_FEDERATED_SIGN_IN_PROVIDER!
                )
              }
            }
          }}
          onFocusVisible={() => {
            screenReaderEnabled.current = true
          }}
          onBlur={() => {
            screenReaderEnabled.current = false
          }}
        >
          {t("continue")}
        </Button>
      </FadeFromBottom>
    </Stack>
  )
}

export default OnboardingMobile
