import { useContext, useEffect, useMemo, useState } from "react"
import ActionDetailsMobile from "../../components/actions/ActionDetailsMobile"
import { MainContext } from "../../controllers/main"
import ActionDetailsDesktop from "../../components/actions/ActionDetailsDesktop"
import { ActionsContext } from "../../controllers/actions"
import { useParams } from "react-router-dom"
import ReactGA from "react-ga4"
import { registerPageEvent } from "../../services/utils/utils"

const ActionDetails = () => {
  const { actionId } = useParams()
  const { isMobile } = useContext(MainContext)
  const { actions, currentDetailedHistory, currentDate } =
    useContext(ActionsContext)

  // register google analytics event
  useEffect(() => {
    registerPageEvent("action_details")
  }, [])

  // send google analytics event
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "action_details" })
  }, [])

  // current action
  const action = actions.find((item) => item.id === actionId)!

  // number of times that action has been logged
  const timesLogged = useMemo(() => {
    return currentDetailedHistory.filter(
      (item) =>
        new Date(item.createdAt).toLocaleDateString() ===
          currentDate.toLocaleDateString() && item.action.id === action.id
    ).length
  }, [currentDetailedHistory])

  // is current date (today === currentDate)
  const isCurrentDate = useMemo(() => {
    return new Date().toLocaleDateString() === currentDate.toLocaleDateString()
  }, [currentDate])

  // repeatable action info
  const [repeatableInfoOpen, setRepeatableInfoOpen] = useState<boolean>(false)

  return isMobile ? (
    <ActionDetailsMobile
      action={action}
      timesLogged={timesLogged}
      isCurrentDate={isCurrentDate}
      repeatableInfoOpen={repeatableInfoOpen}
      setRepeatableInfoOpen={setRepeatableInfoOpen}
    />
  ) : (
    <ActionDetailsDesktop
      action={action}
      timesLogged={timesLogged}
      isCurrentDate={isCurrentDate}
      repeatableInfoOpen={repeatableInfoOpen}
      setRepeatableInfoOpen={setRepeatableInfoOpen}
    />
  )
}

export default ActionDetails
