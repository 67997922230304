import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react"
import { get } from "../services/api/api"
import { MainContext } from "./main"
import Challenge from "../models/challenge"
import { parseChallengeData } from "../services/utils/parseFunctions"

interface ChallengeContextInterface {
  challengeLoading: boolean
  challengeError: boolean
  challenge: Challenge | null
  getChallenge: (withLoading?: boolean) => void
}

const ChallengeContext = createContext<ChallengeContextInterface>({
  challengeLoading: true,
  challengeError: false,
  challenge: null,
  getChallenge: () => {},
})

const ChallengeController = ({ children }: { children: ReactNode }) => {
  const { lang } = useContext(MainContext)

  // loadings
  const [challengeLoading, setChallengeLoading] = useState<boolean>(true)

  // errors
  const [challengeError, setChallengeError] = useState<boolean>(false)

  // states
  const [challenge, setChallenge] = useState<Challenge | null>(null) // current challenge

  // get current challenge
  const getChallenge = async (withLoading = true) => {
    if (withLoading) {
      setChallengeLoading(true)
    }
    setChallengeError(false)

    try {
      const { data } = await get("/web/challenge/get")

      if (data) {
        console.log("challenge", data)
        setChallenge(data)
      }

      setChallengeLoading(false)
    } catch (e) {
      console.log("challenge error", e)
      setChallengeLoading(false)
      setChallengeError(true)
    }
  }

  // initial fetch
  useEffect(() => {
    getChallenge()
  }, [])

  return (
    <ChallengeContext.Provider
      value={{
        challengeLoading,
        challengeError,
        challenge,
        getChallenge,
      }}
    >
      {children}
    </ChallengeContext.Provider>
  )
}
export { ChallengeController, ChallengeContext }
