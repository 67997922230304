import { ButtonBase, Grid, Stack } from "@mui/material"
import { colors } from "../../services/config/colors"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import arrowLefIcon from "../../assets/icons/arrow-left.svg"
import Title from "../global/common/Title"
import { useContext, useState } from "react"
import { UsersContext } from "../../controllers/users"
import AvatarButton from "./common/AvatarButton"
import { MainContext } from "../../controllers/main"
import FadeFromBottom from "../animations/FadeFromBottom"
import Button from "../global/common/Button"
import FadeFromTop from "../animations/FadeFromTop"

const AvatarEditMobile = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { user } = useContext(MainContext)
  const { avatars, changeAvatar } = useContext(UsersContext)

  // loading for avatar change
  const [loading, setLoading] = useState<boolean>(false)

  // selected avatar
  const [selectedAvatar, setSelectedAvatar] = useState<string>(
    user!.profileImage
  )

  return (
    <Stack
      alignItems="center"
      style={{
        width: "100%",
        minHeight: "100%",
        backgroundColor: colors.backgroundWhite,
        paddingTop: 22,
        overflowX: "hidden",
        paddingBottom: 100,
      }}
    >
      {/* header */}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        style={{ width: "100%", height: 48, paddingInline: 16 }}
      >
        <ButtonBase
          style={{
            width: 48,
            minWidth: 48,
            height: 48,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 12,
          }}
          onClick={() => {
            navigate("/performance/settings")
          }}
          aria-label={t("go_back")}
        >
          <img src={arrowLefIcon} style={{ width: 15 }} alt="" />
        </ButtonBase>
        <Title
          fontSize={20}
          lineHeight="23px"
          style={{ height: 23 }}
          component="h1"
        >
          {t("edit_avatar")}
        </Title>
        <div
          style={{
            width: 48,
            minWidth: 48,
            height: 48,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 12,
          }}
        />
      </Stack>
      {/* content */}
      <Grid
        container
        columns={3}
        columnSpacing="16px"
        rowSpacing="16px"
        style={{ marginTop: 0, paddingInline: 16 }}
      >
        {avatars.map((avatar, index) => (
          <Grid key={index} item xs={1}>
            <FadeFromTop delay={index * 0.025}>
              <AvatarButton
                avatar={avatar}
                selected={selectedAvatar === avatar.url}
                current={user!.profileImage === avatar.url ? true : false}
                disabled={loading}
                onClick={() => {
                  setSelectedAvatar(avatar.url)
                }}
              />
            </FadeFromTop>
          </Grid>
        ))}
      </Grid>
      {/* change avatar button */}
      <FadeFromBottom
        style={{ width: "100%", position: "fixed", bottom: 0, left: 0 }}
      >
        <Stack
          style={{
            width: "100%",
            paddingTop: 16,
            paddingBottom: 16,
            boxShadow: "0px 0px 64px rgba(3, 56, 120, 0.12)",
            borderRadius: "10px 10px 0px 0px",
            backgroundColor: colors.backgroundWhite,
            gap: 8,
          }}
        >
          <Button
            width="calc(100% - 32px)"
            style={{ marginLeft: 16 }}
            title={t("save_change")}
            disabled={selectedAvatar === user?.profileImage}
            loading={loading}
            onClick={async () => {
              setLoading(true)

              await changeAvatar(selectedAvatar)

              setLoading(false)
            }}
          >
            {t("save_change")}
          </Button>
        </Stack>
      </FadeFromBottom>
    </Stack>
  )
}

export default AvatarEditMobile
