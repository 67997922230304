import { ButtonBase, Stack } from "@mui/material"
import { colors } from "../../services/config/colors"
import Title from "../global/common/Title"
import arrowLefIcon from "../../assets/icons/arrow-left.svg"
import { useLocation, useNavigate } from "react-router-dom"
import calendarPrimaryIcon from "../../assets/icons/calendar-primary.svg"
import CalendarRowMobile from "./mobile/CalendarRowMobile"
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import { ActionsContext } from "../../controllers/actions"
import days from "../../services/config/days"
import months from "../../services/config/months"
import AppearFromSide from "../animations/AppearFromSide"
import ActionsMissionsCarouselMobile from "./mobile/ActionsMissionsCarouselMobile"
import Text from "../global/common/Text"
import actionsListBackground from "../../assets/images/actions-list-background.png"
import ActionsCarouselMobile from "./mobile/ActionsCarouselMobile"
import Button from "../global/common/Button"
import { AnimatePresence, motion } from "framer-motion"
import ActionsLoggedFeedbackMobile from "../feedbacks/mobile/ActionsLoggedFeedbackMobile"
import Alert from "../global/common/Alert"
import ActionHistoryCard from "./common/ActionHistoryCard"
import HistoryColumnSkeletonMobile from "./mobile/HistoryColumnSkeletonMobile"
import { scrollWindow, scrollWindowToTop } from "../../services/utils/utils"
import CalendarDialogMobile from "./mobile/CalendarDialogMobile"
import { useTranslation } from "react-i18next"
import { MainContext } from "../../controllers/main"
import { maximumActionsPerTime } from "../../services/config/constants"

const ActionsMobile = ({
  noActionsAlertOpen,
  setNoActionsAlertOpen,
  isCurrentDate,
  loading,
  setLoading,
  actionsMissions,
}: {
  noActionsAlertOpen: boolean
  setNoActionsAlertOpen: Dispatch<SetStateAction<boolean>>
  isCurrentDate: boolean
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  actionsMissions: {
    title: string
    progress: string
    points: number
    satisfied: boolean
    onClick: () => void
  }[]
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const { lang } = useContext(MainContext)
  const {
    currentDate,
    selectedActions,
    setSelectedActions,
    logActions,
    actionsLoggedFeedbackOpen,
    setActionsLoggedFeedbackOpen,
    actionsLoggedFeedbackPlural,
    currentHistory,
    currentDetailedHistory,
    actionsPerTimeLimitAlertOpen,
    setActionsPerTimeLimitAlertOpen,
  } = useContext(ActionsContext)

  // scroll to actions is user is coming from action details page
  useEffect(() => {
    if (location.state && location.state.fromAction) {
      setTimeout(() => {
        scrollWindow(1000, "smooth")
      }, 350)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // debounce for log button
  const canClickLogButton = useRef<boolean>(true)

  // calendar dialog
  const [calendarDialogOpen, setCalendarDialogOpen] = useState<boolean>(false)

  return (
    <Stack
      style={{
        width: "100%",
        minHeight: "100%",
        backgroundColor: colors.backgroundWhite,
        paddingTop: 22,
        overflowX: "hidden",
      }}
    >
      {/* header */}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        style={{ width: "100%", height: 48, paddingInline: 16 }}
      >
        <ButtonBase
          style={{
            width: 48,
            minWidth: 48,
            height: 48,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 12,
          }}
          onClick={() => {
            navigate("/")
          }}
          aria-label={t("go_back")}
        >
          <img src={arrowLefIcon} style={{ width: 15 }} alt="" />
        </ButtonBase>
        <Title
          fontSize={20}
          lineHeight="23px"
          style={{ height: 23 }}
          component="h1"
        >
          {t("actions")}
        </Title>
        <div
          style={{
            width: 48,
            minWidth: 48,
            height: 48,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 12,
          }}
        />
      </Stack>
      {/* calendar row */}
      <Stack
        direction="row"
        alignItems="center"
        style={{
          width: "100%",
          height: 40,
          paddingInline: 16,
          position: "relative",
          marginTop: 3,
        }}
      >
        <div style={{ width: "100%", height: 26, position: "relative" }}>
          <AnimatePresence>
            <motion.div
              key={currentDate.toLocaleDateString()}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{
                opacity: { duration: 0.1 },
              }}
              style={{ width: "100%", height: "100%", position: "absolute" }}
            >
              <Title fontSize={20} lineHeight="26px">
                {currentDate.toLocaleDateString() ===
                new Date().toLocaleDateString()
                  ? t("today")
                  : `${t(
                      days[
                        currentDate.getDay() === 0
                          ? 6
                          : currentDate.getDay() - 1
                      ]
                    )}, ${lang === "it" ? currentDate.getDate() : ""} ${t(
                      months[currentDate.getMonth()]
                    ).toLowerCase()} ${
                      lang !== "it" ? currentDate.getDate() : ""
                    }`}
              </Title>
            </motion.div>
          </AnimatePresence>
        </div>
        <ButtonBase
          style={{
            width: 40,
            height: 40,
            borderRadius: 12,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: colors.background,
            position: "absolute",
            right: 16,
          }}
          onClick={() => {
            setCalendarDialogOpen(true)
          }}
          aria-label={t("open_calendar")}
        >
          <img
            src={calendarPrimaryIcon}
            style={{ width: 16, height: 16 }}
            alt=""
          />
        </ButtonBase>
      </Stack>
      <AppearFromSide fade>
        <CalendarRowMobile
          loading={loading}
          setLoading={setLoading}
          style={{ marginTop: 16 }}
        />
      </AppearFromSide>
      {/* actions missions */}
      <AnimatePresence>
        {isCurrentDate && (
          <motion.div
            initial={{
              height: loading || !actionsMissions.length ? 0 : 166,
              marginBottom: loading || !actionsMissions.length ? -30 : 0,
            }}
            animate={{
              height: actionsMissions.length ? 166 : 0,
              marginBottom: actionsMissions.length ? 0 : -30,
            }}
            exit={{ height: 0, marginBottom: -30 }}
            style={{
              overflow: "hidden",
              marginTop: 30,
            }}
            role="region"
            aria-roledescription={t("carousel")}
            aria-label={t("act_type_missions")}
          >
            <AppearFromSide fade delay={0.05}>
              <ActionsMissionsCarouselMobile
                actionsMissions={actionsMissions}
              />
            </AppearFromSide>
          </motion.div>
        )}
      </AnimatePresence>
      {/* actions for you */}
      <AppearFromSide fade delay={0.1} style={{ marginTop: 35 }}>
        <Stack
          direction="row"
          alignItems="center"
          style={{
            width: "100%",
            height: 40,
            paddingInline: 16,
            position: "relative",
          }}
        >
          <Title fontSize={16} lineHeight="18px" component="h2">
            {isCurrentDate ? t("actions_for_you") : t("logged_actions")}
          </Title>
          {isCurrentDate ? (
            <ButtonBase
              style={{
                width: 110,
                height: 40,
                borderRadius: 8,
                border: "1px solid " + colors.primary,
                position: "absolute",
                right: 16,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => {
                setSelectedActions([])
                scrollWindowToTop()
                navigate("/actions/explore")
              }}
              aria-label={t("explore_all")}
            >
              <Text fontSize={14} fontWeight={700} color={colors.primary}>
                {t("explore_all")}
              </Text>
            </ButtonBase>
          ) : (
            <Text
              key={currentDate.toLocaleDateString()}
              fontSize={12}
              fontWeight={700}
              color={colors.textSecondary}
              style={{ position: "absolute", right: 16 }}
              ariaLive="polite"
            >
              {!currentHistory.find(
                (item) =>
                  new Date(item.date).toLocaleDateString() ===
                  currentDate.toLocaleDateString()
              )
                ? t("no_actions")
                : currentHistory
                    .find(
                      (item) =>
                        new Date(item.date).toLocaleDateString() ===
                        currentDate.toLocaleDateString()
                    )
                    ?.counters.reduce(
                      (accumulator, currentValue) =>
                        accumulator + currentValue.amount,
                      0
                    ) === 1
                ? t("one_action")
                : `${currentHistory
                    .find(
                      (item) =>
                        new Date(item.date).toLocaleDateString() ===
                        currentDate.toLocaleDateString()
                    )
                    ?.counters.reduce(
                      (accumulator, currentValue) =>
                        accumulator + currentValue.amount,
                      0
                    )} ${t("total_actions")}`}
            </Text>
          )}
        </Stack>
      </AppearFromSide>
      {isCurrentDate ? (
        <div
          style={{
            width: "100%",
            height: 426,
            minHeight: 426,
            backgroundImage: `url(${actionsListBackground})`,
            backgroundSize: "cover",
            marginTop: 18,
          }}
        >
          <AppearFromSide fade delay={0.15}>
            <ActionsCarouselMobile loading={loading} />
          </AppearFromSide>
          <AppearFromSide
            fade
            delay={0.2}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginTop: 26,
            }}
          >
            <Button
              width={225}
              disabled={loading}
              onClick={() => {
                if (!selectedActions.length) {
                  setNoActionsAlertOpen(true)
                  return
                }

                if (canClickLogButton.current) {
                  logActions()

                  canClickLogButton.current = false
                  setTimeout(() => {
                    canClickLogButton.current = true
                  }, 800)
                }
              }}
              title={
                selectedActions.length === 0
                  ? t("log")
                  : selectedActions.length === 1
                  ? t("log_one_action")
                  : t("log_count_actions", { count: selectedActions.length })
              }
            >
              {selectedActions.length === 0
                ? t("log")
                : selectedActions.length === 1
                ? t("log_one_action")
                : t("log_count_actions", { count: selectedActions.length })}
            </Button>
          </AppearFromSide>
        </div>
      ) : !currentHistory.find(
          (item) =>
            new Date(item.date).toLocaleDateString() ===
            currentDate.toLocaleDateString()
        ) ? null : loading ? (
        <HistoryColumnSkeletonMobile />
      ) : (
        <Stack
          style={{
            width: "100%",
            marginTop: 16,
            paddingInline: 16,
            paddingBottom: 32,
            gap: 8,
          }}
        >
          {currentDetailedHistory.map((item, index) => (
            <ActionHistoryCard
              key={index}
              index={index}
              action={item.action}
              hour={new Date(item.createdAt).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}
            />
          ))}
        </Stack>
      )}
      {/* alerts and feedbacks */}
      <ActionsLoggedFeedbackMobile
        open={actionsLoggedFeedbackOpen}
        setOpen={setActionsLoggedFeedbackOpen}
        plural={actionsLoggedFeedbackPlural}
      />
      <Alert
        open={noActionsAlertOpen}
        title={t("no_actions_selected")}
        description={t("select_action_to_log")}
        primaryActionLabel={t("ok")}
        primaryActionOnClick={() => {
          setNoActionsAlertOpen(false)
        }}
      />
      <CalendarDialogMobile
        open={calendarDialogOpen}
        setOpen={setCalendarDialogOpen}
        daysDisabled={loading}
        setDaysDisabled={setLoading}
      />
      <Alert
        open={actionsPerTimeLimitAlertOpen}
        title={t("warning")}
        description={t("actions_limit", { count: maximumActionsPerTime })}
        primaryActionLabel={t("i_understand")}
        primaryActionOnClick={() => {
          setActionsPerTimeLimitAlertOpen(false)
        }}
      />
      {/* blank space filler */}
      {isCurrentDate && !actionsMissions.length ? (
        <div
          style={{
            width: "100%",
            height: "calc(100% - 726px)",
            position: "absolute",
            bottom: 0,
            backgroundColor: colors.background,
          }}
        />
      ) : null}
    </Stack>
  )
}

export default ActionsMobile
