import { useContext, useEffect, useMemo, useState } from "react"
import { MainContext } from "../../controllers/main"
import ActionsExploreMobile from "../../components/actions/ActionsExploreMobile"
import ActionsExploreDesktop from "../../components/actions/ActionsExploreDesktop"
import { ActionsContext } from "../../controllers/actions"
import { TagsContext } from "../../controllers/tags"
import { useSearchParams } from "react-router-dom"
import { registerPageEvent } from "../../services/utils/utils"

const ActionsExplore = () => {
  const [searchParams] = useSearchParams()
  const { isMobile } = useContext(MainContext)
  const { actions, selectedCategory, setSelectedCategory } =
    useContext(ActionsContext)
  const { categories } = useContext(TagsContext)

  // register google analytics event
  useEffect(() => {
    registerPageEvent("actions_explore")
  }, [])

  // filtered actions list by category
  const filteredActionsList = useMemo(() => {
    if (selectedCategory === "catDefault") {
      return actions.slice(0, 8)
    }

    return actions.filter(
      (action) =>
        action.category.id ===
        categories.find((category) => category.id === selectedCategory)!.id
    )
  }, [selectedCategory, actions])

  // no selected actions alert
  const [noActionsAlertOpen, setNoActionsAlertOpen] = useState<boolean>(false)

  // check query param for category filter
  useEffect(() => {
    const categoryToSet = searchParams.get("category")
    if (categoryToSet) {
      setSelectedCategory(categoryToSet)
    }
  }, [])

  return isMobile ? (
    <ActionsExploreMobile
      selectedCategory={selectedCategory}
      filteredActionsList={filteredActionsList}
      noActionsAlertOpen={noActionsAlertOpen}
      setNoActionsAlertOpen={setNoActionsAlertOpen}
    />
  ) : (
    <ActionsExploreDesktop
      selectedCategory={selectedCategory}
      filteredActionsList={filteredActionsList}
      noActionsAlertOpen={noActionsAlertOpen}
      setNoActionsAlertOpen={setNoActionsAlertOpen}
    />
  )
}

export default ActionsExplore
