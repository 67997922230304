import { ButtonBase, Stack } from "@mui/material"
import Title from "../../global/common/Title"
import { colors } from "../../../services/config/colors"
import actMissionIconWhite from "../../../assets/icons/missions/missionsTypes/act-white.svg"
import Text from "../../global/common/Text"
import { useContext } from "react"
import { MainContext } from "../../../controllers/main"
import PointsBadge from "../../global/common/PointsBadge"
import { useTranslation } from "react-i18next"

const ActionMissionCard = ({
  mission,
  shouldAriaLive = false,
}: {
  mission: {
    title: string
    progress: string
    points: number
    satisfied: boolean
    onClick: () => void
  }
  shouldAriaLive?: boolean
}) => {
  const { t } = useTranslation()
  const { isMobile } = useContext(MainContext)

  return (
    <Stack
      style={{
        width: isMobile ? "83.467vw" : "100%",
        minWidth: isMobile ? "83.467vw" : "100%",
        height: isMobile ? 143 : "100%",
        gap: isMobile ? 11 : 18,
        scrollSnapAlign: "center",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        style={{
          width: "100%",
          height: isMobile ? 32 : 40,
          borderRadius: isMobile ? 4 : 6,
          backgroundColor: "#0E7E69",
          gap: isMobile ? 8 : 11,
        }}
      >
        <img
          src={actMissionIconWhite}
          style={{ width: isMobile ? 16 : 23.5, height: isMobile ? 16 : 23.5 }}
          alt=""
        />
        <Title
          fontSize={isMobile ? 14 : 18}
          color={colors.textWhite}
          style={{
            maxWidth: "calc(100% - 60px)",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {t("act").toUpperCase()}
        </Title>
      </Stack>
      <Stack
        style={{
          width: "100%",
          height: isMobile ? 100 : 235,
          boxShadow: "0px 0px 24px 0px rgba(17, 67, 97, 0.08)",
          borderRadius: isMobile ? 10 : 18,
          paddingTop: isMobile ? 10 : 20,
          paddingLeft: isMobile ? 14 : 30,
          position: "relative",
        }}
      >
        <Title
          fontSize={isMobile ? 14 : 26}
          style={{
            width: isMobile ? "72%" : 550,
          }}
          ariaLive={shouldAriaLive ? "polite" : "off"}
        >
          {mission.title}
        </Title>
        {!isMobile && mission.satisfied ? (
          <div
            style={{
              width: "fit-content",
              height: 30,
              border: "1.5px solid " + colors.primary,
              paddingInline: 6,
              borderRadius: 4,
              display: "flex",
              alignItems: "center",
              position: "absolute",
              bottom: 20,
              right: 30,
            }}
          >
            <Title fontSize={16} color={colors.primary}>
              {t("completed").toUpperCase()}
            </Title>
          </div>
        ) : isMobile && mission.satisfied ? (
          <div
            style={{
              width: "fit-content",
              height: 24,
              border: "1px solid " + colors.primary,
              paddingInline: 6,
              borderRadius: 4,
              display: "flex",
              alignItems: "center",
              position: "absolute",
              right: 14,
              bottom: 10,
            }}
          >
            <Title fontSize={14} color={colors.primary}>
              {t("completed").toUpperCase()}
            </Title>
          </div>
        ) : null}
        {!mission.satisfied && (
          <ButtonBase
            disableRipple
            style={{
              width: "fit-content",
              justifyContent: "flex-start",
              position: "absolute",
              bottom: isMobile ? 10 : 20,
              right: isMobile ? 14 : 30,
            }}
            onClick={mission.onClick}
            aria-label={t("see_all_actions")}
          >
            <Text
              fontSize={isMobile ? 14 : 20}
              fontWeight={700}
              color={colors.primary}
              style={{
                textDecorationLine: "underline",
              }}
            >
              {t("see_all_actions")}
            </Text>
          </ButtonBase>
        )}
        <PointsBadge
          points={mission.points}
          style={{
            position: "absolute",
            top: isMobile ? 10 : 20,
            right: isMobile ? 14 : 30,
          }}
        />
      </Stack>
    </Stack>
  )
}

export default ActionMissionCard
