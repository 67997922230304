export const colors = {
  primary: "#1B8FFD", // brand blue + 20% white
  secondary: "#D02963", // same as missions => act
  disabled: "#7EC0FF", // brand blue + 50% white
  background: "#F2F8FE", // brand blue + 95% white
  backgroundLight: "#F2F3F4", // Japanese Indigo + 95% white
  backgroundWhite: "#FFFFFF", // 100% white
  stroke: "#7D8D96", // Japanese Indigo + 40% white
  text: "#274150", // Japanese Indigo
  textSecondary: "#526773", // Japanese Indigo + 20% white
  textWhite: "#FFFFFF", // 100% white
  metrics: {
    co2: "#759357",
    water: "#5D82CA",
    energy: "#CE9256",
  },
  missions: {
    act: "#14856F",
    learn: "#D02963",
    measure: "#54428E",
  },
  success: "#008000",
  error: "#D02929",
}
