import { useContext, useEffect } from "react"
import { MainContext } from "../../controllers/main"
import DailyEpisodeMobile from "../../components/episode/DailyEpisodeMobile"
import { useParams } from "react-router-dom"
import { JourneysContext } from "../../controllers/journeys"
import DailyEpisodeDesktop from "../../components/episode/DailyEpisodeDesktop"
import { registerPageEvent } from "../../services/utils/utils"

const DailyEpisode = () => {
  const { slideId } = useParams()
  const { isMobile } = useContext(MainContext)
  const { dailyEpisode } = useContext(JourneysContext)

  // register google analytics event
  useEffect(() => {
    registerPageEvent("daily_episode")
  }, [])

  // current slide and slides info
  const currentSlideIndex = parseInt(slideId!)
  const currentSlide = dailyEpisode!.slides[currentSlideIndex]
    ? dailyEpisode!.slides[currentSlideIndex]
    : dailyEpisode!.quiz[currentSlideIndex - dailyEpisode!.slides.length]!
  const currentSlideQuizResult = currentSlide
    ? dailyEpisode!.result.quiz.find((item) => item.slideId === currentSlide.id)
    : undefined
  const numberOfSlides = dailyEpisode!.slides.length
  const numberOfQuiz = dailyEpisode!.quiz.length
  const totalSlides = numberOfSlides + numberOfQuiz

  return isMobile ? (
    <DailyEpisodeMobile
      currentSlide={currentSlide}
      currentSlideIndex={currentSlideIndex}
      numberOfSlides={numberOfSlides}
      numberOfQuiz={numberOfQuiz}
      totalSlides={totalSlides}
      currentSlideQuizResult={currentSlideQuizResult}
    />
  ) : (
    <DailyEpisodeDesktop
      currentSlide={currentSlide}
      currentSlideIndex={currentSlideIndex}
      numberOfSlides={numberOfSlides}
      numberOfQuiz={numberOfQuiz}
      totalSlides={totalSlides}
      currentSlideQuizResult={currentSlideQuizResult}
    />
  )
}

export default DailyEpisode
