import { Dispatch, SetStateAction } from "react"
import FeedbackContainer from "../common/FeedbackContainer"
import Title from "../../global/common/Title"
import Text from "../../global/common/Text"
import actionLoggedImage from "../../../assets/images/action-logged.png"
import { useTranslation } from "react-i18next"

const ActionsLoggedFeedbackMobile = ({
  open,
  setOpen,
  plural,
}: {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  plural: boolean
}) => {
  const { t } = useTranslation()

  return (
    <FeedbackContainer open={open} setOpen={setOpen}>
      <Title lineHeight="38px">
        {plural ? t("actions_logged") : t("action_logged")}
      </Title>
      <img
        src={actionLoggedImage}
        style={{ width: 188, height: 188, marginTop: 30 }}
        alt=""
      />
      <Text
        fontSize={18}
        lineHeight="24.3px"
        fontWeight={500}
        style={{ marginTop: 30 }}
      >
        {t("keep_it_up")}
      </Text>
      <Text
        fontSize={18}
        lineHeight="24.3px"
        textAlign="center"
        style={{ maxWidth: 280 }}
      >
        {t("log_your_actions_every_day")}
      </Text>
    </FeedbackContainer>
  )
}

export default ActionsLoggedFeedbackMobile
