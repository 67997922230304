import { CSSProperties, useContext, useEffect, useState } from "react"
import { colors } from "../../../services/config/colors"
import Text from "../../global/common/Text"
import { MainContext } from "../../../controllers/main"
import { useTranslation } from "react-i18next"

const ChallengeProgressBar = ({
  percentage,
  showPercentageStartingFromDesktop = 10,
  showPercentageStartingFromMobile = 15,
  style,
}: {
  percentage: number
  showPercentageStartingFromDesktop?: number
  showPercentageStartingFromMobile?: number
  style?: CSSProperties
}) => {
  const { t } = useTranslation()
  const { isMobile } = useContext(MainContext)

  // bar percentage, different from the props one beacause the max of this one is 100
  const [barPercentage, setBarPercentage] = useState<number>(0)

  useEffect(() => {
    setBarPercentage(percentage > 100 ? 100 : percentage)
  }, [percentage])

  return (
    <div
      style={{
        width: "100%",
        height: 20,
        overflow: "hidden",
        position: "relative",
        borderRadius: 100,
        backgroundColor: colors.background,
        display: "flex",
        alignItems: "center",
        border: "1px solid " + colors.primary,
        ...style,
      }}
      aria-label={t("challenge_completion", { percentage: percentage })}
      role="group"
      aria-roledescription={t("progress_bar")}
    >
      <div
        style={{
          width: `${barPercentage}%`,
          height: "100%",
          backgroundColor: colors.primary,
          borderRadius: 100,
        }}
      />
      {!isMobile && (
        <Text
          fontSize={12}
          fontWeight={700}
          color={colors.textWhite}
          style={{
            position: "absolute",
            left: `calc(${barPercentage}% - 40px)`,
            opacity: percentage >= showPercentageStartingFromDesktop ? 1 : 0,
          }}
          ariaHidden
        >
          {percentage}%
        </Text>
      )}
      {isMobile && (
        <Text
          fontSize={12}
          fontWeight={700}
          color={colors.textWhite}
          style={{
            position: "absolute",
            left: `calc(${barPercentage}% - 40px)`,
            opacity: percentage >= showPercentageStartingFromMobile ? 1 : 0,
          }}
          ariaHidden
        >
          {percentage}%
        </Text>
      )}
    </div>
  )
}

export default ChallengeProgressBar
