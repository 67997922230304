import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react"
import { get } from "../services/api/api"
import FootprintForm from "../models/footprintForm"
import Footprint from "../models/footprint"

interface FootprintContextInterface {
  userFootprintLoading: boolean
  userFootprintError: boolean
  formsListLoading: boolean
  formsListError: boolean
  userFootprint: Footprint | null
  formsList: FootprintForm[]
  getUserFootprint: (withLoading?: boolean) => Promise<boolean>
  getFormsList: (withLoading?: boolean) => Promise<boolean>
  gettingFootprint: boolean
  setGettingFootprint: Dispatch<SetStateAction<boolean>>
  userFormsListLoading: boolean
  userFormsListError: boolean
  userFormsList: FootprintForm[]
  getUserFormsList: (withLoading?: boolean) => Promise<boolean>
}

const FootprintContext = createContext<FootprintContextInterface>({
  userFootprintLoading: true,
  userFootprintError: false,
  formsListLoading: true,
  formsListError: false,
  userFootprint: null,
  formsList: [],
  getUserFootprint: async () => true,
  getFormsList: async () => true,
  gettingFootprint: false,
  setGettingFootprint: () => {},
  userFormsListLoading: true,
  userFormsListError: false,
  userFormsList: [],
  getUserFormsList: async () => true,
})

const FootprintController = ({ children }: { children: ReactNode }) => {
  // loadings
  const [userFootprintLoading, setUserFootprintLoading] =
    useState<boolean>(true)
  const [formsListLoading, setFormsListLoading] = useState<boolean>(true)
  const [userFormsListLoading, setUserFormsListLoading] =
    useState<boolean>(true)

  // errors
  const [userFootprintError, setUserFootprintError] = useState<boolean>(false)
  const [formsListError, setFormsListError] = useState<boolean>(false)
  const [userFormsListError, setUserFormsListError] = useState<boolean>(false)

  // states
  const [userFootprint, setUserFootprint] = useState<Footprint | null>(null)
  const [formsList, setFormsList] = useState<FootprintForm[]>([])
  const [userFormsList, setUserFormsList] = useState<FootprintForm[]>([])
  const [gettingFootprint, setGettingFootprint] = useState<boolean>(false)

  // get user footprint
  const getUserFootprint = async (withLoading = true) => {
    if (withLoading) {
      setUserFootprintLoading(true)
    }
    setUserFootprintError(false)

    try {
      const { data } = await get("/footprint/user")

      // parse data
      Object.keys(data).forEach((key) => {
        if (typeof data[key] === "number") {
          data[key] = Math.round((data[key] + Number.EPSILON) * 100) / 100
        }
      })

      console.log("user footprint", data)
      if (Object.keys(data).length) {
        setUserFootprint(data)
      }

      setUserFootprintLoading(false)

      return true
    } catch (e) {
      console.log("user footprint error", e)
      setUserFootprintError(true)

      return false
    }
  }

  // get footprint forms list
  const getFormsList = async (withLoading = true) => {
    if (withLoading) {
      setFormsListLoading(true)
    }
    setFormsListError(false)

    try {
      const { data } = await get("/footprint/form/index")

      console.log("forms list", data)
      setFormsList(data)

      setFormsListLoading(false)

      return true
    } catch (e) {
      console.log("forms list error", e)
      setFormsListError(true)

      return false
    }
  }

  // get user forms list
  const getUserFormsList = async (withLoading = true) => {
    if (withLoading) {
      setUserFormsListLoading(true)
    }
    setUserFormsListError(false)

    try {
      const { data } = await get("/footprint/form/user")

      console.log("user forms list", data)
      setUserFormsList(data)

      setUserFormsListLoading(false)

      return true
    } catch (e) {
      console.log("forms list error", e)
      setUserFormsListError(true)

      return false
    }
  }

  // initial fetch
  useEffect(() => {
    getUserFootprint()
    getFormsList()
    getUserFormsList()
  }, [])

  return (
    <FootprintContext.Provider
      value={{
        userFootprintLoading,
        userFootprintError,
        formsListLoading,
        formsListError,
        userFootprint,
        formsList,
        getUserFootprint,
        getFormsList,
        gettingFootprint,
        setGettingFootprint,
        userFormsListLoading,
        userFormsListError,
        userFormsList,
        getUserFormsList,
      }}
    >
      {children}
    </FootprintContext.Provider>
  )
}
export { FootprintController, FootprintContext }
