import { Stack } from "@mui/material"
import Title from "../../global/common/Title"
import { colors } from "../../../services/config/colors"
import Text from "../../global/common/Text"
import lightningIcon from "../../../assets/icons/lightning.svg"
import propicsRowImage from "../../../assets/images/propics-row.png"
import { useTranslation } from "react-i18next"
import { numberWithSeparators } from "../../../services/utils/utils"
import { CSSProperties, useContext } from "react"
import { ChallengeContext } from "../../../controllers/challenge"
import ChallengeProgressBar from "../common/ChallengeProgressBar"

const ChallengeCardDesktop = ({ style }: { style?: CSSProperties }) => {
  const { t } = useTranslation()
  const { challenge } = useContext(ChallengeContext)

  return (
    <Stack
      alignItems="center"
      style={{
        width: "75%",
        backgroundColor: colors.backgroundWhite,
        borderRadius: 8,
        position: "relative",
        paddingTop: 18,
        paddingBottom: 18,
        boxShadow: "0px 0px 24px 0px rgba(147, 160, 167, 0.16)",
        ...style,
      }}
      role="region"
      aria-label={t("challenge_card")}
    >
      <Title fontSize={14} lineHeight="17px" color={colors.primary}>
        {t("collective_challenge").toUpperCase()}
      </Title>
      <Title
        fontSize={24}
        lineHeight="32px"
        textAlign="center"
        style={{ marginTop: 12.5 }}
      >
        {challenge?.title}
      </Title>
      <Text
        fontSize={14}
        lineHeight="19px"
        fontWeight={400}
        style={{ marginTop: 12.5 }}
      >
        {t("reach_together")}
      </Text>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ width: "100%", height: 27, gap: 8.5, marginTop: 10 }}
      >
        <img src={lightningIcon} style={{ width: 16.4 }} alt="" />
        <Title fontSize={20} color={colors.primary}>
          {numberWithSeparators(challenge!.targetAmount)}{" "}
          {t("missions").toLowerCase()}
        </Title>
      </Stack>
      <ChallengeProgressBar
        percentage={Math.round(
          (challenge!.currentAmount / challenge!.targetAmount) * 100
        )}
        showPercentageStartingFromDesktop={18}
        style={{
          width: 260,
          height: 15.6,
          marginTop: 18,
        }}
      />
      <Stack
        direction="row"
        alignItems="center"
        style={{ width: 260, height: 19, gap: 8, marginTop: 10 }}
      >
        <img src={propicsRowImage} style={{ height: "100%" }} alt="" />
        <Text fontSize={12} fontWeight={400}>
          {numberWithSeparators(challenge!.userCount)} {t("partecipants")}
        </Text>
      </Stack>
      {/* <ButtonBase
        style={{
          width: "100%",
          height: 44,
          position: "absolute",
          bottom: 0,
          backgroundColor: colors.primary,
          borderBottomRightRadius: 8,
          borderBottomLeftRadius: 8,
          paddingInline: 25,
        }}
        onClick={() => {
          scrollWindowToTop()
          navigate("/challenge")
        }}
        aria-label={t("go_to_challenge")}
      >
        <Stack
          justifyContent="center"
          style={{ width: "100%", height: "100%" }}
        >
          <Title fontSize={16} color={colors.textWhite}>
            {t("go_to_challenge")}
          </Title>
          <img
            src={arrowRightWhiteIcon}
            style={{ width: 20, position: "absolute", right: 25 }}
            alt=""
          />
        </Stack>
      </ButtonBase> */}
    </Stack>
  )
}

export default ChallengeCardDesktop
