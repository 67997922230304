import { useContext } from "react"
import { MainContext } from "./controllers/main"
import Main from "./views/private/main"
import Error from "./views/public/error"
import Loading from "./views/public/loading"
import Onboarding from "./views/public/onboarding"
import OnboardingPostSignIn from "./views/public/onboardingPostSignIn"

const App = () => {
  const {
    loading,
    signInError,
    userError,
    viewOnboarding,
    viewSecondOnboarding,
  } = useContext(MainContext)

  if (signInError || userError) {
    return (
      <Error
        modules={[
          { name: "Authentication", error: signInError },
          { name: "User", error: userError },
        ]}
      />
    )
  }

  if (loading) {
    return <Loading />
  }

  if (viewOnboarding) {
    return <Onboarding />
  }

  if (viewSecondOnboarding) {
    return <OnboardingPostSignIn />
  }

  return <Main />
}

export default App
