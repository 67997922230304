import { Dispatch, SetStateAction } from "react"
import OnboardingPostSignInDesktop from "../OnboardingPostSignInDesktop"
import FeedbackContainer from "../../feedbacks/common/FeedbackContainer"

const OnboardingAlertDesktop = ({
  open,
  setOpen,
}: {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}) => {
  return (
    <FeedbackContainer
      open={open}
      setOpen={setOpen}
      withCloseButton={false}
      withPadding={false}
      withBackground={false}
    >
      <OnboardingPostSignInDesktop setOpen={setOpen} withBackground={false} />
    </FeedbackContainer>
  )
}

export default OnboardingAlertDesktop
