import { ButtonBase, Stack } from "@mui/material"
import { colors } from "../../services/config/colors"
import BreadcrumbDesktop from "../global/desktop/BreadcrumbDesktop"
import Title from "../global/common/Title"
import AppearFromSide from "../animations/AppearFromSide"
import { Dispatch, SetStateAction, useContext } from "react"
import { useNavigate } from "react-router-dom"
import { scrollWindowToTop } from "../../services/utils/utils"
import Text from "../global/common/Text"
import {
  desktopColumnsGap,
  desktopMaxWidth,
  footerHeight,
} from "../../services/config/constants"
import leafIcon from "../../assets/icons/leaf.svg"
import { BodySliceType } from "../../services/config/enum"
import SavingCard from "./common/SavingCard"
import infoIcon from "../../assets/icons/info-primary.svg"
import Button from "../global/common/Button"
import ActionDetailsBackgroundDesktop from "../../assets/svgComponents/ActionDetailsBackgroundDesktop"
import { MainContext } from "../../controllers/main"
import Action from "../../models/action"
import ActionsLoggedFeedbackMobile from "../feedbacks/mobile/ActionsLoggedFeedbackMobile"
import AlertInfo from "../global/common/AlertInfo"
import repeatableInfoImage from "../../assets/images/repeatable-action-info.png"
import { ActionsContext } from "../../controllers/actions"
import PaddingContainerDesktop from "../global/desktop/PaddingContainerDesktop"
import { useTranslation } from "react-i18next"

const ActionDetailsDesktop = ({
  action,
  timesLogged,
  isCurrentDate,
  repeatableInfoOpen,
  setRepeatableInfoOpen,
}: {
  action: Action
  timesLogged: number
  isCurrentDate: boolean
  repeatableInfoOpen: boolean
  setRepeatableInfoOpen: Dispatch<SetStateAction<boolean>>
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { windowHeight } = useContext(MainContext)
  const {
    actionsLoggedFeedbackOpen,
    setActionsLoggedFeedbackOpen,
    setSelectedActions,
    logActions,
  } = useContext(ActionsContext)

  return (
    <Stack
      style={{
        width: "100%",
        minHeight:
          windowHeight > 1200 ? `calc(100% - ${footerHeight}px)` : 1200,
        position: "relative",
      }}
    >
      {/* navbar background */}
      <div
        style={{
          width: "100%",
          height: 177,
          backgroundColor: colors.background,
        }}
      />
      {/* breadcrumb */}
      <BreadcrumbDesktop
        items={[
          {
            label: t("actions"),
            onClick: () => {
              scrollWindowToTop()
              navigate("/actions")
            },
          },
          ...(window.location.pathname.includes("/explore")
            ? [
                {
                  label: t("explore_all_actions"),
                  onClick: () => {
                    scrollWindowToTop()
                    navigate("/actions/explore")
                  },
                },
              ]
            : []),
          {
            label: action.document.title,
          },
        ]}
        selectedItem={window.location.pathname.includes("/explore") ? 2 : 1}
        style={{ marginTop: 22, zIndex: 1 }}
      />
      {/* main container */}
      <PaddingContainerDesktop>
        <Stack
          style={{
            width: "100%",
            maxWidth: desktopMaxWidth,
            position: "relative",
            zIndex: 1,
          }}
        >
          {/* header */}
          <AppearFromSide fade>
            <Title
              fontSize={36}
              lineHeight="46px"
              style={{ marginTop: 22 }}
              component="h1"
            >
              {action.document.title}
            </Title>
          </AppearFromSide>
          <AppearFromSide fade delay={0.05} style={{ marginTop: 22 }}>
            <Stack
              direction="row"
              style={{ width: "auto", height: 40, gap: 20 }}
            >
              <div
                style={{
                  width: "auto",
                  height: "100%",
                  paddingInline: 8,
                  backgroundColor: action.category.backTagColor,
                  display: "flex",
                  alignItems: "center",
                  borderRadius: 5,
                }}
              >
                <Text
                  fontSize={16}
                  fontWeight={700}
                  color={action.category.foreColor}
                >
                  {action.category.name}
                </Text>
              </div>
              <Stack
                direction="row"
                alignItems="center"
                style={{
                  width: "auto",
                  height: "100%",
                  paddingInline: 8,
                  borderRadius: 5,
                  border: "1px solid " + colors.primary,
                  gap: 8,
                }}
              >
                <img
                  src={leafIcon}
                  style={{ width: 12.5 }}
                  alt={t("points_extended")}
                />
                <Text fontSize={16} fontWeight={700} color={colors.primary}>
                  1
                </Text>
              </Stack>
            </Stack>
          </AppearFromSide>
          {/* content */}
          <AppearFromSide
            fade
            delay={0.1}
            style={{
              width: "100%",
              marginTop: 58,
              marginBottom: 58,
              position: "relative",
            }}
          >
            <Stack
              style={{
                width: "57.14%",
                height: "auto",
                padding: 46,
                backgroundColor: colors.backgroundWhite,
                boxShadow: "0px 0px 24px 0px rgba(147, 160, 167, 0.16)",
                borderRadius: 10,
              }}
            >
              {action.document.body && action.document.body.length
                ? action.document.body.map((item, index) => {
                    if (item.sliceType === BodySliceType.BodyTextSlice) {
                      return (
                        <div
                          className="html"
                          key={index}
                          dangerouslySetInnerHTML={{
                            __html: item.text.replace(/<\/?a[^>]*>/g, ""),
                          }}
                          style={{
                            fontSize: 20,
                            fontWeight: 300,
                            lineHeight: "32px",
                            color: colors.text,
                            overflow: "hidden",
                          }}
                        />
                      )
                    } else {
                      return null
                    }
                  })
                : null}
              {action.metrics.hasSavings ? (
                <Title
                  fontSize={24}
                  lineHeight="46px"
                  style={{
                    marginTop:
                      action.document.body && action.document.body.length
                        ? 28
                        : 0,
                  }}
                  component="h2"
                >
                  {t("action_savings")}
                </Title>
              ) : null}
              {action.metrics.hasSavings ? (
                <Stack
                  direction="row"
                  gap={2}
                  style={{
                    width: "100%",
                    maxWidth: "100%",
                    overflowX: "scroll",
                    marginTop: 18,
                  }}
                >
                  {action.metrics.co2Saving ? (
                    <SavingCard
                      metric="co2"
                      amount={action.metrics.co2Saving}
                    />
                  ) : null}
                  {action.metrics.waterSaving ? (
                    <SavingCard
                      metric="water"
                      amount={action.metrics.waterSaving}
                    />
                  ) : null}
                  {action.metrics.energySaving ? (
                    <SavingCard
                      metric="energy"
                      amount={action.metrics.energySaving}
                    />
                  ) : null}
                </Stack>
              ) : null}
              {action.document.assumptions ? (
                <ButtonBase
                  disableRipple
                  style={{
                    height: 22,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    alignSelf: "flex-start",
                    cursor: "pointer",
                    marginTop: 28,
                  }}
                  onClick={() => {
                    scrollWindowToTop()
                    navigate(window.location.pathname + "/calculations")
                  }}
                  aria-label={t("how_is_calculated")}
                >
                  <img
                    src={infoIcon}
                    style={{ width: 18, height: 18 }}
                    alt=""
                  />
                  <Text
                    fontSize={18}
                    lineHeight="22px"
                    fontWeight={500}
                    style={{ marginLeft: 6 }}
                    color={colors.primary}
                  >
                    {t("how_is_calculated")}
                  </Text>
                </ButtonBase>
              ) : null}
              {isCurrentDate && (
                <Stack style={{ marginTop: 58, gap: 16 }}>
                  {action.timesPerDay > 1 ? (
                    <Stack
                      direction="row"
                      alignItems="center"
                      style={{ width: "100%", position: "relative" }}
                    >
                      <ButtonBase
                        disableRipple
                        style={{
                          height: 22,
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          alignSelf: "flex-start",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setRepeatableInfoOpen(true)
                        }}
                        aria-label={t("log_more_than_once")}
                      >
                        <img
                          src={infoIcon}
                          style={{ width: 18, height: 18 }}
                          alt=""
                        />
                        <Text
                          fontSize={18}
                          lineHeight="22px"
                          fontWeight={500}
                          style={{ marginLeft: 6 }}
                          color={colors.primary}
                        >
                          {t("log_more_than_once")}
                        </Text>
                      </ButtonBase>
                      <Text
                        fontSize={18}
                        lineHeight="22px"
                        fontWeight={500}
                        style={{ position: "absolute", right: 0 }}
                        color={colors.primary}
                      >
                        {timesLogged}/{action.timesPerDay}
                      </Text>
                    </Stack>
                  ) : null}
                  <Button
                    disabled={timesLogged === action.timesPerDay}
                    width="100%"
                    onClick={() => {
                      setSelectedActions([])
                      logActions([action])
                    }}
                    title={
                      timesLogged === action.timesPerDay
                        ? t("action_logged")
                        : t("log")
                    }
                  >
                    {timesLogged === action.timesPerDay
                      ? t("action_logged")
                      : t("log")}
                  </Button>
                </Stack>
              )}
            </Stack>
          </AppearFromSide>
          {/* action image */}
          <img
            src={action.image}
            style={{
              width: 350,
              position: "absolute",
              top: 450,
              right: 0,
            }}
            alt=""
            aria-hidden="true"
          />
        </Stack>
      </PaddingContainerDesktop>
      {/* background */}
      <div
        style={{
          width: "100%",
          maxWidth: 1920,
          height: "100%",
          maxHeight: "100%",
          overflow: "hidden",
          position: "absolute",
          top: 0,
          right: 0,
        }}
      >
        <ActionDetailsBackgroundDesktop
          color={action.category.backColor}
          style={{ position: "absolute", top: 500 }}
        />
      </div>
      <ActionsLoggedFeedbackMobile
        open={actionsLoggedFeedbackOpen}
        setOpen={setActionsLoggedFeedbackOpen}
        plural={false}
      />
      <AlertInfo
        open={repeatableInfoOpen}
        setOpen={setRepeatableInfoOpen}
        title={t("log_more_than_once")}
        description={t("common_and_frequent_actions")}
        image={repeatableInfoImage}
        descriptionAlign="center"
      />
    </Stack>
  )
}

export default ActionDetailsDesktop
