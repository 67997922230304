import { ButtonBase, Fade, IconButton, Modal, Stack } from "@mui/material"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import chevronLeftIcon from "../../../assets/icons/chevron-left-circular.svg"
import { colors } from "../../../services/config/colors"
import Text from "../common/Text"
import { useTranslation } from "react-i18next"

const IosSelectMobile = ({
  open,
  setOpen,
  options,
  onOkPressed,
  currentOption = 0,
}: {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  options: { label: string; id: string; disabled?: boolean }[]
  onOkPressed: (newOption: number) => void
  currentOption?: number
}) => {
  const { t } = useTranslation()

  // scroll to correct option
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        const div = document.getElementById("ios-select-scrollable")
        div?.scrollTo({ top: currentOption * 35, behavior: "auto" })
      }, 50)
    }
  }, [open]) // eslint-disable-line react-hooks/exhaustive-deps

  // focused option
  const [focusedOption, setFocusedOption] = useState<number>(0)

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false)
      }}
      style={{
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "center",
      }}
    >
      <Fade in={open}>
        <div
          style={{
            width: "100%",
            height: 274,
            backgroundColor: colors.backgroundWhite,
            display: "flex",
            flexDirection: "column",
          }}
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          <Stack
            direction="row"
            style={{
              width: "100%",
              height: 40,
              borderBottom: "0.5px solid " + colors.textSecondary,
            }}
            alignItems="center"
          >
            <IconButton
              style={{
                minWidth: 34,
                maxWidth: 34,
                maxHeight: 34,
                marginLeft: 3,
              }}
              onClick={() => {
                setOpen(false)
              }}
              aria-label={t("close_episode_selection")}
            >
              <img
                src={chevronLeftIcon}
                style={{ width: 34, height: 34 }}
                alt=""
              />
            </IconButton>
            <div style={{ minWidth: "calc(100% - 88px)" }} />
            <IconButton
              disabled={
                !options[focusedOption] ||
                (options[focusedOption] && options[focusedOption].disabled)
              }
              style={{ minWidth: 34, width: 34, height: 34 }}
              onClick={() => {
                const div = document.getElementById("ios-select-scrollable")

                onOkPressed(
                  div!.scrollTop ? Math.round(div!.scrollTop / 35) : 0
                )
                setOpen(false)
              }}
              aria-label={t("select_episode")}
            >
              <Text
                fontSize={17}
                fontWeight={500}
                color={
                  !options[focusedOption] ||
                  (options[focusedOption] && options[focusedOption].disabled)
                    ? colors.disabled
                    : colors.primary
                }
                style={{ transition: "200ms" }}
              >
                {t("ok")}
              </Text>
            </IconButton>
          </Stack>
          <div
            style={{
              width: "100%",
              height: 200,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <div
              style={{
                width: "calc(100% - 16px)",
                height: 35,
                backgroundColor: colors.backgroundLight,
                position: "absolute",
                left: 8,
                borderRadius: 8,
              }}
            />
            <div
              id="ios-select-scrollable"
              style={{
                width: "100%",
                height: 200,
                maxHeight: 200,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                position: "relative",
                overflowY: "scroll",
                scrollSnapType: "y mandatory",
                boxSizing: "border-box",
              }}
              onScroll={() => {
                const div = document.getElementById("ios-select-scrollable")
                setFocusedOption(Math.round(div!.scrollTop / 35))
              }}
            >
              <div style={{ width: "100%", minHeight: 82.5 }} />
              {options.map((option, index) => (
                <ButtonBase
                  key={option.id}
                  style={{ width: "100%" }}
                  onClick={() => {
                    const div = document.getElementById("ios-select-scrollable")
                    div?.scrollTo({ top: index * 35, behavior: "smooth" })
                  }}
                >
                  <Text
                    style={{
                      minHeight: 35,
                      scrollSnapAlign: "center",
                      paddingTop: 4,
                      boxSizing: "border-box",
                      transition: "100ms",
                    }}
                    fontWeight={400}
                    fontSize={22}
                    lineHeight="26px"
                    width="calc(100% - 30px)"
                    ellipsis
                    color={
                      index === focusedOption ? undefined : colors.textSecondary
                    }
                  >
                    {option.label}
                  </Text>
                </ButtonBase>
              ))}
              <div style={{ width: "100%", minHeight: 82.5 }} />
            </div>
            <div
              style={{
                width: "100%",
                height: 15,
                position: "absolute",
                top: -0.3,
                background:
                  "linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.6) 30%, rgba(255,255,255,0.9) 50%, rgba(255,255,255,1) 100%)",
              }}
            />
            <div
              style={{
                width: "100%",
                height: 15,
                position: "absolute",
                bottom: -0.3,
                background:
                  "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.9) 30%, rgba(255,255,255,0.6) 50%, rgba(255,255,255,0) 100%)",
              }}
            />
          </div>
        </div>
      </Fade>
    </Modal>
  )
}

export default IosSelectMobile
