import { Stack } from "@mui/material"
import { Dispatch, SetStateAction, useContext } from "react"
import { useNavigate } from "react-router-dom"
import { ActionsContext } from "../../controllers/actions"
import { colors } from "../../services/config/colors"
import { BodySliceType } from "../../services/config/enum"
import infoIcon from "../../assets/icons/info-primary.svg"
import repeatableInfoImage from "../../assets/images/repeatable-action-info.png"
import Text from "../global/common/Text"
import Title from "../global/common/Title"
import SavingCard from "./common/SavingCard"
import BackButtonMobile from "../global/mobile/BackButtonMobile"
import Button from "../global/common/Button"
import FadeFromTop from "../animations/FadeFromTop"
import FadeFromBottom from "../animations/FadeFromBottom"
import AppearFromSide from "../animations/AppearFromSide"
import AlertInfo from "../global/common/AlertInfo"
import ActionsLoggedFeedbackMobile from "../feedbacks/mobile/ActionsLoggedFeedbackMobile"
import Action from "../../models/action"
import { scrollWindowToTop } from "../../services/utils/utils"
import { useTranslation } from "react-i18next"

const ActionDetailsMobile = ({
  action,
  timesLogged,
  isCurrentDate,
  repeatableInfoOpen,
  setRepeatableInfoOpen,
}: {
  action: Action
  timesLogged: number
  isCurrentDate: boolean
  repeatableInfoOpen: boolean
  setRepeatableInfoOpen: Dispatch<SetStateAction<boolean>>
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    logActions,
    setSelectedActions,
    actionsLoggedFeedbackOpen,
    setActionsLoggedFeedbackOpen,
  } = useContext(ActionsContext)

  return (
    <div style={{ width: "100%", minHeight: "100%" }}>
      <div
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingBottom: isCurrentDate ? 136 : 36,
        }}
      >
        {/* back button */}
        <BackButtonMobile
          style={{ position: "absolute", top: 22, left: 16, zIndex: 1 }}
          onClick={() => {
            const pathname = window.location.pathname
            navigate(pathname.slice(0, pathname.lastIndexOf("/")), {
              state: { fromAction: true },
            })
          }}
        />
        {/* top background */}
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: 170,
            minHeight: 170,
            backgroundColor: action.category.backColor,
            top: 0,
            borderBottomLeftRadius: 24,
            borderBottomRightRadius: 24,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            src={action.image}
            style={{ width: 130, height: 130, marginTop: 5 }}
            alt=""
          />
        </div>
        {/* header */}
        <FadeFromTop
          style={{
            width: "calc(100% - 32px)",
            marginTop: 125,
            marginBottom: 8,
          }}
        >
          <Stack
            alignItems="center"
            style={{
              width: "100%",
              backgroundColor: colors.backgroundWhite,
              boxShadow: "0px 0px 24px rgba(17, 67, 97, 0.08)",
              borderRadius: 10,
              position: "relative",
              padding: 24,
              gap: 16,
            }}
          >
            <Text
              fontSize={12}
              lineHeight="16px"
              fontWeight={700}
              color={colors.primary}
            >
              {t("action").toUpperCase()}
            </Text>
            <Title
              fontSize={20}
              lineHeight="25px"
              textAlign="center"
              component="h1"
            >
              {action.document.title}
            </Title>
            <div
              style={{
                width: "auto",
                height: 25,
                paddingInline: 6,
                display: "flex",
                alignItems: "center",
                borderRadius: 4,
                backgroundColor: action.category.backTagColor,
              }}
            >
              <Text
                fontSize={10}
                lineHeight="16px"
                fontWeight={600}
                color={action.category.foreColor}
              >
                {action.category.name}
              </Text>
            </div>
          </Stack>
        </FadeFromTop>
        {/* action body */}
        <AppearFromSide fade>
          {action.document.body && action.document.body.length
            ? action.document.body.map((item, index) => {
                if (item.sliceType === BodySliceType.BodyTextSlice) {
                  return (
                    <div
                      className="html"
                      key={index}
                      dangerouslySetInnerHTML={{
                        __html: item.text.replace(/<\/?a[^>]*>/g, ""),
                      }}
                      style={{
                        fontSize: 16,
                        fontWeight: 300,
                        lineHeight: "22px",
                        color: colors.text,
                        overflow: "hidden",
                        paddingInline: 16,
                      }}
                    />
                  )
                } else {
                  return null
                }
              })
            : null}
        </AppearFromSide>
        {/* action savings */}
        {action.metrics.hasSavings ? (
          <AppearFromSide
            style={{
              width: "100%",
              marginTop:
                action.document.body && action.document.body.length ? 16 : 0,
            }}
            delay={0.05}
            fade
          >
            <Title
              textAlign="left"
              fontSize={18}
              lineHeight="25px"
              paddingLeft={16}
              style={{ width: "100%" }}
              component="h2"
            >
              {t("action_savings")}
            </Title>
          </AppearFromSide>
        ) : null}
        {action.metrics.hasSavings ? (
          <AppearFromSide
            style={{ width: "100%", marginTop: 16 }}
            delay={0.1}
            fade
          >
            <Stack
              direction="row"
              gap={1.5}
              style={{
                width: "100%",
                overflowX: "scroll",
                paddingInline: 16,
              }}
            >
              {action.metrics.co2Saving ? (
                <SavingCard metric="co2" amount={action.metrics.co2Saving} />
              ) : null}
              {action.metrics.waterSaving ? (
                <SavingCard
                  metric="water"
                  amount={action.metrics.waterSaving}
                />
              ) : null}
              {action.metrics.energySaving ? (
                <SavingCard
                  metric="energy"
                  amount={action.metrics.energySaving}
                />
              ) : null}
            </Stack>
          </AppearFromSide>
        ) : null}
        {/* assumptions (come lo calcoliamo) */}
        {action.document.assumptions ? (
          <AppearFromSide
            style={{
              width: "100%",
              height: 22,
              marginTop: 16,
              paddingLeft: 16,
            }}
            delay={0.15}
            fade
          >
            <div
              style={{
                height: 22,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                alignSelf: "flex-start",
              }}
              onClick={() => {
                scrollWindowToTop()
                navigate(window.location.pathname + "/calculations")
              }}
            >
              <img src={infoIcon} style={{ width: 16, height: 16 }} alt="" />
              <Text
                fontSize={12}
                lineHeight="16px"
                fontWeight={700}
                style={{ marginLeft: 5 }}
                color={colors.primary}
              >
                {t("how_is_calculated")}
              </Text>
            </div>
          </AppearFromSide>
        ) : null}
        {/* log button */}
        {isCurrentDate ? (
          <FadeFromBottom
            style={{ width: "100%", position: "fixed", bottom: 0 }}
          >
            <div
              style={{
                width: "100%",
                paddingTop: 16,
                paddingBottom: 16,
                boxShadow: "0px 0px 64px rgba(3, 56, 120, 0.12)",
                borderRadius: "10px 10px 0px 0px",
                backgroundColor: colors.backgroundWhite,
              }}
            >
              {action.timesPerDay > 1 ? (
                <Stack
                  style={{
                    height: 16,
                    marginLeft: 16,
                    paddingRight: 16,
                    marginBottom: 16,
                    position: "relative",
                  }}
                  alignItems="center"
                  direction="row"
                >
                  <img
                    src={infoIcon}
                    style={{ width: 16, height: 16 }}
                    onClick={() => {
                      setRepeatableInfoOpen(true)
                    }}
                    alt=""
                  />
                  <div
                    onClick={() => {
                      setRepeatableInfoOpen(true)
                    }}
                  >
                    <Text
                      fontSize={12}
                      lineHeight="16px"
                      fontWeight={700}
                      style={{ marginLeft: 5 }}
                      color={colors.primary}
                    >
                      {t("log_more_than_once")}
                    </Text>
                  </div>
                  <Text
                    fontSize={12}
                    lineHeight="16px"
                    fontWeight={700}
                    style={{ position: "absolute", right: 16 }}
                    color={colors.primary}
                  >
                    {timesLogged}/{action.timesPerDay}
                  </Text>
                </Stack>
              ) : null}
              <Button
                width="calc(100% - 32px)"
                style={{ marginLeft: 16 }}
                disabled={timesLogged === action.timesPerDay}
                onClick={() => {
                  setSelectedActions([])
                  logActions([action])
                }}
                title={
                  timesLogged === action.timesPerDay
                    ? t("action_logged")
                    : t("log")
                }
              >
                {timesLogged === action.timesPerDay
                  ? t("action_logged")
                  : t("log")}
              </Button>
            </div>
          </FadeFromBottom>
        ) : null}
      </div>
      <ActionsLoggedFeedbackMobile
        open={actionsLoggedFeedbackOpen}
        setOpen={setActionsLoggedFeedbackOpen}
        plural={false}
      />
      <AlertInfo
        open={repeatableInfoOpen}
        setOpen={setRepeatableInfoOpen}
        title={t("log_more_than_once")}
        description={t("common_and_frequent_actions")}
        image={repeatableInfoImage}
        descriptionAlign="center"
      />
    </div>
  )
}

export default ActionDetailsMobile
