import { Dispatch, SetStateAction } from "react"
import FeedbackContainer from "../../feedbacks/common/FeedbackContainer"
import OnboardingPostSignInMobile from "../OnboardingPostSignInMobile"

const OnboardingAlertMobile = ({
  open,
  setOpen,
}: {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}) => {
  return (
    <FeedbackContainer
      open={open}
      setOpen={setOpen}
      withCloseButton={false}
      withPadding={false}
    >
      <OnboardingPostSignInMobile setOpen={setOpen} />
    </FeedbackContainer>
  )
}

export default OnboardingAlertMobile
