import { useContext } from "react"
import ConfettiExplosion from "react-confetti-explosion"
import { MainContext } from "../../../controllers/main"

const Confetti = () => {
  const { isMobile } = useContext(MainContext)

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
        position: "absolute",
        top: 0,
      }}
    >
      <ConfettiExplosion
        force={0.4}
        duration={3000}
        particleCount={120}
        width={isMobile ? window.innerWidth + 300 : window.innerWidth}
        particleSize={7}
        zIndex={100}
      />
    </div>
  )
}

export default Confetti
