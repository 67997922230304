import { ButtonBase, Stack } from "@mui/material"
import { colors } from "../../services/config/colors"
import { useNavigate } from "react-router-dom"
import arrowLefIcon from "../../assets/icons/arrow-left.svg"
import Title from "../global/common/Title"
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import AppearFromSide from "../animations/AppearFromSide"
import Button from "../global/common/Button"
import { ActionsContext } from "../../controllers/actions"
import ActionCardHorizontal from "./common/ActionCardHorizontal"
import { MainContext } from "../../controllers/main"
import Alert from "../global/common/Alert"
import ActionsLoggedFeedbackMobile from "../feedbacks/mobile/ActionsLoggedFeedbackMobile"
import { TagsContext } from "../../controllers/tags"
import CategoryFilterBadge from "./common/CategoryFilterBadge"
import Action from "../../models/action"
import { useTranslation } from "react-i18next"
import {
  accessibilityHeightThreshold,
  maximumActionsPerTime,
} from "../../services/config/constants"

const ActionsExploreMobile = ({
  selectedCategory,
  filteredActionsList,
  noActionsAlertOpen,
  setNoActionsAlertOpen,
}: {
  selectedCategory: string
  filteredActionsList: Action[]
  noActionsAlertOpen: boolean
  setNoActionsAlertOpen: Dispatch<SetStateAction<boolean>>
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { windowHeight } = useContext(MainContext)
  const { categories } = useContext(TagsContext)
  const {
    selectedActions,
    logActions,
    actionsLoggedFeedbackOpen,
    setActionsLoggedFeedbackOpen,
    actionsLoggedFeedbackPlural,
    setSelectedCategory,
    actionsPerTimeLimitAlertOpen,
    setActionsPerTimeLimitAlertOpen,
  } = useContext(ActionsContext)

  // debounce for log button
  const canClickLogButton = useRef<boolean>(true)

  // hide filters bar on scroll when screen is too short
  const [hideFilters, setHideFilters] = useState<boolean>(false)

  useEffect(() => {
    const actionsContainer = document.getElementById(
      "actions-scrollable-container"
    )!

    if (windowHeight > accessibilityHeightThreshold) {
      setHideFilters(false)
    } else if (actionsContainer.scrollTop > 20) {
      setHideFilters(true)
    }
  }, [windowHeight])

  return (
    <Stack
      style={{
        width: "100%",
        minHeight: "100%",
        backgroundColor: colors.backgroundWhite,
        paddingTop: 22,
        overflowX: "hidden",
        position: "relative",
      }}
    >
      {/* header */}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        style={{ width: "100%", height: 48, paddingInline: 16 }}
      >
        <ButtonBase
          style={{
            width: 48,
            minWidth: 48,
            height: 48,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 12,
          }}
          onClick={() => {
            navigate("/actions")
          }}
          aria-label={t("go_back")}
        >
          <img src={arrowLefIcon} style={{ width: 15 }} alt="" />
        </ButtonBase>
        <Title
          fontSize={20}
          lineHeight="23px"
          style={{ height: 23 }}
          component="h1"
        >
          {t("explore_actions")}
        </Title>
        <div
          style={{
            width: 48,
            minWidth: 48,
            height: 48,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 12,
          }}
        />
      </Stack>
      {/* filters row */}
      <AppearFromSide
        fade
        style={{
          marginTop: hideFilters ? 0 : 14,
          height: hideFilters ? 0 : 32,
          overflow: "hidden",
          transition: "height 100ms, margin-top 100ms",
        }}
      >
        <Stack
          role="radiogroup"
          aria-label={t("filters")}
          direction="row"
          alignItems="center"
          style={{
            width: "100%",
            maxWidth: "100%",
            overflowX: "scroll",
            gap: 6,
            paddingInline: 16,
          }}
        >
          <CategoryFilterBadge
            category={{
              id: "catDefault",
              name: t("featured"),
              backColor: colors.primary,
              backTagColor: colors.primary,
              foreColor: colors.textWhite,
            }}
            selected={selectedCategory === "catDefault"}
            onClick={() => {
              setSelectedCategory("catDefault")
            }}
          />
          {categories.map((category, index) => (
            <CategoryFilterBadge
              key={category.id}
              category={category}
              selected={selectedCategory === category.id}
              onClick={() => {
                setSelectedCategory(category.id)
              }}
            />
          ))}
        </Stack>
      </AppearFromSide>
      {/* actions list */}
      <Stack
        id="actions-scrollable-container"
        style={{
          width: "100%",
          maxHeight:
            windowHeight > accessibilityHeightThreshold
              ? windowHeight - 204
              : hideFilters
              ? windowHeight - 70
              : windowHeight - 116,
          overflowY: "scroll",
          paddingInline: 16,
          paddingTop: 12,
          paddingBottom: 12,
          gap: 12,
        }}
        onScroll={() => {
          const actionsContainer = document.getElementById(
            "actions-scrollable-container"
          )!
          if (windowHeight <= accessibilityHeightThreshold) {
            if (actionsContainer.scrollTop > 20) {
              setHideFilters(true)
            } else {
              setHideFilters(false)
            }
          }
        }}
      >
        {/* number of items for accessibility */}
        <AppearFromSide fade>
          <Title
            key={selectedCategory}
            fontSize={12}
            color={colors.textSecondary}
            ariaLive="polite"
          >
            {`${filteredActionsList.length} ${
              filteredActionsList.length === 1
                ? t("action").toLowerCase()
                : t("actions").toLowerCase()
            }`}
          </Title>
        </AppearFromSide>
        {filteredActionsList.map((action, index) => (
          <AppearFromSide
            key={action.id + selectedCategory}
            fade
            delay={0.025 * (index + 1)}
          >
            <ActionCardHorizontal
              width="100%"
              action={action}
              showCategoryAndCo2
            />
          </AppearFromSide>
        ))}
        {/* log button for accessibility (hidden filters) */}
        {windowHeight <= accessibilityHeightThreshold && (
          <Button
            width="100%"
            style={{ minHeight: 56, marginTop: 13 }}
            onClick={() => {
              if (!selectedActions.length) {
                setNoActionsAlertOpen(true)
                return
              }

              if (canClickLogButton.current) {
                logActions()

                canClickLogButton.current = false
                setTimeout(() => {
                  canClickLogButton.current = true
                }, 800)
              }
            }}
            title={
              selectedActions.length === 0
                ? t("log")
                : selectedActions.length === 1
                ? t("log_one_action")
                : t("log_count_actions", { count: selectedActions.length })
            }
          >
            {selectedActions.length === 0
              ? t("log")
              : selectedActions.length === 1
              ? t("log_one_action")
              : t("log_count_actions", { count: selectedActions.length })}
          </Button>
        )}
      </Stack>
      {/* log button */}
      {windowHeight > accessibilityHeightThreshold && (
        <div
          style={{
            width: "100%",
            paddingTop: 16,
            paddingBottom: 16,
            paddingInline: 16,
            position: "absolute",
            bottom: 0,
            boxShadow: "0px 0px 24px 0px rgba(147, 160, 167, 0.16)",
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
            backgroundColor: colors.backgroundWhite,
          }}
        >
          <Button
            width="100%"
            onClick={() => {
              if (!selectedActions.length) {
                setNoActionsAlertOpen(true)
                return
              }

              if (canClickLogButton.current) {
                logActions()

                canClickLogButton.current = false
                setTimeout(() => {
                  canClickLogButton.current = true
                }, 800)
              }
            }}
            title={
              selectedActions.length === 0
                ? t("log")
                : selectedActions.length === 1
                ? t("log_one_action")
                : t("log_count_actions", { count: selectedActions.length })
            }
          >
            {selectedActions.length === 0
              ? t("log")
              : selectedActions.length === 1
              ? t("log_one_action")
              : t("log_count_actions", { count: selectedActions.length })}
          </Button>
        </div>
      )}
      {/* alerts */}
      <ActionsLoggedFeedbackMobile
        open={actionsLoggedFeedbackOpen}
        setOpen={setActionsLoggedFeedbackOpen}
        plural={actionsLoggedFeedbackPlural}
      />
      <Alert
        open={noActionsAlertOpen}
        title={t("no_actions_selected")}
        description={t("select_action_to_log")}
        primaryActionLabel={t("ok")}
        primaryActionOnClick={() => {
          setNoActionsAlertOpen(false)
        }}
      />
      <Alert
        open={actionsPerTimeLimitAlertOpen}
        title={t("warning")}
        description={t("actions_limit", { count: maximumActionsPerTime })}
        primaryActionLabel={t("i_understand")}
        primaryActionOnClick={() => {
          setActionsPerTimeLimitAlertOpen(false)
        }}
      />
    </Stack>
  )
}

export default ActionsExploreMobile
