import { ButtonBase, Stack } from "@mui/material"
import { Dispatch, SetStateAction, useContext, useRef, useState } from "react"
import { MainContext } from "../../controllers/main"
import { colors } from "../../services/config/colors"
import Button from "../global/common/Button"
import { useTranslation } from "react-i18next"
import FadeFromTop from "../animations/FadeFromTop"
import awLogo from "../../assets/images/aw-logo.svg"
import partnerLogo from "../../assets/images/partner-logo.png"
import partnerLogoAlt from "../../assets/images/partner-logo-alt.png"
import Title from "../global/common/Title"
import Text from "../global/common/Text"
import measureWhiteIcon from "../../assets/icons/missions/missionsTypes/measure-white.svg"
import learnWhiteIcon from "../../assets/icons/missions/missionsTypes/learn-white.svg"
import missionCardMeasureImage from "../../assets/images/onboarding/mission-card-measure.png"
import missionCardLearnImage from "../../assets/images/onboarding/mission-card-learn.png"
import missionCardActImage from "../../assets/images/onboarding/mission-card-act.png"
import actWhiteIcon from "../../assets/icons/missions/missionsTypes/act-white.svg"
import chevronRightBigPrimaryIcon from "../../assets/icons/chevron-right-big-primary.svg"
import chevronLeftBigPrimaryIcon from "../../assets/icons/chevron-left-big-primary.svg"
import { focusElement } from "../../services/utils/utils"
import { MissionType } from "../../services/config/enum"

const OnboardingPostSignInMobile = ({
  height,
  borderRadius,
  continueButtonBottom,
  continueButtonWidth,
  setOpen,
}: {
  height?: number | string
  borderRadius?: number
  continueButtonBottom?: number
  continueButtonWidth?: number
  setOpen?: Dispatch<SetStateAction<boolean>>
}) => {
  const { t } = useTranslation()
  const {
    windowHeight,
    setViewSecondOnboarding,
    setUserFirstAccess,
    isMobile,
    user,
    country,
  } = useContext(MainContext)

  // loading
  const [loading, setLoading] = useState<boolean>(false)

  // current visualized page
  const [currentPage, setCurrentPage] = useState<number>(0)

  // can click continue button
  const canClick = useRef<boolean>(true)

  // if user is using screen reader, based on "onFocusVisible" event
  const screenReaderEnabled = useRef<boolean>(false)

  return (
    <Stack
      alignItems="center"
      style={{
        width: "100%",
        height: height ?? windowHeight,
        maxHeight: height ?? windowHeight,
        backgroundColor: colors.background,
        position: "relative",
        overflowY: "scroll",
        borderRadius: borderRadius,
      }}
    >
      {/* header */}
      <FadeFromTop style={{ marginTop: 26 }}>
        <Stack direction="row" style={{ gap: 30 }}>
          <img src={awLogo} style={{ height: 18 }} alt={t("aworld_logo_alt")} />
          <img
            src={country === "US" ? partnerLogo : partnerLogoAlt}
            style={{ width: 50 }}
            alt={t("partner_logo_alt")}
          />
        </Stack>
      </FadeFromTop>
      {/* title */}
      <FadeFromTop style={{ marginTop: 30 }} delay={0.05}>
        <Title
          fontSize={isMobile ? 26 : 28}
          lineHeight={isMobile ? "30px" : "32px"}
          component="h1"
        >
          {t("the_missions").charAt(0).toUpperCase() +
            t("the_missions").slice(1).toLowerCase()}
        </Title>
      </FadeFromTop>
      <FadeFromTop style={{ marginTop: 12 }} delay={0.1}>
        <Text
          fontSize={isMobile ? 18 : 20}
          lineHeight={isMobile ? "25px" : "27px"}
          fontWeight={400}
          component="h2"
        >
          {t("what_are_missions")}
        </Text>
      </FadeFromTop>
      {/* dots */}
      <FadeFromTop style={{ width: "100%", marginTop: 25 }} delay={0.15}>
        {isMobile ? (
          <Stack
            direction="row"
            justifyContent="center"
            style={{
              width: "100%",
              height: 8,
              gap: 4,
            }}
          >
            <div
              style={{
                width: currentPage === 0 ? 16 : 8,
                height: 8,
                borderRadius: 100,
                backgroundColor:
                  currentPage === 0 ? colors.primary : colors.disabled,
                transition: "300ms",
              }}
            />
            <div
              style={{
                width: currentPage === 1 ? 16 : 8,
                height: 8,
                borderRadius: 100,
                backgroundColor:
                  currentPage === 1 ? colors.primary : colors.disabled,
                transition: "300ms",
              }}
            />
            <div
              style={{
                width: currentPage === 2 ? 16 : 8,
                height: 8,
                borderRadius: 100,
                backgroundColor:
                  currentPage === 2 ? colors.primary : colors.disabled,
                transition: "300ms",
              }}
            />
          </Stack>
        ) : (
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{
              width: "100%",
              height: 51,
              gap: 35,
            }}
            role="group"
            aria-label={t("carousel_controls")}
          >
            <ButtonBase
              style={{ width: 51, height: 51, borderRadius: 30 }}
              onClick={() => {
                if (canClick.current) {
                  if (currentPage !== 0) {
                    const container = document.getElementById(
                      "onboarding-container"
                    )
                    container?.scrollTo({
                      left: (currentPage - 1) * 505,
                      behavior: screenReaderEnabled.current ? "auto" : "smooth",
                    })
                  }

                  canClick.current = false
                  const timeoutId = setTimeout(() => {
                    canClick.current = true
                    clearTimeout(timeoutId)
                  }, 200)
                }
              }}
              aria-label={t("previous_slide")}
              disabled={loading}
              aria-disabled={currentPage === 0}
              onFocusVisible={() => {
                screenReaderEnabled.current = true
              }}
              onBlur={() => {
                screenReaderEnabled.current = false
              }}
            >
              <img
                src={chevronLeftBigPrimaryIcon}
                style={{ height: 51 }}
                alt=""
              />
            </ButtonBase>
            <Stack
              direction="row"
              alignItems="center"
              style={{ height: 17, gap: 9 }}
            >
              <ButtonBase
                disabled={loading}
                style={{
                  width: currentPage === 0 ? 22 : 15,
                  height: currentPage === 0 ? 17 : 11.6,
                  backgroundColor:
                    currentPage === 0 ? colors.primary : colors.disabled,
                  borderRadius: 5,
                  transition: "200ms",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => {
                  const container = document.getElementById(
                    "onboarding-container"
                  )
                  container?.scrollTo({
                    left: 0 * 505,
                    behavior: screenReaderEnabled.current ? "auto" : "smooth",
                  })
                }}
                aria-label={t("go_to_slide_count_of_total", {
                  count: 1,
                  total: 3,
                })}
                aria-current={currentPage === 0 ? "true" : "false"}
                onFocusVisible={() => {
                  screenReaderEnabled.current = true
                }}
                onBlur={() => {
                  screenReaderEnabled.current = false
                }}
              >
                <Text
                  fontSize={16}
                  fontWeight={700}
                  color={colors.textWhite}
                  style={{
                    opacity: currentPage === 0 ? 1 : 0,
                    transition: "200ms",
                  }}
                >
                  1
                </Text>
              </ButtonBase>
              <ButtonBase
                disabled={currentPage === 1 || loading}
                style={{
                  width: currentPage === 1 ? 22 : 15,
                  height: currentPage === 1 ? 17 : 11.6,
                  backgroundColor:
                    currentPage === 1 ? colors.primary : colors.disabled,
                  borderRadius: 5,
                  transition: "200ms",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => {
                  const container = document.getElementById(
                    "onboarding-container"
                  )
                  container?.scrollTo({
                    left: 1 * 505,
                    behavior: screenReaderEnabled.current ? "auto" : "smooth",
                  })
                }}
                aria-label={t("go_to_slide_count_of_total", {
                  count: 2,
                  total: 3,
                })}
                aria-current={currentPage === 1 ? "true" : "false"}
                onFocusVisible={() => {
                  screenReaderEnabled.current = true
                }}
                onBlur={() => {
                  screenReaderEnabled.current = false
                }}
              >
                <Text
                  fontSize={16}
                  fontWeight={700}
                  color={colors.textWhite}
                  style={{
                    opacity: currentPage === 1 ? 1 : 0,
                    transition: "200ms",
                  }}
                >
                  2
                </Text>
              </ButtonBase>
              <ButtonBase
                disabled={currentPage === 2 || loading}
                style={{
                  width: currentPage === 2 ? 22 : 15,
                  height: currentPage === 2 ? 17 : 11.6,
                  backgroundColor:
                    currentPage === 2 ? colors.primary : colors.disabled,
                  borderRadius: 5,
                  transition: "200ms",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => {
                  const container = document.getElementById(
                    "onboarding-container"
                  )
                  container?.scrollTo({
                    left: 2 * 505,
                    behavior: screenReaderEnabled.current ? "auto" : "smooth",
                  })
                }}
                aria-label={t("go_to_slide_count_of_total", {
                  count: 3,
                  total: 3,
                })}
                aria-current={currentPage === 2 ? "true" : "false"}
                onFocusVisible={() => {
                  screenReaderEnabled.current = true
                }}
                onBlur={() => {
                  screenReaderEnabled.current = false
                }}
              >
                <Text
                  fontSize={16}
                  fontWeight={700}
                  color={colors.textWhite}
                  style={{
                    opacity: currentPage === 2 ? 1 : 0,
                    transition: "200ms",
                  }}
                >
                  3
                </Text>
              </ButtonBase>
            </Stack>
            <ButtonBase
              style={{ width: 51, height: 51, borderRadius: 30 }}
              onClick={() => {
                if (canClick.current) {
                  if (currentPage !== 2) {
                    const container = document.getElementById(
                      "onboarding-container"
                    )
                    container?.scrollTo({
                      left: (currentPage + 1) * 505,
                      behavior: screenReaderEnabled.current ? "auto" : "smooth",
                    })
                  }

                  canClick.current = false
                  const timeoutId = setTimeout(() => {
                    canClick.current = true
                    clearTimeout(timeoutId)
                  }, 200)
                }
              }}
              aria-label={t("next_slide")}
              disabled={loading}
              aria-disabled={currentPage === 2}
              onFocusVisible={() => {
                screenReaderEnabled.current = true
              }}
              onBlur={() => {
                screenReaderEnabled.current = false
              }}
            >
              <img
                src={chevronRightBigPrimaryIcon}
                style={{ height: 51 }}
                alt=""
              />
            </ButtonBase>
          </Stack>
        )}
      </FadeFromTop>
      {/* content */}
      <FadeFromTop style={{ width: "100%" }} delay={0.2}>
        <Stack
          id="onboarding-container"
          direction="row"
          style={{
            width: "100%",
            maxWidth: "100%",
            height: 600,
            minHeight: 600,
            overflowX: isMobile ? "scroll" : "hidden",
            scrollSnapType: "x mandatory",
          }}
          onScroll={() => {
            const container = document.getElementById("onboarding-container")!
            setCurrentPage(
              isMobile
                ? Math.round(container.scrollLeft / window.innerWidth)
                : Math.round(container.scrollLeft / 505)
            )
          }}
          tabIndex={0}
        >
          {/* first page */}
          <Content
            missionCardImage={missionCardMeasureImage}
            missionIcon={measureWhiteIcon}
            missionColor={colors.missions.measure}
            title={t("measure")}
            description={t("onboarding_measure_description")}
            hidden={currentPage !== 0}
            missionType={MissionType.MEASURE}
          />
          {/* second page */}
          <Content
            missionCardImage={missionCardLearnImage}
            missionIcon={learnWhiteIcon}
            missionColor={colors.missions.learn}
            title={t("learn")}
            description={t("onboarding_learn_description")}
            hidden={currentPage !== 1}
            missionType={MissionType.LEARN}
          />
          {/* third page */}
          <Content
            missionCardImage={missionCardActImage}
            missionIcon={actWhiteIcon}
            missionColor={colors.missions.act}
            title={t("act")}
            description={t("onboarding_act_description")}
            hidden={currentPage !== 2}
            missionType={MissionType.ACT}
          />
        </Stack>
      </FadeFromTop>
      {/* continue button */}
      <Button
        loading={loading}
        title={currentPage === 2 ? t("lets_start") : t("continue")}
        style={{
          width: continueButtonWidth ?? "calc(100% - 32px)",
          position: "fixed",
          bottom: continueButtonBottom ?? 22,
          left: isMobile ? 16 : undefined,
        }}
        onClick={async () => {
          if (canClick.current) {
            if (currentPage === 2) {
              if (user?.firstAccess) {
                setLoading(true)

                await setUserFirstAccess()

                setViewSecondOnboarding(false)
                return
              } else if (setOpen) {
                setOpen(false)
                return
              }
            }

            canClick.current = false

            const container = document.getElementById("onboarding-container")
            container?.scrollTo({
              left: (currentPage + 1) * (isMobile ? window.innerWidth : 505),
              behavior: screenReaderEnabled.current ? "auto" : "smooth",
            })

            if (screenReaderEnabled.current) {
              focusElement("onboarding-container")
            }

            setTimeout(() => {
              canClick.current = true
            }, 400)
          }
        }}
        onFocusVisible={() => {
          screenReaderEnabled.current = true
        }}
        onBlur={() => {
          screenReaderEnabled.current = false
        }}
      >
        {currentPage === 2 ? t("lets_start") : t("continue")}
      </Button>
    </Stack>
  )
}

const Content = ({
  missionCardImage,
  missionIcon,
  missionColor,
  title,
  description,
  hidden,
  missionType,
}: {
  missionCardImage: string
  missionIcon: string
  missionColor: string
  title: string
  description: string
  hidden: boolean
  missionType: MissionType
}) => {
  const { t } = useTranslation()
  const { isMobile } = useContext(MainContext)

  return (
    <Stack
      alignItems="center"
      style={{
        width: "100%",
        minWidth: "100%",
        height: "100%",
        paddingTop: 28,
        paddingInline: isMobile ? 38 : 60,
        scrollSnapAlign: "center",
      }}
      aria-hidden={hidden ? "true" : "false"}
    >
      <div
        style={{
          width: 255,
          height: 348,
          backgroundImage: `url(${missionCardImage})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          borderRadius: 12,
          position: "relative",
          display: "flex",
          justifyContent: "center",
        }}
        aria-label={t("mission_example", { type: title })}
        aria-live="polite"
      >
        <Title
          fontSize={15.4}
          lineHeight="19px"
          paddingLeft={21}
          paddingRight={21}
          color={
            missionType === MissionType.MEASURE ? colors.text : colors.textWhite
          }
          style={{ position: "absolute", left: 0, top: 61.5 }}
        >
          {missionType === MissionType.MEASURE
            ? t("calculate_your_footprint")
            : missionType === MissionType.LEARN
            ? t("watch_daily_episode")
            : t("log_1_action_today")}
        </Title>
        {missionType === MissionType.LEARN && (
          <Title
            fontSize={12.5}
            lineHeight="16px"
            color={colors.textWhite}
            textAlign="center"
            style={{ position: "absolute", width: 100, top: 219 }}
          >
            {t("reduce_use_of_plastic")}
          </Title>
        )}
        <Title
          fontSize={15}
          lineHeight="19px"
          color={
            missionType === MissionType.ACT ? colors.textWhite : colors.primary
          }
          style={{
            position: "absolute",
            top: 265,
            textDecoration: "underline",
          }}
          ariaHidden
        >
          {t("go")}
        </Title>
      </div>
      <Stack
        direction="row"
        justifyContent="center"
        style={{ width: "100%", height: 24, gap: 8, marginTop: 32 }}
      >
        <div
          style={{
            width: isMobile ? 24 : 26,
            height: isMobile ? 24 : 26,
            borderRadius: "100%",
            backgroundColor: missionColor,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={missionIcon}
            style={{ height: isMobile ? 10 : 12 }}
            alt=""
          />
        </div>
        <Title
          fontSize={isMobile ? 20 : 22}
          lineHeight={isMobile ? "24px" : "26px"}
        >
          {title}
        </Title>
      </Stack>
      <Text
        fontSize={isMobile ? 18 : 20}
        fontWeight={400}
        lineHeight={isMobile ? "25px" : "27px"}
        textAlign="center"
        style={{ marginTop: 16 }}
      >
        {description}
      </Text>
    </Stack>
  )
}

export default OnboardingPostSignInMobile
