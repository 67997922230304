import { Stack } from "@mui/material"
import BackButtonMobile from "../global/mobile/BackButtonMobile"
import { useNavigate } from "react-router-dom"
import { useContext } from "react"
import { JourneysContext } from "../../controllers/journeys"
import learnMissionIcon from "../../assets/icons/missions/missionsTypes/learn.svg"
import Title from "../global/common/Title"
import { colors } from "../../services/config/colors"
import JourneyCardMobile from "./mobile/JourneyCardMobile"
import FadeFromTop from "../animations/FadeFromTop"
import Mission from "../../models/mission"
import { useTranslation } from "react-i18next"

const JourneyMobile = ({ journeyMission }: { journeyMission?: Mission }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { journey } = useContext(JourneysContext)

  return (
    <Stack style={{ width: "100%", minHeight: "100%", position: "relative" }}>
      {/* back button */}
      <BackButtonMobile
        style={{ marginTop: 22, marginLeft: 16, zIndex: 1 }}
        onClick={() => {
          navigate("/")
        }}
      />
      {/* image header */}
      <img
        src={journey?.image}
        style={{
          width: "100%",
          height: 308,
          objectFit: "cover",
          objectPosition: "top",
          position: "absolute",
          top: 0,
        }}
        alt=""
      />
      {/* current journey mission */}
      {journeyMission && !journeyMission.satisfied ? (
        <FadeFromTop>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            style={{
              width: "calc(100% - 32px)",
              height: 32,
              marginLeft: 16,
              borderRadius: 4,
              backgroundColor: colors.missions.learn,
              position: "relative",
              marginTop: 10,
              gap: 8,
            }}
          >
            <img src={learnMissionIcon} style={{ width: 11 }} alt="" />
            <Title fontSize={14} color={colors.textWhite}>
              {t("learn").toUpperCase()}
            </Title>
          </Stack>
        </FadeFromTop>
      ) : null}
      {/* journey card */}
      <JourneyCardMobile journeyMission={journeyMission} />
    </Stack>
  )
}

export default JourneyMobile
