import { Stack } from "@mui/material"
import { useContext } from "react"
import { MainContext } from "../../controllers/main"
import OnboardingMobile from "./OnboardingMobile"

const OnboardingDesktop = () => {
  const { windowHeight } = useContext(MainContext)

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      style={{
        width: "100%",
        height: windowHeight,
        backgroundColor: "#252525",
      }}
    >
      <div
        style={{ width: 505, height: windowHeight - 72, position: "relative" }}
      >
        <OnboardingMobile
          height="100%"
          borderRadius={20}
          continueButtonBottom={58}
          continueButtonWidth={330}
        />
      </div>
    </Stack>
  )
}

export default OnboardingDesktop
