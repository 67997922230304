import { ButtonBase, Stack } from "@mui/material"
import BreadcrumbDesktop from "../global/desktop/BreadcrumbDesktop"
import { useNavigate } from "react-router-dom"
import { colors } from "../../services/config/colors"
import closeEpisodeIcon from "../../assets/icons/close-episode.svg"
import Slide from "../../models/slide"
import chevronLeftCircularIcon from "../../assets/icons/chevron-left-circular.svg"
import chevronRightCircularIcon from "../../assets/icons/chevron-right-circular.svg"
import { useContext } from "react"
import { JourneysContext } from "../../controllers/journeys"
import QuizResult from "../../models/quizResult"
import DailyEpisodeMobile from "./DailyEpisodeMobile"
import { useTranslation } from "react-i18next"
import { focusElement } from "../../services/utils/utils"

const DailyEpisodeDesktop = ({
  currentSlide,
  currentSlideIndex,
  currentSlideQuizResult,
  numberOfSlides,
  numberOfQuiz,
  totalSlides,
}: {
  currentSlide: Slide
  currentSlideIndex: number
  currentSlideQuizResult?: QuizResult
  numberOfSlides: number
  numberOfQuiz: number
  totalSlides: number
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { dailyEpisode } = useContext(JourneysContext)

  return (
    <Stack
      alignItems="center"
      style={{
        width: "100%",
        height: "100%",
        minHeight: "100%",
        maxHeight: "100%",
        position: "relative",
        zIndex: 100,
        backgroundColor: "#1A1A1A",
      }}
    >
      {/* header */}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        style={{
          width: "100%",
          height: 52,
          backgroundColor: "#000000",
          paddingInline: 30,
          position: "relative",
        }}
      >
        <BreadcrumbDesktop
          items={[
            {
              label: dailyEpisode!.title,
            },
          ]}
          selectedItem={0}
          withPadding={false}
          textColor={colors.textWhite}
        />
        <ButtonBase
          style={{ width: 38, height: 38, position: "absolute", right: 16 }}
          onClick={() => {
            navigate("/")
          }}
          aria-label={t("close")}
        >
          <img
            src={closeEpisodeIcon}
            style={{ width: 38, height: 38 }}
            alt=""
          />
        </ButtonBase>
      </Stack>
      {/* episode */}
      <Stack
        alignItems="center"
        direction="row"
        style={{ height: "calc(100% - 52px)", gap: 35 }}
      >
        <ButtonBase
          disabled={
            !currentSlideIndex ||
            !currentSlide ||
            (currentSlide &&
              currentSlide.slideType === "QuizSlide" &&
              !dailyEpisode!.result.quiz.some(
                (item) => item.slideId === currentSlide.id
              )) ||
            (currentSlide &&
              currentSlide.slideType === "QuizSlide" &&
              !dailyEpisode!.result.completed)
          }
          style={{
            width: 50,
            height: 50,
            borderRadius: 25,
            backgroundColor: colors.backgroundWhite,
          }}
          onClick={() => {
            focusElement("slide-container")

            const pathname = window.location.pathname

            // go to previous slide
            if (currentSlideIndex > 0) {
              navigate(
                pathname.slice(0, pathname.lastIndexOf("/")) +
                  "/" +
                  (currentSlideIndex - 1)
              )
            }
          }}
          aria-label={t("previous_slide")}
        >
          <img
            src={chevronLeftCircularIcon}
            style={{
              width: "100%",
              height: "100%",
              transition: "150ms",
              opacity:
                !currentSlideIndex ||
                !currentSlide ||
                (currentSlide &&
                  currentSlide.slideType === "QuizSlide" &&
                  !dailyEpisode!.result.quiz.some(
                    (item) => item.slideId === currentSlide.id
                  )) ||
                (currentSlide &&
                  currentSlide.slideType === "QuizSlide" &&
                  !dailyEpisode!.result.completed)
                  ? 0.3
                  : 1,
            }}
            alt=""
          />
        </ButtonBase>
        <div
          id="slide-container"
          style={{
            width: 375,
            height: "calc(100% - 60px)",
            maxHeight: 780,
          }}
          aria-label={
            currentSlideIndex === totalSlides
              ? t("end_of_episode_feedback")
              : t("slide_count_of_total", {
                  count: currentSlideIndex + 1,
                  total: totalSlides,
                })
          }
          role="region"
          aria-roledescription={t("content")}
          tabIndex={0}
        >
          <DailyEpisodeMobile
            currentSlide={currentSlide}
            currentSlideIndex={currentSlideIndex}
            numberOfSlides={numberOfSlides}
            numberOfQuiz={numberOfQuiz}
            totalSlides={totalSlides}
            currentSlideQuizResult={currentSlideQuizResult}
            style={{ maxHeight: 780 }}
          />
        </div>
        <ButtonBase
          disabled={
            (currentSlide &&
              currentSlide.slideType === "QuizSlide" &&
              !dailyEpisode!.result.quiz.some(
                (item) => item.slideId === currentSlide.id
              )) ||
            currentSlideIndex ===
              dailyEpisode!.slides.length + dailyEpisode!.quiz.length
          }
          style={{
            width: 50,
            height: 50,
            borderRadius: 25,
            backgroundColor: colors.backgroundWhite,
          }}
          onClick={() => {
            focusElement("slide-container")

            const pathname = window.location.pathname

            // go to next slide
            if (currentSlideIndex + 1 <= totalSlides) {
              navigate(
                pathname.slice(0, pathname.lastIndexOf("/")) +
                  "/" +
                  (currentSlideIndex + 1)
              )
            }
          }}
          aria-label={t("next_slide")}
        >
          <img
            src={chevronRightCircularIcon}
            style={{
              width: "100%",
              height: "100%",
              transition: "150ms",
              opacity:
                (currentSlide &&
                  currentSlide.slideType === "QuizSlide" &&
                  !dailyEpisode!.result.quiz.some(
                    (item) => item.slideId === currentSlide.id
                  )) ||
                currentSlideIndex ===
                  dailyEpisode!.slides.length + dailyEpisode!.quiz.length
                  ? 0.3
                  : 1,
            }}
            alt=""
          />
        </ButtonBase>
      </Stack>
    </Stack>
  )
}

export default DailyEpisodeDesktop
