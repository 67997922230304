import {
  CSSProperties,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react"
import CalendarRowDayMobile from "./CalendarRowDayMobile"
import { ActionsContext } from "../../../controllers/actions"
import { calculateWeek } from "../../../services/utils/utils"
import days from "../../../services/config/days"
import { useTranslation } from "react-i18next"

const CalendarRowMobile = ({
  style,
  loading,
  setLoading,
}: {
  style?: CSSProperties
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
}) => {
  const { t } = useTranslation()
  const { currentDate } = useContext(ActionsContext)

  // current week
  const [currentWeek, setCurrentWeek] = useState<Date[]>(
    calculateWeek(new Date())
  )

  useEffect(() => {
    setCurrentWeek(calculateWeek(currentDate))
  }, [currentDate])

  return (
    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <div
        style={{
          width: "100%",
          maxWidth: 600,
          minHeight: 80,
          paddingInline: 16,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          ...style,
        }}
        role="region"
        aria-label={t("current_week")}
      >
        {currentWeek.map((day, index) => (
          <CalendarRowDayMobile
            key={index}
            day={day}
            dayLabel={t(days[index]).slice(0, 3)}
            loading={loading}
            setLoading={setLoading}
          />
        ))}
      </div>
    </div>
  )
}

export default CalendarRowMobile
