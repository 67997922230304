import { ButtonBase, Grid, Stack } from "@mui/material"
import backgroundImageTop from "../../assets/images/landing-background-desktop-top.webp"
import backgroundImageBottom from "../../assets/images/landing-background-desktop-bottom.webp"
import awLogo from "../../assets/images/aw-logo.svg"
import partnerLogo from "../../assets/images/partner-logo.png"
import partnerLogoAlt from "../../assets/images/partner-logo-alt.png"
import Title from "../global/common/Title"
import Text from "../global/common/Text"
import AppearFromSide from "../animations/AppearFromSide"
import ChallengeCardDesktop from "../challenge/desktop/ChallengeCardDesktop"
import { colors } from "../../services/config/colors"
import MissionCardDesktop from "./desktop/MissionCardDesktop"
import Button from "../global/common/Button"
import propicsRowImage from "../../assets/images/propics-row.png"
import FadeFromTop from "../animations/FadeFromTop"
import {
  desktopColumnsGap,
  desktopMaxWidth,
} from "../../services/config/constants"
import PaddingContainerDesktop from "../global/desktop/PaddingContainerDesktop"
import { useContext, useState } from "react"
import { MissionsContext } from "../../controllers/missions"
import { Trans, useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { scrollWindow, scrollWindowToTop } from "../../services/utils/utils"
import MissionsTabs from "../missions/common/MissionsTabs"
import { MissionPeriod } from "../../services/config/enum"
import DailyMissionsCardDesktop from "./desktop/DailyMissionsCardDesktop"
import AlertInfo from "../global/common/AlertInfo"
import whatIsAMissionImage from "../../assets/images/what-is-a-mission.svg"
import MissionInfoCardDesktop from "./desktop/MissionInfoCardDesktop"
import { ChallengeContext } from "../../controllers/challenge"
import { MainContext } from "../../controllers/main"
import spaceshipImage from "../../assets/images/spaceship.svg"
import infoPrimaryIcon from "../../assets/icons/info-primary.svg"
import mapImage from "../../assets/images/map.svg"

const LandingDesktop = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { country } = useContext(MainContext)
  const { missions, selectedTab, setSelectedTab, completedMissionsCount } =
    useContext(MissionsContext)
  const { challenge } = useContext(ChallengeContext)

  // what is a mission alert
  const [missionInfoAlertOpen, setMissionInfoAlertOpen] =
    useState<boolean>(false)

  // come back tomorrow alert
  const [comeBackAlertOpen, setComeBackAlertOpen] = useState<boolean>(false)

  return (
    <Stack
      alignItems="center"
      style={{
        width: "100%",
      }}
    >
      {/* header */}
      <div
        style={{
          width: "100%",
          height: 746,
          minHeight: 746,
          paddingTop: 191.5,
          position: "relative",
        }}
      >
        <img
          src={backgroundImageTop}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            objectPosition: "bottom",
            position: "absolute",
            top: 0,
          }}
          alt=""
        />
        <PaddingContainerDesktop style={{ position: "relative", zIndex: 1 }}>
          <Stack
            direction="row"
            style={{
              width: "100%",
              maxWidth: desktopMaxWidth,
              gap: desktopColumnsGap,
            }}
          >
            {/* first header half */}
            <Stack style={{ width: "50%" }}>
              <AppearFromSide fade>
                <Stack
                  direction="row"
                  alignItems="flex-end"
                  style={{ height: 53, gap: 68 }}
                >
                  <img
                    src={awLogo}
                    style={{ width: 275 }}
                    alt={t("aworld_logo_alt")}
                  />
                  <img
                    src={country === "US" ? partnerLogo : partnerLogoAlt}
                    style={{
                      width: 148,
                      marginBottom: country === "US" ? 0 : -20,
                    }}
                    alt={t("partner_logo_alt")}
                  />
                </Stack>
              </AppearFromSide>
              <AppearFromSide delay={0.05} fade>
                <Title
                  fontSize={40}
                  lineHeight="46px"
                  style={{ letterSpacing: "-0.4px", marginTop: 40 }}
                  component="h1"
                >
                  {t("join_partner_challenge")}
                </Title>
              </AppearFromSide>
              <AppearFromSide delay={0.1} fade>
                <Text
                  fontSize={26}
                  lineHeight="40px"
                  fontWeight={400}
                  style={{ marginTop: 40 }}
                >
                  <Trans i18nKey="complete_missions" />
                </Text>
              </AppearFromSide>
              <AppearFromSide fade style={{ marginTop: 34 }} delay={0.15}>
                <Button
                  width={330}
                  onClick={() => {
                    if (!missions.length) {
                      scrollWindowToTop()
                      navigate("/performance")
                    } else {
                      scrollWindow(780, "smooth")
                    }
                  }}
                  title={
                    missions.length
                      ? t("go_to_your_missions")
                      : t("go_to_your_points")
                  }
                >
                  {missions.length
                    ? t("go_to_your_missions")
                    : t("go_to_your_points")}
                </Button>
              </AppearFromSide>
            </Stack>
            {/* second header half */}
            <Stack justifyContent="center" style={{ width: "50%" }}>
              <AppearFromSide
                x={100}
                delay={0.05}
                fade
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <ChallengeCardDesktop style={{ marginTop: -90 }} />
              </AppearFromSide>
            </Stack>
          </Stack>
        </PaddingContainerDesktop>
      </div>
      {/* missions */}
      <FadeFromTop style={{ marginTop: 85 }}>
        <Title fontSize={40} lineHeight="46px" component="h2">
          {t("your_missions").toUpperCase()}
        </Title>
      </FadeFromTop>
      {/* tabs */}
      {missions.length ? (
        <FadeFromTop style={{ width: "100%", marginTop: 60 }} delay={0.05}>
          <PaddingContainerDesktop>
            <MissionsTabs
              tabs={[t("all"), t("today"), t("week"), t("special")]}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              style={{
                width: "100%",
                maxWidth: 1050,
              }}
              infoButtonOnClick={() => {
                setMissionInfoAlertOpen(true)
              }}
            />
          </PaddingContainerDesktop>
        </FadeFromTop>
      ) : (
        <FadeFromTop style={{ width: "100%", marginTop: 36 }} delay={0.05}>
          <PaddingContainerDesktop>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              style={{
                width: "100%",
                maxWidth: 1050,
                height: 60,
                position: "relative",
              }}
            >
              <Text fontSize={26} fontWeight={400}>
                {t("all_missions_completed")}
              </Text>
              <ButtonBase
                aria-label={t("what_is_a_mission")}
                style={{
                  width: 60,
                  height: 60,
                  position: "absolute",
                  right: 0,
                  backgroundColor: colors.background,
                  borderRadius: 15,
                }}
                onClick={() => {
                  setMissionInfoAlertOpen(true)
                }}
              >
                <img
                  src={infoPrimaryIcon}
                  style={{ width: 25, height: 25 }}
                  alt=""
                  aria-hidden="true"
                />
              </ButtonBase>
            </Stack>
          </PaddingContainerDesktop>
        </FadeFromTop>
      )}
      {/* missions grid */}
      {missions.length ? (
        <FadeFromTop style={{ marginTop: 60, width: "100%" }} delay={0.1}>
          <PaddingContainerDesktop>
            {/* missions grid */}
            <Grid
              id="missions-desktop-container"
              aria-label={t("available_missions")}
              container
              columns={3}
              columnSpacing={`${desktopColumnsGap}px`}
              rowSpacing="74px"
              style={{
                width: `calc(100% + ${desktopColumnsGap}px)`,
                maxWidth: 1080,
              }}
              role="tabpanel"
            >
              {missions
                .filter((mission) => {
                  switch (selectedTab) {
                    case 0:
                      return mission
                    case 1:
                      if (mission.period === MissionPeriod.DAILY) {
                        return mission
                      } else {
                        return null
                      }
                    case 2:
                      if (mission.period === MissionPeriod.WEEKLY) {
                        return mission
                      } else {
                        return null
                      }
                    case 3:
                      if (mission.period === MissionPeriod.CUSTOM) {
                        return mission
                      } else {
                        return null
                      }
                  }

                  return null
                })
                .map((mission, index) => (
                  <Grid key={mission.missionId + selectedTab} item xs={1}>
                    <MissionCardDesktop
                      index={index}
                      color={mission.color}
                      title={mission.title}
                      titleColor={mission.titleColor}
                      goButtonColor={mission.goButtonColor}
                      image={mission.image}
                      current={mission.current}
                      target={mission.target}
                      points={mission.points}
                      satisfied={mission.satisfied}
                      missionType={mission.missionType}
                      missionSubType={mission.missionSubType}
                      missionTypeId={mission.missionTypeId}
                      period={mission.period}
                      onClick={() => {
                        scrollWindowToTop()
                        navigate(mission.navigationPath)
                      }}
                      selectedTab={selectedTab}
                      end={mission.end}
                      currentPerDay={mission.currentPerDay}
                      targetPerDay={mission.targetPerDay}
                      setComeBackAlertOpen={setComeBackAlertOpen}
                      updatedAt={mission.updatedAt}
                    />
                  </Grid>
                ))}
              {selectedTab === 1 ? (
                <Grid item xs={1}>
                  {missions.filter(
                    (mission) =>
                      mission.period === MissionPeriod.DAILY &&
                      mission.satisfied
                  ).length === 2 ? (
                    <MissionInfoCardDesktop
                      missionsCompleted={2}
                      missionsPeriod={MissionPeriod.DAILY}
                    />
                  ) : (
                    <DailyMissionsCardDesktop />
                  )}
                </Grid>
              ) : selectedTab === 2 ? (
                <Grid item xs={1}>
                  <MissionInfoCardDesktop
                    key={selectedTab}
                    missionsCompleted={
                      missions.filter(
                        (mission) =>
                          mission.period === MissionPeriod.WEEKLY &&
                          mission.satisfied
                      ).length
                    }
                    missionsPeriod={MissionPeriod.WEEKLY}
                  />
                </Grid>
              ) : selectedTab === 3 ? (
                <Grid item xs={1}>
                  <MissionInfoCardDesktop
                    key={selectedTab}
                    missionsCompleted={
                      missions.filter(
                        (mission) =>
                          mission.period === MissionPeriod.CUSTOM &&
                          mission.satisfied
                      ).length
                    }
                    missionsPeriod={MissionPeriod.CUSTOM}
                  />
                </Grid>
              ) : null}
            </Grid>
          </PaddingContainerDesktop>
        </FadeFromTop>
      ) : (
        <FadeFromTop delay={0.1} style={{ marginTop: 80 }}>
          <PaddingContainerDesktop>
            <img src={spaceshipImage} style={{ width: 330 }} alt="" />
          </PaddingContainerDesktop>
        </FadeFromTop>
      )}
      {/* bottom part */}
      <Stack
        alignItems="center"
        style={{
          width: "100%",
          height: 532,
          backgroundImage: `url(${backgroundImageBottom})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          position: "relative",
          marginTop: 40,
          paddingTop: 26,
        }}
      >
        {missions.length ? (
          <FadeFromTop delay={0.15}>
            <Title fontSize={26} lineHeight="30px" color={colors.secondary}>
              {missions.filter((item) => item.satisfied).length}{" "}
              {missions.filter((item) => item.satisfied).length === 1
                ? t("completed_singular")
                : t("completed_plural")}
            </Title>
          </FadeFromTop>
        ) : null}
        {/* <FadeFromTop style={{ marginTop: 34 }} delay={0.15}>
          <Button
            width={330}
            onClick={() => {
              if (!missions.length) {
                scrollWindowToTop()
                navigate("/performance")
              } else if (
                missions.some(
                  (mission) =>
                    !mission.satisfied &&
                    ((!mission.currentPerDay && !mission.targetPerDay) ||
                      (mission.currentPerDay &&
                        mission.targetPerDay &&
                        mission.currentPerDay !== mission.targetPerDay))
                )
              ) {
                scrollWindowToTop()
                navigate(
                  missions.find(
                    (mission) =>
                      !mission.satisfied &&
                      ((!mission.currentPerDay && !mission.targetPerDay) ||
                        (mission.currentPerDay &&
                          mission.targetPerDay &&
                          mission.currentPerDay !== mission.targetPerDay))
                  )!.navigationPath
                )
              } else {
                scrollWindowToTop()
                navigate("/actions")
              }
            }}
            title={missions.length ? t("act_now") : t("go_to_your_points")}
          >
            {missions.length ? t("act_now") : t("go_to_your_points")}
          </Button>
        </FadeFromTop> */}
        <FadeFromTop delay={0.2} style={{ marginTop: 270 }}>
          <Stack alignItems="center">
            <img src={propicsRowImage} style={{ height: 55 }} alt="" />
            <Title fontSize={20} lineHeight="24px" style={{ marginTop: 16 }}>
              {t("challenge_contribution_percentage", {
                percentage: (challenge!.currentAmount
                  ? Math.round(
                      ((completedMissionsCount / challenge!.currentAmount) *
                        100 +
                        Number.EPSILON) *
                        100
                    ) / 100
                  : 0
                )
                  .toString()
                  .replace(".", ","),
              })}
            </Title>
            <ButtonBase
              style={{ marginTop: 18 }}
              onClick={() => {
                scrollWindowToTop()
                navigate("/performance/leaderboard")
              }}
              aria-label={t("view_leaderboard")}
            >
              <Title
                fontSize={18}
                color={colors.primary}
                style={{
                  textDecorationLine: "underline",
                }}
              >
                {t("view_leaderboard")}
              </Title>
            </ButtonBase>
          </Stack>
        </FadeFromTop>
      </Stack>
      {/* what is a mission info alert */}
      <AlertInfo
        open={missionInfoAlertOpen}
        setOpen={setMissionInfoAlertOpen}
        title={t("what_is_a_mission")}
        description={t("what_is_a_mission_description")}
        descriptionAlign="center"
        image={whatIsAMissionImage}
      />
      {/* come back tomorrow info alert */}
      <AlertInfo
        open={comeBackAlertOpen}
        setOpen={setComeBackAlertOpen}
        title={t("thats_all_for_today")}
        description={t("come_back_tomorrow_to_continue")}
        descriptionAlign="center"
        image={mapImage}
      />
    </Stack>
  )
}

export default LandingDesktop
