import { useContext, useEffect, useMemo } from "react"
import { MainContext } from "../../controllers/main"
import FootprintMobile from "../../components/footprint/FootprintMobile"
import FootprintDesktop from "../../components/footprint/FootprintDesktop"
import { MissionsContext } from "../../controllers/missions"
import { MissionType } from "../../services/config/enum"
import { FootprintContext } from "../../controllers/footprint"
import { registerPageEvent } from "../../services/utils/utils"

const Footprint = () => {
  const { isMobile } = useContext(MainContext)
  const { missions, updateAll } = useContext(MissionsContext)
  const {
    gettingFootprint,
    setGettingFootprint,
    getUserFootprint,
    getFormsList,
    getUserFormsList,
  } = useContext(FootprintContext)

  // register google analytics event
  useEffect(() => {
    registerPageEvent("footprint")
  }, [])

  // footprint missions
  const footprintMissions = useMemo(() => {
    return missions.filter(
      (mission) => mission.missionType === MissionType.MEASURE
    )
  }, [missions])

  // if user is coming from footprintForm page and has completed the form, get footprint and forms list
  useEffect(() => {
    if (gettingFootprint) {
      setTimeout(async () => {
        await Promise.all([
          getUserFootprint(false),
          getFormsList(false),
          getUserFormsList(false),
        ])
        setGettingFootprint(false)
        updateAll()
      }, 2000)
    }
  }, [])

  return isMobile ? (
    <FootprintMobile footprintMissions={footprintMissions} />
  ) : (
    <FootprintDesktop footprintMissions={footprintMissions} />
  )
}

export default Footprint
