import ReactGA from "react-ga4"
import Episode from "../../models/episode"

// register google analytics pageview event
export const registerPageEvent = (pageName: string) => {
  ReactGA.send({ hitType: "pageview", page: pageName })
}

// register google analytics event
export const registerEvent = (name: string, params?: object) => {
  if (params) {
    ReactGA.event(name, params)
  } else {
    ReactGA.event(name)
  }
}

// calculate entire week
export const calculateWeek = (currentDate: Date) => {
  var curr = new Date(currentDate)

  var currDay = curr.getDay() === 0 ? 7 : curr.getDay()
  var first = curr.getDate() - currDay + 1

  var firstWeekDay = new Date(curr.setDate(first))
  let currentWeek = [firstWeekDay]

  for (let i = 1; i < 7; i++) {
    let datetToPush = new Date(firstWeekDay)
    datetToPush.setDate(datetToPush.getDate() + i)
    currentWeek.push(datetToPush)
  }

  return currentWeek
}

// scroll main container to a given top position
export const scrollWindow = (
  top: number,
  behavior: ScrollBehavior = "auto"
) => {
  document
    .getElementById("main-container")!
    .scrollTo({ top: top, behavior: behavior })
}

// scroll main container to top
export const scrollWindowToTop = () => {
  document
    .getElementById("main-container")!
    .scrollTo({ top: 0, behavior: "auto" })
}

// get all days in a specified month
export const getDaysInMonth = (month: number, year: number) => {
  var date = new Date(year, month, 1)

  var days = []
  while (date.getMonth() === month) {
    days.push(new Date(date))
    date.setDate(date.getDate() + 1)
  }

  if (days[0].getDay() === 0) {
    days.unshift(...Array(6).fill(""))
  } else if (days[0].getDay() !== 1) {
    days.unshift(...Array(days[0].getDay() - 1).fill(""))
  }

  return days
}

// cache an array of images
export const cacheImages = async (srcArray: string[]) => {
  const promises = srcArray.map((src) => {
    return new Promise<void>((resolve, reject) => {
      const img = new Image()

      img.src = src
      img.onload = () => resolve()
      img.onerror = () => reject()
    })
  })

  return await Promise.all(promises)
}

// cache all images of an episode
export const cacheEpisodeImages = async (episode: Episode) => {
  var images: string[] = []
  episode.slides.forEach((slide) => {
    let currentSlide = slide as any
    if (currentSlide.background) {
      images.push(currentSlide.background)
    }
    if (currentSlide.decor) {
      images.push(currentSlide.decor)
    }
    if (currentSlide.image && currentSlide.image.url) {
      images.push(currentSlide.image.url)
    }
  })
  if (episode.quiz.length) {
    episode.quiz.forEach((slide) => {
      let currentSlide = slide as any
      if (currentSlide.background) {
        images.push(currentSlide.background)
      }
      if (currentSlide.decor) {
        images.push(currentSlide.decor)
      }
      if (currentSlide.opt1 && currentSlide.opt1.image) {
        images.push(currentSlide.opt1.image)
      }
      if (currentSlide.opt2 && currentSlide.opt2.image) {
        images.push(currentSlide.opt2.image)
      }
      if (currentSlide.opt3 && currentSlide.opt3.image) {
        images.push(currentSlide.opt3.image)
      }
      if (currentSlide.opt4 && currentSlide.opt4.image) {
        images.push(currentSlide.opt4.image)
      }
    })
  }

  const promises = images.map((src) => {
    return new Promise<void>((resolve, reject) => {
      const img = new Image()

      img.src = src
      img.onload = () => resolve()
      img.onerror = () => reject()
    })
  })

  return await Promise.all(promises)
}

// return a given number as string with separators
export const numberWithSeparators = (
  n: number,
  separator: "." | "," = "."
): string => {
  return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator)
}

// enum as array https://stackoverflow.com/questions/41308123/map-typescript-enum
export const enumAsArray = (enumToMap: any) => {
  return Object.keys(enumToMap) as Array<keyof typeof enumToMap>
}

// calculate the amount of hours until midnight of the current day
export const hoursUntilMidnight = () => {
  const now = new Date()
  const midnight = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    23,
    59,
    59
  )

  const ms = midnight.getTime() - now.getTime()
  const hours = Math.round(ms / 3600000)

  return hours
}

// calculate the amount of days between two given dates
export const daysBetweenDates = (
  firstDate: Date,
  secondDate: Date,
  includeLastDay: boolean
) => {
  if (includeLastDay) {
    secondDate.setDate(secondDate.getDate() + 1)
    return Math.round(
      (secondDate.getTime() - firstDate.getTime()) / (1000 * 60 * 60 * 24)
    )
  } else {
    return Math.round(
      (secondDate.getTime() - firstDate.getTime()) / (1000 * 60 * 60 * 24)
    )
  }
}

// focus dom element
export const focusElement = (elementId: string) => {
  const element = document.getElementById(elementId)!
  element.focus()
}

// round number to 1 decimal places if necessary
export const roundWithDecimalPlaces = (num: number, decimalPlaces: number) => {
  const value = Math.pow(10, decimalPlaces)

  return Math.round((num + Number.EPSILON) * value) / value
}
