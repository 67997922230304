import { Grid, Stack } from "@mui/material"
import { colors } from "../../services/config/colors"
import BreadcrumbDesktop from "../global/desktop/BreadcrumbDesktop"
import {
  desktopColumnsGap,
  desktopMaxWidth,
  footerHeight,
} from "../../services/config/constants"
import FadeFromTop from "../animations/FadeFromTop"
import learnMissionIcon from "../../assets/icons/missions/missionsTypes/learn.svg"
import Title from "../global/common/Title"
import { useContext, useState } from "react"
import { JourneysContext } from "../../controllers/journeys"
import Text from "../global/common/Text"
import EpisodeCardDesktop from "./desktop/EpisodeCardDesktop"
import PointsBadge from "../global/common/PointsBadge"
import PaddingContainerDesktop from "../global/desktop/PaddingContainerDesktop"
import { useTranslation } from "react-i18next"
import Mission from "../../models/mission"

const JourneyDesktop = ({ journeyMission }: { journeyMission?: Mission }) => {
  const { t } = useTranslation()
  const { journey } = useContext(JourneysContext)

  // loading for episode images caching
  const [loading, setLoading] = useState<boolean>(false)

  return (
    <Stack
      style={{
        width: "100%",
        minHeight: `calc(100% - ${footerHeight}px)`,
        position: "relative",
      }}
    >
      {/* navbar background */}
      <div
        style={{
          width: "100%",
          height: 177,
          backgroundColor: colors.background,
        }}
      />
      {/* journey image */}
      <img
        src={journey?.image}
        style={{
          width: "100%",
          height: 337,
          objectFit: "cover",
          position: "absolute",
          top: 177,
        }}
        alt=""
      />
      {/* breadcrumb */}
      <BreadcrumbDesktop
        items={[{ label: t("journey") }]}
        selectedItem={0}
        style={{ marginTop: 22, position: "relative" }}
        withBackground
      />
      {/* main container */}
      <PaddingContainerDesktop>
        <Stack
          style={{
            width: "100%",
            maxWidth: desktopMaxWidth,
            marginTop: 93,
            position: "relative",
          }}
        >
          {/* current journey mission */}
          {journeyMission && !journeyMission.satisfied ? (
            <FadeFromTop>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                style={{
                  width: "100%",
                  height: 40,
                  borderRadius: 4,
                  backgroundColor: colors.missions.learn,
                  position: "relative",
                  gap: 10,
                }}
              >
                <img src={learnMissionIcon} style={{ height: 18 }} alt="" />
                <Title fontSize={18} color={colors.textWhite}>
                  {t("learn").toUpperCase()}
                </Title>
              </Stack>
            </FadeFromTop>
          ) : null}
          {/* journey heading */}
          <FadeFromTop delay={0.05}>
            <Stack
              style={{
                width: "100%",
                height: "auto",
                paddingTop: 38,
                paddingBottom: 38,
                paddingInline: 50,
                backgroundColor: colors.backgroundWhite,
                borderRadius: 16,
                boxShadow: "0px 0px 24px 0px rgba(147, 160, 167, 0.16)",
                marginTop: 18,
                position: "relative",
              }}
            >
              <PointsBadge
                points={journey!.episodes.length}
                style={{ position: "absolute", top: 38, right: 50 }}
              />
              <Title
                fontSize={36}
                lineHeight="46px"
                style={{
                  maxWidth: "calc(100% - 100px)",
                  height: 92,
                  maxHeight: 92,
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
                component="h1"
              >
                {journey?.title}
              </Title>
              <Stack
                direction="row"
                alignItems="center"
                style={{ width: "auto", height: 40, marginTop: 24, gap: 20 }}
              >
                <div
                  style={{
                    height: "100%",
                    paddingInline: 8,
                    borderRadius: 5,
                    backgroundColor: journey?.category.backTagColor,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Text
                    fontSize={16}
                    fontWeight={700}
                    color={journey?.category.foreColor}
                  >
                    {journey?.category.name}
                  </Text>
                </div>
                {journey?.sdg ? (
                  <div
                    style={{
                      height: "100%",
                      paddingInline: 8,
                      borderRadius: 5,
                      backgroundColor: colors.backgroundWhite,
                      display: "flex",
                      alignItems: "center",
                      border: "1px solid " + colors.primary,
                    }}
                  >
                    <Text fontSize={16} fontWeight={700} color={colors.primary}>
                      {t("sdg")} {journey.sdg}
                    </Text>
                  </div>
                ) : null}
              </Stack>
            </Stack>
          </FadeFromTop>
          {/* episodes */}
          <Grid
            container
            columnSpacing={`${desktopColumnsGap}px`}
            rowSpacing={`${desktopColumnsGap}px`}
            style={{
              width: `calc(100% + ${desktopColumnsGap}px)`,
              maxWidth: `calc(${desktopMaxWidth}px + ${desktopColumnsGap}px)`,
              marginTop: 28,
              marginBottom: 58,
            }}
          >
            {journey?.episodes.map((episode, index) => (
              <Grid key={index} item xs={4}>
                <FadeFromTop
                  delay={0.05 * (index + 2)}
                  style={{ width: "100%" }}
                >
                  <EpisodeCardDesktop
                    episode={episode}
                    episodeNumber={index + 1}
                    loading={loading}
                    setLoading={setLoading}
                  />
                </FadeFromTop>
              </Grid>
            ))}
          </Grid>
        </Stack>
      </PaddingContainerDesktop>
    </Stack>
  )
}

export default JourneyDesktop
