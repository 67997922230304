import { Stack } from "@mui/material"
import FootprintForm from "../../models/footprintForm"
import { desktopMaxWidth, footerHeight } from "../../services/config/constants"
import PaddingContainerDesktop from "../global/desktop/PaddingContainerDesktop"
import AppearFromSide from "../animations/AppearFromSide"
import BreadcrumbDesktop from "../global/desktop/BreadcrumbDesktop"
import { colors } from "../../services/config/colors"
import Title from "../global/common/Title"
import { useTranslation } from "react-i18next"
import FadeFromTop from "../animations/FadeFromTop"
import Mission from "../../models/mission"
import measureMissionIcon from "../../assets/icons/missions/missionsTypes/measure-white.svg"
import { useNavigate } from "react-router-dom"
import { Widget } from "@typeform/embed-react"
import { useContext } from "react"
import { MainContext } from "../../controllers/main"

const FootprintFormDesktop = ({
  currentForm,
  footprintMission,
  onTypeformSubmit,
}: {
  currentForm: FootprintForm
  footprintMission?: Mission
  onTypeformSubmit: () => void
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { user } = useContext(MainContext)

  return (
    <Stack
      style={{ width: "100%", minHeight: `calc(100% - ${footerHeight}px)` }}
    >
      {/* navbar background */}
      <div
        style={{
          width: "100%",
          height: 177,
          backgroundColor: colors.background,
        }}
      />
      {/* breadcrumb */}
      <BreadcrumbDesktop
        items={[
          {
            label: t("carbon_footprint"),
            onClick: () => {
              navigate("/footprint")
            },
          },
          { label: t("survey") },
        ]}
        selectedItem={1}
        style={{ marginTop: 22 }}
      />
      {/* main container */}
      <PaddingContainerDesktop>
        <Stack style={{ width: "100%", maxWidth: desktopMaxWidth }}>
          {/* header */}
          <AppearFromSide fade>
            <Title
              fontSize={36}
              lineHeight="46px"
              style={{ marginTop: 22 }}
              component="h1"
            >
              {t("survey")}
            </Title>
          </AppearFromSide>
          {/* current footprint mission */}
          {footprintMission && !footprintMission.satisfied ? (
            <FadeFromTop style={{ marginTop: 42 }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                style={{
                  width: "100%",
                  height: 40,
                  borderRadius: 4,
                  backgroundColor: colors.missions.measure,
                  position: "relative",
                  gap: 10,
                }}
              >
                <img src={measureMissionIcon} style={{ width: 16 }} alt="" />
                <Title fontSize={18} color={colors.textWhite}>
                  {t("measure").toUpperCase()}
                </Title>
              </Stack>
            </FadeFromTop>
          ) : null}
          {/* typeform */}
          <div
            style={{
              width: "100%",
              height: 546,
              marginBottom: 58,
              position: "relative",
              marginTop:
                footprintMission && !footprintMission.satisfied ? 0 : 42,
            }}
          >
            <Widget
              id={currentForm.formId}
              style={{
                width: "100%",
                height: "100%",
              }}
              onSubmit={onTypeformSubmit}
              hidden={{
                sub: user!.sub,
              }}
              inlineOnMobile
            />
            <div
              style={{
                width: "100%",
                height: 5,
                backgroundColor: colors.backgroundWhite,
                position: "absolute",
                top: 0,
              }}
            />
          </div>
        </Stack>
      </PaddingContainerDesktop>
    </Stack>
  )
}

export default FootprintFormDesktop
