import { CSSProperties, ReactNode } from "react"
import { motion, useReducedMotion } from "framer-motion"

const Appear = ({
  children,
  style,
  fade = false,
  scale = true,
  delay = 0,
  fadeDuration = 0.5,
}: {
  children?: ReactNode
  style?: CSSProperties
  fade?: boolean
  scale?: boolean
  delay?: number
  fadeDuration?: number
}) => {
  const shouldReduceMotion = useReducedMotion()

  return (
    <motion.div
      style={style}
      initial={{ opacity: fade ? 0 : 1, scale: scale ? 0.5 : 1 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        delay: shouldReduceMotion ? 0 : delay,
        scale: {
          type: "spring",
          duration: 0.6,
        },
        opacity: {
          duration: fadeDuration,
        },
      }}
    >
      {children}
    </motion.div>
  )
}

export default Appear
