import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react"
import {
  availableLanguages,
  mobileBreakpoint,
  shouldSkipAuth,
} from "../services/config/constants"
import i18next from "i18next"
import { getCurrentUser } from "aws-amplify/auth"
import { signInWithRedirect, signOut } from "aws-amplify/auth"
import { get, post, put } from "../services/api/api"
import jwt_decode from "jwt-decode"
import { parseUserData } from "../services/utils/parseFunctions"
import User from "../models/user"
import { useSearchParams } from "react-router-dom"

interface MainContextInterface {
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  signInError: boolean
  viewOnboarding: boolean
  viewSecondOnboarding: boolean
  setViewSecondOnboarding: Dispatch<SetStateAction<boolean>>
  setSignInError: Dispatch<SetStateAction<boolean>>
  setViewOnboarding: Dispatch<SetStateAction<boolean>>
  isMobile: boolean
  windowWidth: number
  windowHeight: number
  lang: string
  setLang: Dispatch<SetStateAction<string>>
  country: string | null
  federatedSignIn: (provider: string) => void
  user: User | null
  setUser: Dispatch<SetStateAction<User | null>>
  userError: boolean
  getUserInfo: () => Promise<boolean>
  setUserFirstAccess: () => Promise<boolean>
  userSignOut: () => Promise<boolean>
}

const MainContext = createContext<MainContextInterface>({
  loading: true,
  setLoading: () => {},
  signInError: false,
  viewOnboarding: true,
  viewSecondOnboarding: true,
  setViewSecondOnboarding: () => {},
  setSignInError: () => {},
  setViewOnboarding: () => {},
  isMobile: false,
  windowWidth: window.innerWidth,
  windowHeight: window.innerHeight,
  lang: "en",
  setLang: () => {},
  country: null,
  federatedSignIn: () => {},
  user: null,
  setUser: () => {},
  userError: false,
  getUserInfo: async () => true,
  setUserFirstAccess: async () => true,
  userSignOut: async () => true,
})

const MainController = ({ children }: { children: ReactNode }) => {
  const [searchParams] = useSearchParams()

  // loadings
  const [loading, setLoading] = useState<boolean>(true) // main loading

  // states
  const [signInError, setSignInError] = useState<boolean>(false) // signin error
  const [viewOnboarding, setViewOnboarding] = useState<boolean>(true) // view or not sign up onboarding
  const [viewSecondOnboarding, setViewSecondOnboarding] =
    useState<boolean>(true) // view or not post sign up onboarding
  const [isMobile, setIsMobile] = useState<boolean>(false) // if screen is mobile size or not
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth) // window current width
  const [windowHeight, setWindowHeight] = useState<number>(window.innerHeight) // window current height
  const [lang, setLang] = useState<string>("en") // app language
  const [country, setCountry] = useState<string | null>(null) // app country
  const [user, setUser] = useState<User | null>(null) // current user
  const [userError, setUserError] = useState<boolean>(false) // current user error

  // get user
  const getUser = async () => {
    const { data } = await get("/web/user/get")

    return data
  }

  // get user wallet
  const getUserWallet = async () => {
    const { data } = await get("/web/mission/point/user")

    return data
  }

  // get all user info
  const getUserInfo = async () => {
    setUserError(false)

    try {
      const result = await Promise.all([getUser(), getUserWallet()])

      const userData = result[0]
      const userWallet = result[1]

      // parse data
      parseUserData(userData)
      if (userWallet && userWallet.points) {
        userData.points = userWallet.points
      } else {
        userData.points = 0
      }

      console.log("user", userData)
      setUser(userData)

      // set app language based on user language
      if (userData.lang) {
        i18next.changeLanguage(userData.lang)
        if (availableLanguages.find((item) => item.code === userData.lang)) {
          setLang(userData.lang)
          document.documentElement.lang = userData.lang
        } else {
          setLang("en")
          document.documentElement.lang = "en"
        }
      }

      // don't show second onboarding if it isn't user first access
      if (!userData.firstAccess) {
        setViewSecondOnboarding(false)
      }

      return true
    } catch (e) {
      console.log("user error", e)
      setUserError(true)

      return false
    }
  }

  // set user first access to false
  const setUserFirstAccess = async () => {
    try {
      await put("/web/user/firstaccess", {
        externalid: (
          jwt_decode(
            localStorage.getItem(
              Object.keys(localStorage).find((key) => key.includes("idToken"))!
            )!
          ) as any
        )["custom:externalid"],
      })
      console.log("user firstAccess set to false")

      // set first access to false locally
      user!.firstAccess = false
      setUser({ ...user! })

      return true
    } catch (e) {
      console.log("firstaccess error", e)
      setUserError(true)

      return false
    }
  }

  // check if screen is mobile or not
  useEffect(() => {
    // first check
    if (window.innerWidth >= mobileBreakpoint) {
      setIsMobile(false)
    } else {
      setIsMobile(true)
    }

    // event listener on resize
    window.addEventListener("resize", () => {
      if (window.innerWidth >= mobileBreakpoint) {
        setIsMobile(false)
      } else {
        setIsMobile(true)
      }

      setWindowWidth(window.innerWidth)
      setWindowHeight(window.innerHeight)
    })
  }, [])

  // check if an amplify session is already present or not
  const checkSession = async () => {
    try {
      await getCurrentUser()

      // if user is present, call signin endpoint to do stuff with the user and get current user
      const decodedIdToken = jwt_decode(
        localStorage.getItem(
          Object.keys(localStorage).find((key) => key.includes("idToken"))!
        )!
      ) as any

      // if external id is not present, throw error
      if (!decodedIdToken["custom:externalid"]) {
        console.error("No custom:externalid key in token")
        setSignInError(true)
        return
      }

      // signin
      await post("/web/signin", {
        externalid: decodedIdToken["custom:externalid"],
        given_name: decodedIdToken["given_name"],
        family_name: decodedIdToken["family_name"],
        lang: localStorage.getItem("lang"),
        country: localStorage.getItem("country"),
      })
      localStorage.removeItem("lang")
      localStorage.removeItem("country")
      console.log("signin done")
      await getUserInfo()

      setViewOnboarding(false)
      setLoading(false)
    } catch {
      // no user, so go to onboarding, accept terms and privacy and enter with sso
      setLoading(false)
    }
  }

  // sso
  const federatedSignIn = async (provider: string) => {
    try {
      await signInWithRedirect({
        provider: { custom: provider },
      })
    } catch (e) {
      console.error(e)
    }
  }

  // sso
  const userSignOut = async () => {
    try {
      await signOut()

      return true
    } catch (e) {
      console.error(e)

      return false
    }
  }

  // initial fetch
  useEffect(() => {
    // get language from query param and set it
    const langParam = searchParams.get("lang")
    if (langParam) {
      i18next.changeLanguage(langParam)
      if (availableLanguages.some((item) => item.code === langParam)) {
        localStorage.setItem("lang", langParam)
        document.documentElement.lang = langParam
        setLang(langParam)
      } else {
        localStorage.setItem("lang", "en")
        document.documentElement.lang = "en"
        setLang("en")
      }
    } else if (!langParam && !localStorage.getItem("lang")) {
      const langToSet = navigator.language.slice(0, 2)
      i18next.changeLanguage(langToSet)
      if (availableLanguages.some((item) => item.code === langToSet)) {
        localStorage.setItem("lang", langToSet)
        document.documentElement.lang = langToSet
        setLang(langToSet)
      } else {
        localStorage.setItem("lang", "en")
        document.documentElement.lang = "en"
        setLang("en")
      }
    } else {
      const langToSet = localStorage.getItem("lang")!
      i18next.changeLanguage(langToSet)
      if (availableLanguages.some((item) => item.code === langToSet)) {
        localStorage.setItem("lang", langToSet)
        document.documentElement.lang = langToSet
        setLang(langToSet)
      } else {
        localStorage.setItem("lang", "en")
        document.documentElement.lang = "en"
        setLang("en")
      }
    }

    // get country from query param
    const countryParam = searchParams.get("country")
    if (countryParam) {
      localStorage.setItem("country", countryParam.toUpperCase())
      setCountry(countryParam.toUpperCase())
    }

    if (shouldSkipAuth) {
      const fakeSignIn = async () => {
        await getUserInfo()

        setViewOnboarding(false)
        setViewSecondOnboarding(false)
        setLoading(false)
      }

      fakeSignIn()
    } else {
      checkSession()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <MainContext.Provider
      value={{
        loading,
        setLoading,
        signInError,
        viewOnboarding,
        viewSecondOnboarding,
        setViewSecondOnboarding,
        setSignInError,
        setViewOnboarding,
        isMobile,
        windowWidth,
        windowHeight,
        lang,
        setLang,
        country,
        federatedSignIn,
        user,
        setUser,
        userError,
        getUserInfo,
        setUserFirstAccess,
        userSignOut,
      }}
    >
      {children}
    </MainContext.Provider>
  )
}
export { MainController, MainContext }
