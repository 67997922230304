import { useContext, useEffect, useMemo } from "react"
import { MainContext } from "../../controllers/main"
import FootprintFormDesktop from "../../components/footprint/FootprintFormDesktop"
import FootprintFormMobile from "../../components/footprint/FootprintFormMobile"
import { FootprintContext } from "../../controllers/footprint"
import { MissionsContext } from "../../controllers/missions"
import { MissionType } from "../../services/config/enum"
import { useLocation, useNavigate } from "react-router-dom"
import { registerEvent, registerPageEvent } from "../../services/utils/utils"

const FootprintForm = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { isMobile } = useContext(MainContext)
  const { formsList, setGettingFootprint } = useContext(FootprintContext)
  const { missions } = useContext(MissionsContext)

  // register google analytics event
  useEffect(() => {
    registerPageEvent("footprint_form")
  }, [])

  // current form to complete
  const currentForm = useMemo(() => {
    if (location.state && location.state.sector) {
      return formsList.find((form) => form.sector === location.state.sector)!
    }

    if (formsList.some((form) => !form.isRefine)) {
      return formsList.find((form) => !form.isRefine)!
    }

    return formsList[0]
  }, [formsList, location])

  // footprint missions
  const footprintMission = useMemo(() => {
    return missions.find(
      (mission) => mission.missionType === MissionType.MEASURE
    )
  }, [missions])

  // on typeform submit function
  const onTypeformSubmit = () => {
    registerEvent("footprint_form_completed", {
      id: currentForm.formId,
    })
    setGettingFootprint(true)
    navigate("/footprint")
  }

  return isMobile ? (
    <FootprintFormMobile
      currentForm={currentForm}
      footprintMission={footprintMission}
      onTypeformSubmit={onTypeformSubmit}
    />
  ) : (
    <FootprintFormDesktop
      currentForm={currentForm}
      footprintMission={footprintMission}
      onTypeformSubmit={onTypeformSubmit}
    />
  )
}

export default FootprintForm
