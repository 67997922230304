import { LinearProgress, Stack } from "@mui/material"
import { desktopMaxWidth, footerHeight } from "../../services/config/constants"
import { colors } from "../../services/config/colors"
import BreadcrumbDesktop from "../global/desktop/BreadcrumbDesktop"
import { useTranslation } from "react-i18next"
import PaddingContainerDesktop from "../global/desktop/PaddingContainerDesktop"
import AppearFromSide from "../animations/AppearFromSide"
import Title from "../global/common/Title"
import { useNavigate } from "react-router-dom"
import { useContext, useEffect, useRef } from "react"
import { MissionsContext } from "../../controllers/missions"
import PointsHistoryListItem from "../performance/common/PointsHistoryListItem"

const MissionsDesktop = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { missionsHistory, missionsHistoryNextToken, getMissionsHistory } =
    useContext(MissionsContext)

  // missions history loading and next token refs
  const loading = useRef<boolean>(false)
  const nextToken = useRef<string | null>(missionsHistoryNextToken)

  // update next token ref when state changes
  useEffect(() => {
    nextToken.current = missionsHistoryNextToken
  }, [missionsHistoryNextToken])

  // add event listener on main container to check scroll and paginate missions history list
  useEffect(() => {
    const onContainerScroll = async () => {
      const container = document.getElementById("main-container")!

      if (
        container.scrollTop + window.innerHeight + 400 >=
          container.scrollHeight &&
        nextToken.current &&
        !loading.current
      ) {
        loading.current = true
        await getMissionsHistory(false)
        loading.current = false
      }
    }

    const container = document.getElementById("main-container")!
    container.addEventListener("scroll", onContainerScroll)

    return () => {
      container.removeEventListener("scroll", onContainerScroll)
    }
  }, [getMissionsHistory])

  return (
    <Stack
      style={{ width: "100%", minHeight: `calc(100% - ${footerHeight}px)` }}
    >
      {/* navbar background */}
      <div
        style={{
          width: "100%",
          height: 177,
          backgroundColor: colors.background,
        }}
      />
      {/* breadcrumb */}
      <BreadcrumbDesktop
        items={[
          {
            label: t("performance"),
            onClick: () => {
              navigate("/performance")
            },
          },
          {
            label: t("completed_missions"),
          },
        ]}
        selectedItem={1}
        style={{ marginTop: 22 }}
      />
      {/* main container */}
      <PaddingContainerDesktop>
        <Stack style={{ width: "100%", maxWidth: desktopMaxWidth }}>
          {/* header */}
          <AppearFromSide fade>
            <Title
              fontSize={36}
              lineHeight="46px"
              style={{ marginTop: 22 }}
              component="h1"
            >
              {t("completed_missions")}
            </Title>
          </AppearFromSide>
          <Stack
            alignItems="center"
            style={{
              width: "57.14%",
              height: "auto",
              marginBottom: 60,
            }}
          >
            {/* list */}
            <AppearFromSide fade style={{ width: "100%", marginTop: 46 }}>
              <Stack
                style={{
                  width: "100%",
                  position: "relative",
                  paddingBottom: 28,
                  gap: 28,
                }}
              >
                {missionsHistory.map((item, index) => (
                  <Stack key={index} style={{ gap: 28 }}>
                    {index ? (
                      <div
                        style={{
                          width: "100%",
                          height: 1.5,
                          backgroundColor: colors.backgroundLight,
                        }}
                      />
                    ) : null}
                    <PointsHistoryListItem
                      title={item.title}
                      time={item.updatedAt!}
                      points={item.points}
                      type={item.missionType}
                      forFullList
                    />
                  </Stack>
                ))}
                {loading.current && (
                  <LinearProgress
                    style={{
                      width: "100%",
                      position: "absolute",
                      bottom: 0,
                      borderRadius: 14,
                    }}
                  />
                )}
              </Stack>
            </AppearFromSide>
          </Stack>
        </Stack>
      </PaddingContainerDesktop>
    </Stack>
  )
}

export default MissionsDesktop
