import EpisodeBody from "./common/EpisodeBody"
import EpisodeTimeline from "./common/EpisodeTimeline"
import EpisodeHeader from "./common/EpisodeHeader"
import Slide from "../../models/slide"
import Episode from "../../models/episode"
import EpisodeFeedback from "../feedbacks/common/EpisodeFeedback"
import { CSSProperties, useContext } from "react"
import { JourneysContext } from "../../controllers/journeys"
import { motion } from "framer-motion"
import JourneyFeedback from "../feedbacks/common/JourneyFeedback"
import { MainContext } from "../../controllers/main"
import { Alert, ButtonBase } from "@mui/material"
import { availableLanguages } from "../../services/config/constants"
import FadeFromBottom from "../animations/FadeFromBottom"
import { useTranslation } from "react-i18next"
import QuizResult from "../../models/quizResult"
import { useNavigate } from "react-router-dom"
import { focusElement } from "../../services/utils/utils"

const EpisodeMobile = ({
  currentSlide,
  currentSlideIndex,
  numberOfSlides,
  numberOfQuiz,
  totalSlides,
  currentEpisode,
  currentEpisodeIndex,
  nextEpisode,
  currentSlideQuizResult,
  style,
}: {
  currentSlide: Slide
  currentSlideIndex: number
  numberOfSlides: number
  numberOfQuiz: number
  totalSlides: number
  currentEpisode: Episode
  currentEpisodeIndex: number
  nextEpisode?: Episode
  currentSlideQuizResult?: QuizResult
  style?: CSSProperties
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { isMobile } = useContext(MainContext)
  const { journey } = useContext(JourneysContext)

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        position: "relative",
        overflow: "hidden",
        ...style,
      }}
    >
      {/* timeline */}
      <EpisodeTimeline
        currentSlideIndex={currentSlideIndex}
        numberOfSlides={numberOfSlides}
        numberOfQuiz={numberOfQuiz}
      />
      {/* header */}
      {isMobile && (
        <EpisodeHeader
          episode={currentEpisode}
          episodeIndex={currentEpisodeIndex}
        />
      )}
      {/* mobile back button */}
      {isMobile && (
        <ButtonBase
          aria-label={t("previous_slide")}
          disableRipple
          style={{
            width: "30%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 1,
          }}
          onClick={() => {
            if (
              !isMobile ||
              !currentSlide ||
              (currentSlide &&
                currentSlide.slideType === "QuizSlide" &&
                !currentEpisode.result.quiz.some(
                  (item) => item.slideId === currentSlide.id
                )) ||
              (currentSlide &&
                currentSlide.slideType === "QuizSlide" &&
                !currentEpisode.result.completed)
            ) {
              return
            }

            if (currentSlideIndex > 0) {
              focusElement("slide-container-mobile")

              const pathname = window.location.pathname
              navigate(
                pathname.slice(0, pathname.lastIndexOf("/")) +
                  "/" +
                  (currentSlideIndex - 1)
              )
            }
          }}
        />
      )}
      {/* episode body */}
      <div
        id="slide-container-mobile"
        style={{ width: "100%", height: "100%", minHeight: "100%" }}
        aria-label={
          currentSlideIndex === totalSlides
            ? t("end_of_episode_feedback")
            : t("slide_count_of_total", {
                count: currentSlideIndex + 1,
                total: totalSlides,
              })
        }
        role={isMobile ? "region" : "none"}
        aria-roledescription={isMobile ? t("content") : undefined}
        tabIndex={isMobile ? 0 : undefined}
      >
        {currentSlideIndex === totalSlides &&
        journey?.result.completed &&
        !journey.episodes[currentEpisodeIndex + 1] ? (
          <JourneyFeedback />
        ) : currentSlideIndex === totalSlides ? (
          <EpisodeFeedback episode={currentEpisode} nextEpisode={nextEpisode} />
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            style={{ width: "100%", height: "100%" }}
          >
            <EpisodeBody
              currentSlide={currentSlide}
              currentEpisode={currentEpisode}
              isLastEpisode={
                !journey!.episodes[currentEpisodeIndex + 1] ? true : false
              }
              isDailyEpisode={false}
              quizResult={currentSlideQuizResult}
            />
          </motion.div>
        )}
      </div>
      {/* mobile next button */}
      {isMobile && (
        <ButtonBase
          aria-label={t("next_slide")}
          disableRipple
          style={{
            width: "70%",
            height: "100%",
            position: "absolute",
            top: 0,
            right: 0,
            zIndex: 1,
          }}
          onClick={() => {
            if (
              !isMobile ||
              !currentSlide ||
              (currentSlide &&
                currentSlide.slideType === "QuizSlide" &&
                !currentEpisode.result.quiz.some(
                  (item) => item.slideId === currentSlide.id
                ))
            ) {
              return
            }

            if (currentSlideIndex + 1 <= totalSlides) {
              focusElement("slide-container-mobile")

              const pathname = window.location.pathname
              navigate(
                pathname.slice(0, pathname.lastIndexOf("/")) +
                  "/" +
                  (currentSlideIndex + 1)
              )
            }
          }}
        />
      )}
      {/* alert if the user has completed the current quiz in another language (the results are not available) */}
      {currentSlide &&
      currentSlide.slideType === "QuizSlide" &&
      currentEpisode.result.completed &&
      !currentEpisode.result.quiz.find(
        (item) => item.slideId === currentSlide.id
      ) ? (
        <FadeFromBottom
          style={{
            width: "calc(100% - 32px)",
            position: "absolute",
            bottom: 16,
            left: 16,
            zIndex: 20,
          }}
        >
          <Alert
            severity="warning"
            style={{
              width: "100%",
              fontSize: 13,
            }}
          >
            {t("episode_completed_in_another_language", {
              lang: availableLanguages.find(
                (item) => item.code === currentEpisode.result.quiz[0].lang
              )
                ? t(
                    availableLanguages.find(
                      (item) => item.code === currentEpisode.result.quiz[0].lang
                    )!.label
                  ).toLowerCase()
                : "unknown",
            })}
          </Alert>
        </FadeFromBottom>
      ) : null}
    </div>
  )
}

export default EpisodeMobile
