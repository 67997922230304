// config
export const shoudlShowApiLogs = false
export const shouldSkipAuth = false
export const maximumActionsPerTime = 10
export const mobileBreakpoint = 1280
export const accessibilityHeightThreshold = 350
export const availableLanguages = [
  { code: "it", label: "italian" },
  { code: "en", label: "english" },
  { code: "es", label: "spanish" },
]
export const countriesWithDoublePrivacyCheck = [
  "CL",
  "US",
  "MX",
  "PA",
  "CR",
  "GT",
  "CO",
]

// stories
export const numberOfRows = 43
export const numberOfColumns = 15
export const slideWidth = 360
export const slideHeight = 700

// dimensions
export const footerHeight = 150
export const desktopPadding = "8.984vw"
export const desktopMaxWidth = 1050
export const desktopActionsLogButtonWidth = "39%"
export const desktopActionsLogButtonMaxWidth = 557
export const desktopColumnsGap = 30

// data
export const italianFootprintAverage = 5.55
