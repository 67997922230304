import { useContext, useEffect, useMemo } from "react"
import { MainContext } from "../../controllers/main"
import JourneyMobile from "../../components/journey/JourneyMobile"
import JourneyDesktop from "../../components/journey/JourneyDesktop"
import { MissionsContext } from "../../controllers/missions"
import { MissionSubType } from "../../services/config/enum"
import { registerPageEvent } from "../../services/utils/utils"

const Journey = () => {
  const { isMobile } = useContext(MainContext)
  const { missions } = useContext(MissionsContext)

  // register google analytics event
  useEffect(() => {
    registerPageEvent("journey")
  }, [])

  // journey missions
  const journeyMission = useMemo(() => {
    return missions.find(
      (mission) => mission.missionSubType === MissionSubType.JOURNEY
    )
  }, [missions])

  return isMobile ? (
    <JourneyMobile journeyMission={journeyMission} />
  ) : (
    <JourneyDesktop journeyMission={journeyMission} />
  )
}

export default Journey
