import { Stack } from "@mui/material"
import ActionMissionCard from "../common/ActionMissionCard"
import { CSSProperties, useState } from "react"
import { colors } from "../../../services/config/colors"

const ActionsMissionsCarouselMobile = ({
  actionsMissions,
  style,
}: {
  actionsMissions: {
    title: string
    progress: string
    points: number
    satisfied: boolean
    onClick: () => void
  }[]
  style?: CSSProperties
}) => {
  // current mission, changed on scroll
  const [currentMission, setCurrentMission] = useState<number>(0)

  return (
    <Stack alignItems="center" style={{ width: "100%", height: 166, ...style }}>
      <Stack
        id="actions-missions-carousel"
        direction="row"
        alignItems="flex-start"
        style={{
          width: "100%",
          maxWidth: "100%",
          height: 165,
          minHeight: 165,
          overflowX: "scroll",
          scrollSnapType: "x mandatory",
          paddingInline: 16,
          gap: 12,
        }}
        onScroll={() => {
          const container = document.getElementById(
            "actions-missions-carousel"
          )!
          setCurrentMission(
            Math.round(container.scrollLeft / (window.innerWidth - 69.5))
          )
        }}
      >
        {actionsMissions.map((mission, index) => (
          <ActionMissionCard key={index} mission={mission} />
        ))}
      </Stack>
      <Stack direction="row" style={{ height: 8, marginTop: -7, gap: 4 }}>
        {actionsMissions.map((mission, index) => (
          <div
            key={index}
            style={{
              width: currentMission === index ? 16 : 8,
              height: 8,
              borderRadius: 4,
              backgroundColor:
                currentMission === index ? colors.primary : colors.disabled,
              transition: "250ms",
            }}
          />
        ))}
      </Stack>
    </Stack>
  )
}

export default ActionsMissionsCarouselMobile
