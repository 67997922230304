import { Grid, Stack } from "@mui/material"
import { Dispatch, SetStateAction, useContext, useRef } from "react"
import { MainContext } from "../../controllers/main"
import {
  desktopMaxWidth,
  footerHeight,
  maximumActionsPerTime,
} from "../../services/config/constants"
import BreadcrumbDesktop from "../global/desktop/BreadcrumbDesktop"
import { scrollWindowToTop } from "../../services/utils/utils"
import { useNavigate } from "react-router-dom"
import { colors } from "../../services/config/colors"
import ActionDetailsBackgroundDesktop from "../../assets/svgComponents/ActionDetailsBackgroundDesktop"
import AppearFromSide from "../animations/AppearFromSide"
import Title from "../global/common/Title"
import CategoryFilterBadge from "./common/CategoryFilterBadge"
import Action from "../../models/action"
import { TagsContext } from "../../controllers/tags"
import ActionCardHorizontal from "./common/ActionCardHorizontal"
import FadeFromTop from "../animations/FadeFromTop"
import Button from "../global/common/Button"
import { ActionsContext } from "../../controllers/actions"
import ActionsLoggedFeedbackMobile from "../feedbacks/mobile/ActionsLoggedFeedbackMobile"
import Alert from "../global/common/Alert"
import PaddingContainerDesktop from "../global/desktop/PaddingContainerDesktop"
import { useTranslation } from "react-i18next"

const ActionsExploreDesktop = ({
  selectedCategory,
  filteredActionsList,
  noActionsAlertOpen,
  setNoActionsAlertOpen,
}: {
  selectedCategory: string
  filteredActionsList: Action[]
  noActionsAlertOpen: boolean
  setNoActionsAlertOpen: Dispatch<SetStateAction<boolean>>
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { windowHeight } = useContext(MainContext)
  const { categories } = useContext(TagsContext)
  const {
    selectedActions,
    logActions,
    actionsLoggedFeedbackOpen,
    setActionsLoggedFeedbackOpen,
    actionsLoggedFeedbackPlural,
    setSelectedCategory,
    actionsPerTimeLimitAlertOpen,
    setActionsPerTimeLimitAlertOpen,
  } = useContext(ActionsContext)

  // debounce for log button
  const canClickLogButton = useRef<boolean>(true)

  return (
    <Stack
      style={{
        width: "100%",
        minHeight:
          windowHeight > 1200 ? `calc(100% - ${footerHeight}px)` : 1200,
        position: "relative",
      }}
    >
      {/* navbar background */}
      <div
        style={{
          width: "100%",
          height: 177,
          backgroundColor: colors.background,
        }}
      />
      {/* breadcrumb */}
      <BreadcrumbDesktop
        items={[
          {
            label: t("actions"),
            onClick: () => {
              scrollWindowToTop()
              navigate("/actions")
            },
          },
          {
            label: t("explore_all_actions"),
          },
        ]}
        selectedItem={1}
        style={{ marginTop: 22, zIndex: 1 }}
      />
      {/* main container */}
      <PaddingContainerDesktop>
        <Stack
          style={{
            width: "100%",
            maxWidth: desktopMaxWidth,
            position: "relative",
            zIndex: 1,
          }}
        >
          {/* header */}
          <AppearFromSide fade>
            <Title
              fontSize={36}
              lineHeight="46px"
              style={{ marginTop: 22 }}
              component="h1"
            >
              {t("explore_all_actions")}
            </Title>
          </AppearFromSide>
          {/* category filters */}
          <Stack
            role="radiogroup"
            aria-label={t("filters")}
            direction="row"
            flexWrap="wrap"
            style={{
              width: "65.71%",
              maxWidth: "65.71%",
              gap: 12,
              rowGap: 12,
              marginTop: 22,
            }}
          >
            <CategoryFilterBadge
              category={{
                id: "catDefault",
                name: t("featured"),
                backColor: colors.primary,
                backTagColor: colors.primary,
                foreColor: colors.textWhite,
              }}
              selected={selectedCategory === "catDefault"}
              onClick={() => {
                setSelectedCategory("catDefault")
              }}
            />
            {categories.map((category, index) => (
              <CategoryFilterBadge
                key={category.id}
                category={category}
                selected={selectedCategory === category.id}
                onClick={() => {
                  setSelectedCategory(category.id)
                }}
              />
            ))}
          </Stack>
          {/* number of items for accessibility */}
          <Title
            key={selectedCategory}
            fontSize={20}
            lineHeight="42px"
            color={colors.textSecondary}
            style={{ marginTop: 22 }}
            ariaLive="polite"
          >
            {`${filteredActionsList.length} ${
              filteredActionsList.length === 1
                ? t("action").toLowerCase()
                : t("actions").toLowerCase()
            }`}
          </Title>
          {/* actions grid */}
          <Grid
            container
            columns={2}
            rowGap="16px"
            columnGap="30px"
            style={{ width: "65.71%", marginTop: 22 }}
          >
            {filteredActionsList.map((action, index) => (
              <Grid
                item
                zeroMinWidth
                xs={1}
                key={action.id + selectedCategory}
                style={{ maxWidth: "calc(50% - 15px)" }}
              >
                <FadeFromTop
                  key={action.id + selectedCategory}
                  delay={0.015 * index}
                >
                  <ActionCardHorizontal
                    width="100%"
                    action={action}
                    showCategoryAndCo2
                  />
                </FadeFromTop>
              </Grid>
            ))}
          </Grid>
          {/* log button */}
          <FadeFromTop
            delay={0.05}
            style={{
              marginTop: 68,
              marginBottom: 76,
              width: "65.71%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              width="74%"
              onClick={() => {
                if (!selectedActions.length) {
                  setNoActionsAlertOpen(true)
                  return
                }

                if (canClickLogButton.current) {
                  logActions()

                  canClickLogButton.current = false
                  setTimeout(() => {
                    canClickLogButton.current = true
                  }, 800)
                }
              }}
              title={
                selectedActions.length === 0
                  ? t("log")
                  : selectedActions.length === 1
                  ? t("log_one_action")
                  : t("log_count_actions", { count: selectedActions.length })
              }
            >
              {selectedActions.length === 0
                ? t("log")
                : selectedActions.length === 1
                ? t("log_one_action")
                : t("log_count_actions", { count: selectedActions.length })}
            </Button>
          </FadeFromTop>
        </Stack>
      </PaddingContainerDesktop>
      {/* background */}
      <div
        style={{
          width: "100%",
          maxWidth: 1920,
          height: "100%",
          maxHeight: "100%",
          overflow: "hidden",
          position: "absolute",
          top: 0,
          right: 0,
        }}
      >
        <ActionDetailsBackgroundDesktop
          color={colors.background}
          style={{ position: "absolute", top: 500 }}
        />
      </div>
      {/* alerts */}
      <ActionsLoggedFeedbackMobile
        open={actionsLoggedFeedbackOpen}
        setOpen={setActionsLoggedFeedbackOpen}
        plural={actionsLoggedFeedbackPlural}
      />
      <Alert
        open={noActionsAlertOpen}
        title={t("no_actions_selected")}
        description={t("select_action_to_log")}
        primaryActionLabel={t("ok")}
        primaryActionOnClick={() => {
          setNoActionsAlertOpen(false)
        }}
      />
      <Alert
        open={actionsPerTimeLimitAlertOpen}
        title={t("warning")}
        description={t("actions_limit", { count: maximumActionsPerTime })}
        primaryActionLabel={t("i_understand")}
        primaryActionOnClick={() => {
          setActionsPerTimeLimitAlertOpen(false)
        }}
      />
    </Stack>
  )
}

export default ActionsExploreDesktop
