import { Skeleton, Stack } from "@mui/material"
import AppearFromSide from "../../animations/AppearFromSide"
import { useContext } from "react"
import { ActionsContext } from "../../../controllers/actions"

const HistoryColumnSkeletonMobile = () => {
  const { currentHistory, currentDate } = useContext(ActionsContext)

  // array of skeleton, calculated based on the global history to know how many skeletons show
  const skeletonsArray = Array(
    currentHistory
      .find(
        (item) =>
          new Date(item.date).toLocaleDateString() ===
          currentDate.toLocaleDateString()
      )
      ?.counters.reduce(
        (accumulator, currentValue) => accumulator + currentValue.amount,
        0
      )
  ).fill("")

  return (
    <Stack
      style={{
        width: "100%",
        marginTop: 16,
        paddingInline: 16,
        paddingBottom: 32,
        gap: 8,
      }}
    >
      {skeletonsArray.map((item, index) => (
        <AppearFromSide key={index} fade delay={0.05 * index}>
          <Skeleton
            variant="rectangular"
            animation="wave"
            style={{ width: "100%", height: 88, borderRadius: 10 }}
          />
        </AppearFromSide>
      ))}
    </Stack>
  )
}

export default HistoryColumnSkeletonMobile
