import { Dispatch, SetStateAction } from "react"
import Calendar from "../common/Calendar"
import { Fade, Modal } from "@mui/material"
import { useTranslation } from "react-i18next"

const CalendarDialogMobile = ({
  open,
  setOpen,
  daysDisabled,
  setDaysDisabled,
}: {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  daysDisabled: boolean
  setDaysDisabled: Dispatch<SetStateAction<boolean>>
}) => {
  const { t } = useTranslation()

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false)
      }}
      aria-labelledby={t("calendar")}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Fade in={open}>
        <div style={{ paddingInline: 16 }}>
          <Calendar
            daysDisabled={daysDisabled}
            setDaysDisabled={setDaysDisabled}
            setDialogOpen={setOpen}
          />
        </div>
      </Fade>
    </Modal>
  )
}

export default CalendarDialogMobile
