import { ButtonBase, Stack } from "@mui/material"
import { colors } from "../../../services/config/colors"
import Title from "../common/Title"
import { KeyboardEvent, useContext, useEffect, useRef, useState } from "react"
import { motion, useReducedMotion } from "framer-motion"
import Text from "../common/Text"
import { useNavigate } from "react-router-dom"
import {
  desktopColumnsGap,
  desktopMaxWidth,
  desktopPadding,
} from "../../../services/config/constants"
import { useTranslation } from "react-i18next"
import { ChallengeContext } from "../../../controllers/challenge"
import { MainContext } from "../../../controllers/main"

const NavBarDesktop = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const shouldReduceMotion = useReducedMotion()
  const { user } = useContext(MainContext)
  const { challenge } = useContext(ChallengeContext)

  // change item
  const changeItem = () => {
    const pathname = window.location.pathname

    return pathname.includes("/footprint")
      ? 1
      : pathname.includes("/actions")
      ? 2
      : pathname.includes("/journey")
      ? 3
      : pathname.includes("/challenge")
      ? 4
      : pathname.includes("/performance")
      ? 5
      : 0
  }

  // current menu item
  const [selectedItem, setSelectedItem] = useState<number>(changeItem())

  // change item when location is changed in other components
  useEffect(() => {
    setSelectedItem(changeItem())
  }, [window.location.pathname]) // eslint-disable-line react-hooks/exhaustive-deps

  // focus main content (accessibility)
  const focusMainContent = () => {
    const main = document.getElementById("main-content")!
    main.tabIndex = 0
    main.focus()
    setTimeout(() => main.removeAttribute("tabindex"), 500)
  }

  // refs for all tabs
  const tabRefs = useRef<HTMLButtonElement[]>([])

  // focus tab helper
  const [selectedIndex, setSelectedIndex] = useState<number>(0)

  const focusTab = (index: number) => {
    const tab = tabRefs.current[index]
    if (tab) {
      tab.focus()
    }
  }

  // onKeyDown handler for tab elements
  const onKeyDown = (event: KeyboardEvent<HTMLButtonElement>) => {
    const count = 6
    const nextTab = () => focusTab((selectedIndex + 1) % count)
    const prevTab = () => focusTab((selectedIndex - 1 + count) % count)
    const firstTab = () => focusTab(0)
    const lastTab = () => focusTab(count - 1)

    const keyMap = {
      ArrowRight: nextTab,
      ArrowLeft: prevTab,
      Home: firstTab,
      End: lastTab,
    }

    if (
      event.key === "ArrowRight" ||
      event.key === "ArrowLeft" ||
      event.key === "Home" ||
      event.key === "End"
    ) {
      const action = keyMap[event.key]
      if (action) {
        event.preventDefault()
        action()
      }
    }
  }

  return (
    <nav
      role="navigation"
      style={{
        width: "100%",
        height: 177,
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 99,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor:
          selectedItem === 0 ? "rgba(0, 0, 0, 0)" : colors.background,
      }}
    >
      <ButtonBase
        id="skip-link"
        style={{
          position: "absolute",
          top: 16,
          color: colors.primary,
          cursor: "default",
          opacity: 0,
          left: "-200vw",
        }}
        onFocus={() => {
          const link = document.getElementById("skip-link")!
          link.style.opacity = "1"
          link.style.left = ""
        }}
        onBlur={() => {
          const link = document.getElementById("skip-link")!
          link.style.opacity = "0"
          link.style.left = "-200vw"
        }}
        onClick={() => {
          const link = document.getElementById("skip-link")!
          link.style.opacity = "0"
          link.style.left = "-200vw"

          focusMainContent()
        }}
        aria-label={t("skip_to_main_content")}
      >
        <Text fontSize={18} fontWeight={700} color={colors.primary}>
          {t("skip_to_main_content")}
        </Text>
      </ButtonBase>
      <Stack
        direction="row"
        alignItems="center"
        style={{
          width: `calc(100% - (${desktopPadding} * 2))`,
          maxWidth: desktopMaxWidth,
          height: 60,
          gap: desktopColumnsGap,
          position: "relative",
        }}
      >
        {/* home button */}
        <ButtonBase
          role="tab"
          aria-selected={selectedItem === 0 ? "true" : "false"}
          style={{
            width: 60,
            minWidth: 60,
            height: "100%",
            borderRadius: 30,
            backgroundColor: colors.backgroundWhite,
          }}
          onClick={() => {
            setSelectedItem(0)
            navigate("/")

            focusMainContent()
          }}
          aria-label={t("home")}
          ref={(element) => (tabRefs.current[0] = element!)}
          onKeyDown={onKeyDown}
          onFocus={() => setSelectedIndex(0)}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 26 26"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            style={{
              color: selectedItem === 0 ? colors.textWhite : colors.primary,
              position: "relative",
              zIndex: 1,
              transition: "400ms",
            }}
          >
            <path
              d="M1.3604 8.4049C1.90829 7.37524 2.93839 6.608 4.99861 5.07352C8.81253 2.23284 10.7195 0.8125 13 0.8125C15.2805 0.8125 17.1875 2.23284 21.0014 5.07352C23.0616 6.608 24.0917 7.37524 24.6396 8.4049C25.1875 9.43456 25.1875 10.6032 25.1875 12.9405V14.7581C25.1875 19.6746 25.1875 22.1328 23.4027 23.6601C22.1282 24.7508 20.2991 25.0626 17.2656 25.1518L17.2657 18.1757C17.2664 17.3373 17.267 16.6879 17.0697 16.1365C16.7316 15.1917 15.988 14.448 15.0432 14.11C14.4918 13.9127 13.8424 13.9132 13.004 13.914L12.8477 13.9141L12.6913 13.914C11.8529 13.9132 11.2035 13.9127 10.6521 14.11C9.70731 14.448 8.96367 15.1917 8.62561 16.1365C8.42832 16.6879 8.42887 17.3373 8.4296 18.1757L8.42969 25.1419C5.57735 25.0406 3.82852 24.7137 2.59732 23.6601C0.8125 22.1328 0.8125 19.6746 0.8125 14.7581V12.9405C0.8125 10.6032 0.812502 9.43456 1.3604 8.4049Z"
              fill="currentColor"
            />
            <path
              d="M10.2578 25.1795V18.332C10.2578 17.2609 10.2713 16.9636 10.3469 16.7524C10.5005 16.3229 10.8386 15.9849 11.268 15.8312C11.4793 15.7557 11.7766 15.7422 12.8477 15.7422C13.9187 15.7422 14.216 15.7557 14.4273 15.8312C14.8568 15.9849 15.1948 16.3229 15.3484 16.7524C15.424 16.9636 15.4375 17.2609 15.4375 18.332V25.1821C14.6878 25.1875 13.8774 25.1875 13 25.1875C12.0024 25.1875 11.0915 25.1875 10.2578 25.1795Z"
              fill="currentColor"
            />
          </svg>
        </ButtonBase>
        {/* menu */}
        <Stack
          direction="row"
          alignItems="center"
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: colors.backgroundWhite,
            borderRadius: 30,
          }}
        >
          <ButtonBase
            style={{
              width: "25%",
              height: "100%",
              borderRadius: 30,
            }}
            onClick={() => {
              setSelectedItem(1)
              navigate("/footprint")

              focusMainContent()
            }}
            aria-label={t("carbon_footprint")}
            role="tab"
            aria-selected={selectedItem === 1 ? "true" : "false"}
            ref={(element) => (tabRefs.current[1] = element!)}
            onKeyDown={onKeyDown}
            onFocus={() => setSelectedIndex(1)}
          >
            <Text
              fontSize={18}
              fontWeight={700}
              color={selectedItem === 1 ? colors.textWhite : colors.primary}
              style={{ position: "relative", zIndex: 1, transition: "400ms" }}
            >
              {t("carbon_footprint")}
            </Text>
          </ButtonBase>
          <ButtonBase
            style={{
              width: "25%",
              height: "100%",
              borderRadius: 30,
            }}
            onClick={() => {
              setSelectedItem(2)
              navigate("/actions")

              focusMainContent()
            }}
            aria-label={t("actions")}
            role="tab"
            aria-selected={selectedItem === 2 ? "true" : "false"}
            ref={(element) => (tabRefs.current[2] = element!)}
            onKeyDown={onKeyDown}
            onFocus={() => setSelectedIndex(2)}
          >
            <Text
              fontSize={18}
              fontWeight={700}
              color={selectedItem === 2 ? colors.textWhite : colors.primary}
              style={{ position: "relative", zIndex: 1, transition: "400ms" }}
            >
              {t("actions")}
            </Text>
          </ButtonBase>
          <ButtonBase
            style={{
              width: "25%",
              height: "100%",
              borderRadius: 30,
            }}
            onClick={() => {
              setSelectedItem(3)
              navigate("/journey")

              focusMainContent()
            }}
            aria-label={t("journey")}
            role="tab"
            aria-selected={selectedItem === 3 ? "true" : "false"}
            ref={(element) => (tabRefs.current[3] = element!)}
            onKeyDown={onKeyDown}
            onFocus={() => setSelectedIndex(3)}
          >
            <Text
              fontSize={18}
              fontWeight={700}
              color={selectedItem === 3 ? colors.textWhite : colors.primary}
              style={{ position: "relative", zIndex: 1, transition: "400ms" }}
            >
              {t("journey")}
            </Text>
          </ButtonBase>
          <ButtonBase
            disabled={!challenge}
            style={{
              width: "25%",
              height: "100%",
              borderRadius: 30,
              opacity: !challenge ? 0.4 : 1,
            }}
            onClick={() => {
              setSelectedItem(4)
              navigate("/challenge")

              focusMainContent()
            }}
            aria-label={t("challenge")}
            role="tab"
            aria-selected={selectedItem === 4 ? "true" : "false"}
            ref={(element) => (tabRefs.current[4] = element!)}
            onKeyDown={onKeyDown}
            onFocus={() => setSelectedIndex(4)}
          >
            <Text
              fontSize={18}
              fontWeight={700}
              color={selectedItem === 4 ? colors.textWhite : colors.primary}
              style={{ position: "relative", zIndex: 1, transition: "400ms" }}
            >
              {t("challenge")}
            </Text>
          </ButtonBase>
        </Stack>
        {/* performance button */}
        <ButtonBase
          style={{
            width: "auto",
            height: "100%",
            borderRadius: 30,
            backgroundColor: colors.backgroundWhite,
          }}
          onClick={() => {
            setSelectedItem(5)
            navigate("/performance")

            focusMainContent()
          }}
          aria-label={t("performance")}
          role="tab"
          aria-selected={selectedItem === 5 ? "true" : "false"}
          ref={(element) => (tabRefs.current[5] = element!)}
          onKeyDown={onKeyDown}
          onFocus={() => setSelectedIndex(5)}
        >
          <Stack
            direction="row"
            alignItems="center"
            style={{
              width: 155,
              minWidth: 155,
              height: "100%",
              paddingLeft: 9,
            }}
          >
            <div
              style={{
                width: 46,
                height: 46,
                borderRadius: "100%",
                position: "relative",
                zIndex: 1,
                backgroundImage: `url(${
                  user!.profileImage
                }), url(https://cdn.aworld.io/users/default/profile.jpg)`,
                backgroundSize: "105%, 100%",
                backgroundPosition: "center",
              }}
            />
            <Title
              fontSize={18}
              color={selectedItem === 5 ? colors.textWhite : colors.primary}
              style={{
                transition: "400ms",
                position: "absolute",
                zIndex: 1,
                right: 18,
              }}
            >
              {user?.points} {t("points")}
            </Title>
          </Stack>
        </ButtonBase>
        {/* selector */}
        <motion.div
          animate={selectedItem.toString()}
          variants={{
            "0": {
              width: 60,
              left: 0,
            },
            "1": {
              width: `calc((100% - 215px - ${desktopColumnsGap}px * 2) / 4)`,
              left: `calc(60px + ${desktopColumnsGap}px + (100% - 215px - ${desktopColumnsGap}px * 2) / 4 * 0)`,
            },
            "2": {
              width: `calc((100% - 215px - ${desktopColumnsGap}px * 2) / 4)`,
              left: `calc(60px + ${desktopColumnsGap}px + (100% - 215px - ${desktopColumnsGap}px * 2) / 4 * 1)`,
            },
            "3": {
              width: `calc((100% - 215px - ${desktopColumnsGap}px * 2) / 4)`,
              left: `calc(60px + ${desktopColumnsGap}px + (100% - 215px - ${desktopColumnsGap}px * 2) / 4 * 2)`,
            },
            "4": {
              width: `calc((100% - 215px - ${desktopColumnsGap}px * 2) / 4)`,
              left: `calc(60px + ${desktopColumnsGap}px + (100% - 215px - ${desktopColumnsGap}px * 2) / 4 * 3)`,
            },
            "5": {
              width: 155,
              left: `calc(60px + ${
                desktopColumnsGap * 2
              }px + (100% - 215px - ${desktopColumnsGap}px * 2) / 4 * 4)`,
            },
          }}
          transition={{
            left: { type: "spring", damping: 20, stiffness: 150 },
            width: { duration: shouldReduceMotion ? 0 : 0.3 },
          }}
          initial={false}
          style={{
            height: 60,
            position: "absolute",
            backgroundColor: colors.primary,
            borderRadius: 30,
          }}
        />
      </Stack>
    </nav>
  )
}

export default NavBarDesktop
