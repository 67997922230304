import { Doughnut } from "react-chartjs-2"
import ChartDataLabels from "chartjs-plugin-datalabels"
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js"
import { Stack } from "@mui/material"
import Title from "../../global/common/Title"
import Text from "../../global/common/Text"
import { useTranslation } from "react-i18next"
import { useContext } from "react"
import { MainContext } from "../../../controllers/main"
import { PulseLoader } from "react-spinners"
import { colors } from "../../../services/config/colors"
import { FootprintContext } from "../../../controllers/footprint"
import { TagsContext } from "../../../controllers/tags"

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels)

const FootprintDoughnutChart = () => {
  const { t } = useTranslation()
  const { isMobile } = useContext(MainContext)
  const { gettingFootprint, userFootprint } = useContext(FootprintContext)
  const { mainCategories } = useContext(TagsContext)

  return !userFootprint ? (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <Doughnut
        aria-label={t("footprint_chart_empty_alt")}
        role="img"
        style={{
          width: "100%",
          opacity: gettingFootprint ? 0.5 : 1,
          transition: "200ms",
        }}
        options={{
          cutout: "68%",
          events: [],
          layout: {
            padding: {
              left: isMobile ? 35 : 40,
              right: isMobile ? 35 : 40,
              top: isMobile ? 35 : 40,
              bottom: isMobile ? 35 : 40,
            },
          },
          plugins: {
            datalabels: {
              color: (context: any) => {
                return context.chart.data.datasets[0].borderColor[
                  context.dataIndex
                ]
              },
              formatter: (value: any, context: any) => {
                return `${context.chart.data.labels[context.dataIndex]}\n-`
              },
              anchor: "end",
              align: "center",
              textAlign: "center",
              font: {
                weight: 700,
                size: isMobile ? 12 : 15,
                family: "Satoshi",
              },
              backgroundColor: (context: any) => {
                return context.chart.data.datasets[0].backgroundColor[
                  context.dataIndex
                ]
              },
              borderColor: "white",
              borderWidth: 3,
              borderRadius: 4.5,
              padding: {
                left: isMobile ? 6 : 12,
                right: isMobile ? 6 : 12,
                top: isMobile ? 6 : 8,
                bottom: isMobile ? 6 : 8,
              },
            },
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
          },
        }}
        data={{
          labels: mainCategories.map((mainCategory) => mainCategory.name),
          datasets: [
            {
              data: [2.5, 2.5, 2.5, 2.5],
              backgroundColor: mainCategories.map(
                (mainCategory) => mainCategory.backTagColor
              ),
              borderColor: mainCategories.map(
                (mainCategory) => mainCategory.foreColor
              ),
              borderWidth: 0,
              borderRadius: 1000,
              spacing: 6,
            },
          ],
        }}
      />
      <Stack alignItems="center" style={{ position: "absolute" }}>
        {gettingFootprint ? (
          <div style={{ height: isMobile ? 54 : 98.5 }} />
        ) : (
          <Title fontSize={isMobile ? 40 : 73}>--</Title>
        )}
        <Text
          fontSize={isMobile ? 12 : 18}
          fontWeight={400}
          style={{
            marginTop: isMobile ? -4 : -10,
            opacity: gettingFootprint ? 0.5 : 1,
            transition: "200ms",
          }}
        >
          {t("tons_co2e")}
        </Text>
      </Stack>
      {gettingFootprint && (
        <PulseLoader color={colors.text} style={{ position: "absolute" }} />
      )}
    </div>
  ) : (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <Doughnut
        aria-label={t("footprint_chart_alt", {
          homePercentage: Math.round(
            (userFootprint.footprintHome / userFootprint.footprint) * 100
          ),
          shoppingPercentage: Math.round(
            (userFootprint.footprintShopping / userFootprint.footprint) * 100
          ),
          transportPercentage: Math.round(
            (userFootprint.footprintTransport / userFootprint.footprint) * 100
          ),
          foodPercentage: Math.round(
            (userFootprint.footprintFood / userFootprint.footprint) * 100
          ),
        })}
        role="img"
        style={{
          width: "100%",
          opacity: gettingFootprint ? 0.5 : 1,
          transition: "200ms",
        }}
        options={{
          cutout: "68%",
          events: [],
          layout: {
            padding: {
              left: isMobile ? 35 : 40,
              right: isMobile ? 35 : 40,
              top: isMobile ? 35 : 40,
              bottom: isMobile ? 35 : 40,
            },
          },
          plugins: {
            datalabels: {
              color: (context: any) => {
                return context.chart.data.datasets[0].borderColor[
                  context.dataIndex
                ]
              },
              formatter: (value: any, context: any) => {
                if (context / userFootprint.footprint <= 0.01) {
                  return null
                } else {
                  return `${
                    context.chart.data.labels[context.dataIndex]
                  }\n${Math.round((value / userFootprint.footprint) * 100)}%`
                }
              },
              anchor: "end",
              align: "center",
              textAlign: "center",
              font: {
                weight: 700,
                size: isMobile ? 12 : 15,
                family: "Satoshi",
              },
              backgroundColor: (context: any) => {
                return context.chart.data.datasets[0].backgroundColor[
                  context.dataIndex
                ]
              },
              borderColor: "white",
              borderWidth: 3,
              borderRadius: 4.5,
              padding: {
                left: isMobile ? 6 : 12,
                right: isMobile ? 6 : 12,
                top: isMobile ? 6 : 8,
                bottom: isMobile ? 6 : 8,
              },
            },
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
          },
        }}
        data={{
          labels: mainCategories.map((mainCategory) => mainCategory.name),
          datasets: [
            {
              data: [
                userFootprint.footprintHome,
                userFootprint.footprintShopping,
                userFootprint.footprintTransport,
                userFootprint.footprintFood,
              ],
              backgroundColor: mainCategories.map(
                (mainCategory) => mainCategory.backTagColor
              ),
              borderColor: mainCategories.map(
                (mainCategory) => mainCategory.foreColor
              ),
              borderWidth: 0,
              borderRadius: 1000,
              spacing: 6,
            },
          ],
        }}
      />
      <Stack alignItems="center" style={{ position: "absolute" }}>
        {gettingFootprint ? (
          <div style={{ height: isMobile ? 54 : 98.5 }} />
        ) : (
          <Title fontSize={isMobile ? 40 : 73}>
            {userFootprint.footprint.toString().replace(".", ",")}
          </Title>
        )}
        <Text
          fontSize={isMobile ? 12 : 18}
          fontWeight={400}
          style={{
            marginTop: isMobile ? -4 : -10,
            opacity: gettingFootprint ? 0.5 : 1,
            transition: "200ms",
          }}
        >
          {t("tons_co2e")}
        </Text>
      </Stack>
      {gettingFootprint && (
        <PulseLoader color={colors.text} style={{ position: "absolute" }} />
      )}
    </div>
  )
}

export default FootprintDoughnutChart
