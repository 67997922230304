import { ButtonBase, Stack } from "@mui/material"
import { colors } from "../../../services/config/colors"
import measureWhiteIcon from "../../../assets/icons/missions/missionsTypes/measure-white.svg"
import Title from "../../global/common/Title"
import { useTranslation } from "react-i18next"
import Mission from "../../../models/mission"
import { CSSProperties, useContext } from "react"
import { MainContext } from "../../../controllers/main"
import { useNavigate } from "react-router-dom"
import { FootprintContext } from "../../../controllers/footprint"
import { scrollWindowToTop } from "../../../services/utils/utils"
import PointsBadge from "../../global/common/PointsBadge"

const FootprintMissionCard = ({
  mission,
  style,
}: {
  mission: Mission
  style?: CSSProperties
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { isMobile } = useContext(MainContext)
  const { gettingFootprint } = useContext(FootprintContext)

  return (
    <Stack
      style={{
        width: "100%",
        minWidth: "100%",
        height: isMobile ? 142 : 207,
        gap: isMobile ? 10 : 18,
        ...style,
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        style={{
          width: "100%",
          height: isMobile ? 32 : 40,
          minHeight: isMobile ? 32 : 40,
          borderRadius: 4,
          backgroundColor: colors.missions.measure,
          gap: 8,
        }}
      >
        <img
          src={measureWhiteIcon}
          style={{ width: isMobile ? 11 : 18 }}
          alt=""
        />
        <Title fontSize={isMobile ? 14 : 18} color={colors.textWhite}>
          {t("measure").toUpperCase()}
        </Title>
      </Stack>
      <Stack
        style={{
          width: "100%",
          height: isMobile ? 135 : 149,
          boxShadow: "0px 0px 24px 0px rgba(147, 160, 167, 0.16)",
          borderRadius: 10,
          backgroundColor: colors.backgroundWhite,
          position: "relative",
          paddingTop: isMobile ? 10 : 20,
          paddingLeft: isMobile ? 14 : 30,
          gap: isMobile ? 0 : 8,
        }}
      >
        <Title
          fontSize={isMobile ? 14 : 26}
          style={{ width: isMobile ? 230 : 350, marginTop: isMobile ? 4 : 0 }}
        >
          {mission.title}
        </Title>
        {mission.satisfied ? (
          <div
            style={{
              width: "auto",
              height: isMobile ? 24 : 30,
              paddingInline: 6,
              borderRadius: 4,
              border: isMobile
                ? "1px solid " + colors.primary
                : "1.5px solid " + colors.primary,
              position: "absolute",
              bottom: isMobile ? 10 : 20,
              right: isMobile ? 14 : 30,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Title fontSize={isMobile ? 14 : 16} color={colors.primary}>
              {t("completed").toUpperCase()}
            </Title>
          </div>
        ) : (
          <ButtonBase
            disableRipple
            disabled={gettingFootprint}
            style={{
              position: "absolute",
              bottom: isMobile ? 10 : 20,
              right: isMobile ? 14 : 30,
            }}
            onClick={() => {
              scrollWindowToTop()
              navigate("/footprint/form")
            }}
            aria-label={t("go_to_survey")}
          >
            <Title
              fontSize={isMobile ? 14 : 16}
              color={colors.primary}
              style={{
                textDecoration: "underline",
                opacity: gettingFootprint ? 0.5 : 1,
                transition: "200ms",
              }}
            >
              {t("go_to_survey")}
            </Title>
          </ButtonBase>
        )}
        <PointsBadge
          points={mission.points}
          style={{
            position: "absolute",
            top: isMobile ? 10 : 20,
            right: isMobile ? 14 : 30,
          }}
        />
      </Stack>
    </Stack>
  )
}

export default FootprintMissionCard
