import { ButtonBase, Grid, Skeleton, Stack } from "@mui/material"
import { colors } from "../../services/config/colors"
import BreadcrumbDesktop from "../global/desktop/BreadcrumbDesktop"
import Title from "../global/common/Title"
import Text from "../global/common/Text"
import AppearFromSide from "../animations/AppearFromSide"
import { Dispatch, SetStateAction, useContext, useRef, useState } from "react"
import { ActionsContext } from "../../controllers/actions"
import days from "../../services/config/days"
import months from "../../services/config/months"
import ActionMissionCard from "./common/ActionMissionCard"
import chevronRightCircularIcon from "../../assets/icons/chevron-right-circular.svg"
import chevronLeftCircularIcon from "../../assets/icons/chevron-left-circular.svg"
import { AnimatePresence, motion } from "framer-motion"
import Button from "../global/common/Button"
import actionsListBackgroundImage from "../../assets/images/actions-list-background.png"
import Action from "../../models/action"
import ActionCardHorizontal from "./common/ActionCardHorizontal"
import FadeFromTop from "../animations/FadeFromTop"
import {
  desktopColumnsGap,
  desktopActionsLogButtonMaxWidth,
  desktopMaxWidth,
  desktopPadding,
  footerHeight,
  desktopActionsLogButtonWidth,
  maximumActionsPerTime,
} from "../../services/config/constants"
import Calendar from "./common/Calendar"
import Alert from "../global/common/Alert"
import ActionsLoggedFeedbackMobile from "../feedbacks/mobile/ActionsLoggedFeedbackMobile"
import ActionHistoryCard from "./common/ActionHistoryCard"
import HistoryGridSkeletonDesktop from "./desktop/HistoryGridSkeletonDesktop"
import { scrollWindowToTop } from "../../services/utils/utils"
import { useNavigate } from "react-router-dom"
import PaddingContainerDesktop from "../global/desktop/PaddingContainerDesktop"
import { useTranslation } from "react-i18next"
import { MainContext } from "../../controllers/main"

const ActionsDesktop = ({
  noActionsAlertOpen,
  setNoActionsAlertOpen,
  isCurrentDate,
  loading,
  setLoading,
  actionsMissions,
}: {
  noActionsAlertOpen: boolean
  setNoActionsAlertOpen: Dispatch<SetStateAction<boolean>>
  isCurrentDate: boolean
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  actionsMissions: {
    title: string
    progress: string
    points: number
    satisfied: boolean
    onClick: () => void
  }[]
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { lang } = useContext(MainContext)
  const {
    currentDate,
    actions,
    selectedActions,
    logActions,
    actionsLoggedFeedbackOpen,
    setActionsLoggedFeedbackOpen,
    actionsLoggedFeedbackPlural,
    currentHistory,
    currentDetailedHistory,
    setSelectedActions,
    actionsPerTimeLimitAlertOpen,
    setActionsPerTimeLimitAlertOpen,
  } = useContext(ActionsContext)

  // selected action missiion for carousel
  const [selectedMission, setSelectedMission] = useState<number>(0)
  const canGoForward = useRef<boolean>(true)
  const canGoBackward = useRef<boolean>(true)

  // group function for actions list
  // https://stackoverflow.com/questions/54895665/how-could-i-use-map-to-render-a-list-of-items-but-grouped-at-every-3-items
  const group = (items: any[], n: number) =>
    items.reduce((acc, x, i) => {
      const idx = Math.floor(i / n)
      acc[idx] = [...(acc[idx] || []), x]
      return acc
    }, [])

  // debounce for log button
  const canClickLogButton = useRef<boolean>(true)

  // should the content of ActionMissionCard be announced
  const [shouldAriaLiveMissionCard, setShouldAriaLiveMissionCard] =
    useState<boolean>(false)

  return (
    <Stack
      style={{
        width: "100%",
        minHeight: `calc(100% - ${footerHeight}px)`,
        backgroundColor: "#F1F7FE",
      }}
    >
      <Stack style={{ width: "100%", backgroundColor: colors.backgroundWhite }}>
        {/* navbar background */}
        <div
          style={{
            width: "100%",
            height: 177,
            backgroundColor: colors.background,
          }}
        />
        {/* breadcrumb */}
        <BreadcrumbDesktop
          items={[{ label: t("actions") }]}
          selectedItem={0}
          style={{ marginTop: 22 }}
        />
        {/* main container */}
        <PaddingContainerDesktop>
          <Stack
            style={{
              width: "100%",
              maxWidth: desktopMaxWidth,
            }}
          >
            {/* header */}
            <AppearFromSide fade>
              <Title
                fontSize={36}
                lineHeight="46px"
                style={{ marginTop: 22 }}
                component="h1"
              >
                {t("actions")}
              </Title>
            </AppearFromSide>
            <AppearFromSide fade delay={0.05}>
              <Text
                fontSize={28}
                lineHeight="46px"
                fontWeight={400}
                style={{ marginTop: 8 }}
              >
                {t("log_your_actions")}
              </Text>
            </AppearFromSide>
            {/* current date */}
            <AppearFromSide
              fade
              delay={0.07}
              x={100}
              style={{
                width: "100%%",
                height: 46,
                marginTop: -10,
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  position: "relative",
                }}
              >
                <AnimatePresence>
                  <motion.div
                    key={currentDate.toLocaleDateString()}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{
                      opacity: { duration: 0.1 },
                    }}
                    style={{ position: "absolute", right: 0 }}
                  >
                    <Text
                      fontSize={20}
                      lineHeight="46px"
                      fontWeight={700}
                      color={colors.primary}
                    >
                      {`${
                        currentDate.toLocaleDateString() ===
                        new Date().toLocaleDateString()
                          ? t("today")
                          : t(
                              days[
                                currentDate.getDay() === 0
                                  ? 6
                                  : currentDate.getDay() - 1
                              ]
                            )
                      }, ${
                        lang === "en"
                          ? t(months[currentDate.getMonth()]).toLowerCase()
                          : currentDate.getDate()
                      } ${
                        lang === "en"
                          ? currentDate.getDate()
                          : t(months[currentDate.getMonth()]).toLowerCase()
                      }`}
                    </Text>
                  </motion.div>
                </AnimatePresence>
              </div>
            </AppearFromSide>
            {/* actions missions and calendar */}
            <Stack
              direction="row"
              style={{
                width: "100%",
                height: 356,
                marginTop: 18,
                gap: desktopColumnsGap,
              }}
            >
              {/* actions missions carousel */}
              <div
                style={{ width: "65.71%", minWidth: "65.71%", height: "100%" }}
                role="region"
                aria-roledescription={t("carousel")}
                aria-label={t("act_type_missions")}
              >
                <AppearFromSide
                  fade
                  delay={0.1}
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "relative",
                    display: "flex",
                    flexDirection: "column-reverse",
                    gap: 12,
                  }}
                >
                  {/* actions missions carousel controls */}
                  {actionsMissions.length ? (
                    <AppearFromSide
                      fade
                      delay={0.1}
                      style={{
                        width: "100%",
                        height: 51,
                      }}
                    >
                      <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        style={{
                          width: "100%",
                          height: "100%",
                          gap: 35,
                        }}
                        role="group"
                        aria-label={t("carousel_controls")}
                      >
                        <ButtonBase
                          style={{ width: 51, height: 51, borderRadius: 30 }}
                          onClick={() => {
                            if (canGoBackward.current) {
                              setShouldAriaLiveMissionCard(true)

                              if (selectedMission !== 0) {
                                setSelectedMission((current) => (current -= 1))
                              } else {
                                setSelectedMission(actionsMissions.length - 1)
                              }

                              canGoBackward.current = false
                              const timeoutId = setTimeout(() => {
                                canGoBackward.current = true
                                clearTimeout(timeoutId)
                              }, 200)
                            }
                          }}
                          aria-label={t("previous_slide")}
                        >
                          <img
                            src={chevronLeftCircularIcon}
                            style={{ height: 51 }}
                            alt=""
                          />
                        </ButtonBase>
                        <Stack
                          direction="row"
                          alignItems="center"
                          style={{ height: 17, gap: 9 }}
                        >
                          {actionsMissions.map((mission, index) => (
                            <ButtonBase
                              key={index}
                              style={{
                                width: selectedMission === index ? 22 : 15,
                                height: selectedMission === index ? 17 : 11.6,
                                backgroundColor:
                                  selectedMission === index
                                    ? colors.primary
                                    : colors.disabled,
                                borderRadius: 5,
                                transition: "200ms",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                              onClick={() => {
                                setShouldAriaLiveMissionCard(true)
                                setSelectedMission(index)
                              }}
                              aria-label={t("go_to_slide_count_of_total", {
                                count: index + 1,
                                total: actionsMissions.length,
                              })}
                              aria-current={
                                selectedMission === index ? "true" : "false"
                              }
                            >
                              <Text
                                fontSize={16}
                                fontWeight={700}
                                color={colors.textWhite}
                                style={{
                                  opacity: selectedMission === index ? 1 : 0,
                                  transition: "200ms",
                                }}
                              >
                                {index + 1}
                              </Text>
                            </ButtonBase>
                          ))}
                        </Stack>
                        <ButtonBase
                          style={{ width: 51, height: 51, borderRadius: 30 }}
                          onClick={() => {
                            if (canGoForward.current) {
                              setShouldAriaLiveMissionCard(true)

                              if (
                                selectedMission !==
                                actionsMissions.length - 1
                              ) {
                                setSelectedMission((current) => (current += 1))
                              } else {
                                setSelectedMission(0)
                              }

                              canGoForward.current = false
                              const timeoutId = setTimeout(() => {
                                canGoForward.current = true
                                clearTimeout(timeoutId)
                              }, 200)
                            }
                          }}
                          aria-label={t("next_slide")}
                        >
                          <img
                            src={chevronRightCircularIcon}
                            style={{ height: 51 }}
                            alt=""
                          />
                        </ButtonBase>
                      </Stack>
                    </AppearFromSide>
                  ) : null}
                  <div
                    style={{
                      width: "100%",
                      height: 293,
                      position: "relative",
                    }}
                  >
                    <AnimatePresence>
                      <motion.div
                        key={selectedMission}
                        initial={{ scale: 0.8, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        exit={{ scale: 0.8, opacity: 0 }}
                        transition={{
                          scale: { duration: 0.4, type: "spring" },
                          opacity: { duration: 0.1 },
                        }}
                        style={{
                          width: "100%",
                          height: "100%",
                          position: "absolute",
                        }}
                      >
                        {actionsMissions.length ? (
                          <ActionMissionCard
                            mission={actionsMissions[selectedMission]}
                            shouldAriaLive={shouldAriaLiveMissionCard}
                          />
                        ) : null}
                      </motion.div>
                    </AnimatePresence>
                  </div>
                </AppearFromSide>
              </div>
              {/* calendar */}
              <div
                style={{ width: "100%", height: 293 }}
                role="region"
                aria-label={t("calendar")}
              >
                <AppearFromSide
                  x={100}
                  fade
                  delay={0.1}
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <Calendar
                    daysDisabled={loading}
                    setDaysDisabled={setLoading}
                  />
                </AppearFromSide>
              </div>
            </Stack>
            {/* actions list header */}
            <Stack
              direction="row"
              alignItems="center"
              style={{
                width: "100%",
                height: 50,
                position: "relative",
                marginTop: 48,
              }}
            >
              <AppearFromSide fade delay={0.15}>
                <Title fontSize={26} lineHeight="46px" component="h2">
                  {isCurrentDate ? t("featured_actions") : t("logged_actions")}
                </Title>
              </AppearFromSide>
              <AppearFromSide
                fade
                delay={0.15}
                x={100}
                style={{ position: "absolute", right: 0 }}
              >
                {isCurrentDate ? (
                  <Button
                    width={240}
                    outlined
                    onClick={() => {
                      setSelectedActions([])
                      scrollWindowToTop()
                      navigate("/actions/explore")
                    }}
                    title={t("explore_all_actions")}
                  >
                    {t("explore_all_actions")}
                  </Button>
                ) : (
                  <Title
                    key={currentDate.toLocaleDateString()}
                    fontSize={20}
                    lineHeight="42px"
                    color={colors.textSecondary}
                    ariaLive="polite"
                  >
                    {!currentHistory.find(
                      (item) =>
                        new Date(item.date).toLocaleDateString() ===
                        currentDate.toLocaleDateString()
                    )
                      ? t("no_actions")
                      : currentHistory
                          .find(
                            (item) =>
                              new Date(item.date).toLocaleDateString() ===
                              currentDate.toLocaleDateString()
                          )
                          ?.counters.reduce(
                            (accumulator, currentValue) =>
                              accumulator + currentValue.amount,
                            0
                          ) === 1
                      ? t("one_action")
                      : `${currentHistory
                          .find(
                            (item) =>
                              new Date(item.date).toLocaleDateString() ===
                              currentDate.toLocaleDateString()
                          )
                          ?.counters.reduce(
                            (accumulator, currentValue) =>
                              accumulator + currentValue.amount,
                            0
                          )} ${t("total_actions")}`}
                  </Title>
                )}
              </AppearFromSide>
            </Stack>
          </Stack>
        </PaddingContainerDesktop>
        {/* actions list */}
        {isCurrentDate ? (
          <Stack
            style={{
              width: "100%",
              height: 466,
              minHeight: 466,
              backgroundImage: `url(${actionsListBackgroundImage})`,
              backgroundPosition: "top",
              backgroundSize: "cover",
              marginTop: 38,
            }}
          >
            <PaddingContainerDesktop>
              <FadeFromTop
                delay={loading ? 0 : 0.15}
                style={{
                  width: "100%",
                  maxWidth: desktopMaxWidth,
                }}
              >
                <Stack
                  direction="row"
                  style={{ width: "100%", height: 280, gap: desktopColumnsGap }}
                >
                  {group(actions.slice(0, 9), 3).map(
                    (item: Action[], index: number) => (
                      <Stack
                        key={index}
                        style={{
                          width: "100%",
                          height: "100%",
                          gap: 8,
                        }}
                      >
                        {loading
                          ? item.map((action, index) => (
                              <Skeleton
                                key={index}
                                variant="rectangular"
                                animation="wave"
                                style={{
                                  width: "100%",
                                  minWidth: "100%",
                                  minHeight: 88,
                                  height: 88,
                                  borderRadius: 10,
                                }}
                              />
                            ))
                          : item.map((action) => (
                              <ActionCardHorizontal
                                key={action.id}
                                action={action}
                                width="100%"
                              />
                            ))}
                      </Stack>
                    )
                  )}
                </Stack>
              </FadeFromTop>
            </PaddingContainerDesktop>
            <FadeFromTop
              delay={loading ? 0.05 : 0.25}
              style={{
                marginTop: 60,
                alignSelf: "center",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                width={desktopActionsLogButtonWidth}
                style={{ maxWidth: desktopActionsLogButtonMaxWidth }}
                disabled={loading}
                onClick={() => {
                  if (!selectedActions.length) {
                    setNoActionsAlertOpen(true)
                    return
                  }

                  if (canClickLogButton && canClickLogButton.current) {
                    logActions()

                    canClickLogButton.current = false
                    setTimeout(() => {
                      canClickLogButton.current = true
                    }, 800)
                  }
                }}
                title={
                  selectedActions.length === 0
                    ? t("log")
                    : selectedActions.length === 1
                    ? t("log_one_action")
                    : t("log_count_actions", { count: selectedActions.length })
                }
              >
                {selectedActions.length === 0
                  ? t("log")
                  : selectedActions.length === 1
                  ? t("log_one_action")
                  : t("log_count_actions", { count: selectedActions.length })}
              </Button>
            </FadeFromTop>
          </Stack>
        ) : !currentHistory.find(
            (item) =>
              new Date(item.date).toLocaleDateString() ===
              currentDate.toLocaleDateString()
          ) ? (
          <Stack
            style={{
              width: "100%",
              height: 466,
              minHeight: 466,
              backgroundImage: `url(${actionsListBackgroundImage})`,
              backgroundPosition: "top",
              backgroundSize: "cover",
              marginTop: 38,
            }}
          />
        ) : loading ? (
          <HistoryGridSkeletonDesktop />
        ) : (
          <Stack
            alignItems="center"
            style={{
              width: "100%",
              minHeight: 466,
              marginTop: 38,
              paddingInline: desktopPadding,
              paddingBottom: 68,
              backgroundImage: `url(${actionsListBackgroundImage})`,
              backgroundPosition: "top",
              backgroundSize: "cover",
            }}
          >
            <Grid
              container
              columns={3}
              columnSpacing={`${desktopColumnsGap}px`}
              rowSpacing="8px"
              style={{
                width: `calc(100% + ${desktopColumnsGap}px)`,
                maxWidth: `calc(${desktopMaxWidth}px + ${desktopColumnsGap}px)`,
              }}
            >
              {currentDetailedHistory.map((item, index) => (
                <Grid key={index} item xs={1}>
                  <ActionHistoryCard
                    index={index}
                    action={item.action}
                    hour={new Date(item.createdAt).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  />
                </Grid>
              ))}
            </Grid>
          </Stack>
        )}
      </Stack>
      {/* alerts and feedbacks */}
      <ActionsLoggedFeedbackMobile
        open={actionsLoggedFeedbackOpen}
        setOpen={setActionsLoggedFeedbackOpen}
        plural={actionsLoggedFeedbackPlural}
      />
      <Alert
        open={noActionsAlertOpen}
        title={t("no_actions_selected")}
        description={t("select_action_to_log")}
        primaryActionLabel={t("ok")}
        primaryActionOnClick={() => {
          setNoActionsAlertOpen(false)
        }}
      />
      <Alert
        open={actionsPerTimeLimitAlertOpen}
        title={t("warning")}
        description={t("actions_limit", { count: maximumActionsPerTime })}
        primaryActionLabel={t("i_understand")}
        primaryActionOnClick={() => {
          setActionsPerTimeLimitAlertOpen(false)
        }}
      />
    </Stack>
  )
}

export default ActionsDesktop
