import { useContext, useEffect } from "react"
import OnboardingPostSignInMobile from "../../components/onboarding/OnboardingPostSignInMobile"
import { MainContext } from "../../controllers/main"
import OnboardingPostSignInDesktop from "../../components/onboarding/OnboardingPostSignInDesktop"
import { registerPageEvent } from "../../services/utils/utils"

const OnboardingPostSignIn = () => {
  const { isMobile } = useContext(MainContext)

  // register google analytics event
  useEffect(() => {
    registerPageEvent("onboarding_post_signin")
  }, [])

  return isMobile ? (
    <OnboardingPostSignInMobile />
  ) : (
    <OnboardingPostSignInDesktop />
  )
}

export default OnboardingPostSignIn
