import FadeFromTop from "../../components/animations/FadeFromTop"
import Center from "../../components/global/common/Center"
import Text from "../../components/global/common/Text"
import { colors } from "../../services/config/colors"
import awLogoShort from "../../assets/images/aw-logo-short.svg"
import { Stack } from "@mui/material"
import closeCircleRedIcon from "../../assets/icons/close-circle-red.png"
import checkCircleGreenIcon from "../../assets/icons/check-circle-green.png"
import { useContext, useEffect } from "react"
import { MainContext } from "../../controllers/main"
import { useTranslation } from "react-i18next"
import { registerPageEvent } from "../../services/utils/utils"

const Error = ({
  modules,
}: {
  modules: { name: string; error: boolean }[]
}) => {
  const { t } = useTranslation()
  const { isMobile } = useContext(MainContext)

  // register google analytics event
  useEffect(() => {
    registerPageEvent("error")
  }, [])

  return (
    <Center
      style={{ backgroundColor: colors.backgroundWhite, paddingInline: 16 }}
    >
      <FadeFromTop>
        <img
          src={awLogoShort}
          style={{ width: isMobile ? 40 : 48 }}
          alt={t("aworld_logo_alt")}
        />
      </FadeFromTop>
      <FadeFromTop delay={0.025} style={{ marginTop: 36 }}>
        <Text fontSize={isMobile ? 20 : 22} fontWeight={600} textAlign="center">
          {t("error_title")}
        </Text>
      </FadeFromTop>
      <FadeFromTop delay={0.05} style={{ marginTop: 32 }}>
        <Text fontSize={isMobile ? 18 : 20} fontWeight={500} textAlign="center">
          {t("error_details")}
        </Text>
      </FadeFromTop>
      <Stack style={{ marginTop: 32, gap: 8 }}>
        {modules
          .filter((module) => module.error)
          .map((module, index) => (
            <FadeFromTop key={index} delay={0.025 * (index + 3)}>
              <Stack
                direction="row"
                alignItems="center"
                style={{
                  minWidth: 180,
                  height: isMobile ? 26 : 30,
                  backgroundColor: module.error ? colors.error : colors.success,
                  borderRadius: 6,
                  paddingInline: 8,
                  gap: 8,
                }}
              >
                <img
                  src={module.error ? closeCircleRedIcon : checkCircleGreenIcon}
                  style={{ height: isMobile ? 14 : 16 }}
                  alt={t("error")}
                />
                <Text
                  fontSize={isMobile ? 16 : 18}
                  fontWeight={500}
                  color={colors.textWhite}
                >
                  {module.name}
                </Text>
              </Stack>
            </FadeFromTop>
          ))}
      </Stack>
    </Center>
  )
}

export default Error
