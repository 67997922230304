import { ButtonBase, Grid } from "@mui/material"
import { Dispatch, SetStateAction, useContext } from "react"
import { ActionsContext } from "../../../controllers/actions"
import { colors } from "../../../services/config/colors"
import Text from "../../global/common/Text"
import months from "../../../services/config/months"
import { useTranslation } from "react-i18next"

const CalendarDay = ({
  day,
  disabled,
  setDisabled,
  setDialogOpen,
}: {
  day: Date | string
  disabled: boolean
  setDisabled: Dispatch<SetStateAction<boolean>>
  setDialogOpen?: Dispatch<SetStateAction<boolean>>
}) => {
  const { t } = useTranslation()
  const {
    currentDate,
    currentHistory,
    setCurrentDate,
    setSelectedActions,
    getActionsDetailedHistory,
  } = useContext(ActionsContext)

  // if it is current date or not
  const isCurrentDate =
    typeof day !== "string" &&
    currentDate.toLocaleDateString() === day.toLocaleDateString()

  // if it is current day or not
  const isCurrentDay =
    typeof day !== "string" &&
    day.toLocaleDateString() === new Date().toLocaleDateString()

  // if an action has been logged in day or not
  const actionLogged =
    typeof day !== "string" &&
    currentHistory.find(
      (item) =>
        new Date(item.date).toLocaleDateString() === day.toLocaleDateString()
    )

  return (
    <Grid
      item
      xs={1}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ButtonBase
        style={{
          width: 32,
          height: 32,
          borderRadius: 20,
          position: "relative",
        }}
        disabled={
          typeof day === "string" ||
          (typeof day !== "string" && day.getTime() > new Date().getTime())
            ? true
            : false
        }
        onClick={async () => {
          if (!disabled) {
            setSelectedActions([])
            setCurrentDate(day as Date)

            if (setDialogOpen) {
              setDialogOpen(false)
            }

            // get detailed history for selected day but only if there is not empty
            if (
              currentHistory.find(
                (item) =>
                  new Date(item.date).toLocaleDateString() ===
                  (day as Date).toLocaleDateString()
              )
            ) {
              setDisabled(true)

              await getActionsDetailedHistory(day as Date)

              setDisabled(false)
            }
          }
        }}
        aria-label={
          typeof day === "string"
            ? ""
            : isCurrentDay
            ? `${t("today")}, ${day.getDate()} ${t(months[day.getMonth()])}`
            : isCurrentDate
            ? `${day.getDate()} ${t(months[day.getMonth()])}`
            : t("go_to_specific_day", {
                day: day.getDate(),
                month: months[day.getMonth()],
              })
        }
        role="radio"
        aria-checked={isCurrentDate ? "true" : "false"}
      >
        {/* primary color circle if the day is selected */}
        <div
          style={{
            width: 33,
            height: 33,
            borderRadius: 20,
            position: "absolute",
            opacity: isCurrentDate ? 1 : 0,
            backgroundColor: colors.primary,
            transition: "100ms",
          }}
        />
        <Text
          fontSize={16}
          fontWeight={400}
          color={
            isCurrentDate
              ? colors.textWhite
              : typeof day !== "string" && day.getTime() > new Date().getTime()
              ? colors.textSecondary
              : colors.text
          }
          style={{ transition: "100ms", position: "relative", marginTop: -6 }}
        >
          {typeof day === "string" ? null : day.getDate()}
        </Text>
        {actionLogged && (
          <div
            style={{
              width: 6,
              height: 6,
              borderRadius: 3,
              backgroundColor: isCurrentDate
                ? colors.backgroundWhite
                : colors.primary,
              position: "absolute",
              bottom: 3,
              transition: "100ms",
            }}
          />
        )}
        {/* </div> */}
      </ButtonBase>
    </Grid>
  )
}

export default CalendarDay
