export const privacyPolicyEn = `<html>
<head>
  <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
</head>
<body class="c3 c20 doc-content">
  <p class="c3 c18">
    <span class="c14 c21"
      >Privacy notice pursuant to Article 13 of Regulation (EU) 2016/679
      (&ldquo;GDPR&rdquo;) and the Privacy Code</span
    >
  </p>
  <p class="c1 c3"><span class="c14 c22"></span></p>
  <ul class="c2 lst-kix_list_2-0 start">
    <li class="c11 c3 li-bullet-0">
      <span class="c5">Data controller</span>
    </li>
  </ul>
  <p class="c6 c3">
    <span class="c8"
      >Enel S.p.A. with registered office at Viale Regina Margherita n. 137,
      VAT No. 15844561009</span
    ><span class="c8 c15">&nbsp;</span><span class="c8">(hereinafter </span
    ><span class="c14">&ldquo;Enel&rdquo; </span
    ><span class="c8">or the &quot;</span><span class="c14">Controller</span
    ><span class="c0"
      >&rdquo;), as the Data Controller, will process your Personal Data in
      accordance with the provisions of the applicable Personal Data
      protection legislation and this notice.</span
    >
  </p>
  <p class="c1 c3"><span class="c0"></span></p>
  <ul class="c2 lst-kix_list_2-0">
    <li class="c23 li-bullet-0">
      <span class="c5">Data Protection Officer (DPO)</span>
    </li>
  </ul>
  <p class="c6 c3">
    <span class="c0"
      >The Controller has appointed a DPO who can be contacted at the
      following email address dpoenelhr@enel.com</span
    >
  </p>
  <p class="c1 c3"><span class="c0"></span></p>
  <ul class="c2 lst-kix_list_2-0">
    <li class="c23 li-bullet-0">
      <span class="c5">Processing Purpose and Method </span>
    </li>
  </ul>
  <p class="c6 c3">
    <span class="c8"
      >The Controller will process your Personal Data (such as: </span
    ><span class="c14 c15"
      >first name, last name, e-mail, personal data related to life habits
      and/or consumption preferences processed for profiling
      purposes..etc</span
    ><span class="c8">) &ldquo;</span><span class="c14">Personal Data</span
    ><span class="c8"
      >&rdquo; legitimately found and communicated by you to enable you to use
      Aworld, the innovative platform integrated in ME&#39;s services that
      aims to spread a culture of sustainability and create shared value.
      Through AWORLD, You will be able to take advantage of several services,
      such as: </span
    ><span class="c8 c15"
      >carbon footprint calculator; cross-cutting training content on the 17
      SDGs (Sustainable Development Goals); suggestions of virtuous actions
      with indication of savings achieved in terms of CO2, water and energy;
      challenges to engage the community, setting key targets to be achieved
      in terms of positive actions generated, values of water, CO2 and energy
      saved; training content and recording of virtuous actions; positive
      impact achieved in terms of personal savings</span
    ><span class="c0">.</span>
  </p>
  <p class="c1 c3"><span class="c0"></span></p>
  <p class="c6 c3">
    <span class="c0"
      >Qualitative and quantitative data about platform usage, training hours,
      and Sustainable Development Goals (SDGs) will be made available and
      processed by Enel in aggregate form.
    </span>
  </p>
  <p class="c1 c3"><span class="c0"></span></p>
  <p class="c6">
    <span class="c0"
      >For the purposes of this notice, the processing of Personal Data means
      any operation or series of operations carried out on Personal Data,
      whether or not by automated means, such as collection, recording,
      organisation, structuring, storage, adaptation or alteration, retrieval,
      consultation, use, disclosure by transmission, dissemination or
      otherwise making available, alignment or combination, restriction,
      erasure or destruction.</span
    >
  </p>
  <p class="c1"><span class="c0"></span></p>
  <p class="c6">
    <span class="c0"
      >Please note that such Personal Data will be processed manually and/or
      with the support of IT or data transmission devices.</span
    >
  </p>
  <p class="c1"><span class="c0"></span></p>
  <p class="c6">
    <span class="c0"
      >The Controller has implemented appropriate technical and organisational
      measures to ensure a level of security appropriate to the risk, in
      accordance with Article 32 of the GDPR, aimed at preventing and
      counteracting the loss of Personal Data, as well as unlawful or
      incorrect use and unauthorised access.</span
    >
  </p>
  <p class="c1 c3"><span class="c0"></span></p>
  <ul class="c2 lst-kix_list_2-0">
    <li class="c11 li-bullet-0">
      <span class="c5">Processing purpose and legal basis</span>
    </li>
  </ul>
  <p class="c6 c3">
    <span class="c8"
      >Enel will process your Personal Data obtained for the purpose related
      to the use of Aworld, the innovative platform integrated in ME&#39;s
      services that aims to spread a culture of sustainability and create
      shared value.</span
    >
  </p>
  <p class="c1 c3"><span class="c12 c8"></span></p>
  <p class="c6 c3">
    <span class="c0"
      >The legal basis for the processing is the consent given at the botton
      of the platform.</span
    >
  </p>
  <p class="c1 c3"><span class="c0"></span></p>
  <p class="c6 c3">
    <span class="c0"
      >Consent can be withdrawn at any time at the Profile section of the
      platform and the withdrawal of the consent will result in the deletion
      of the account and the anonymization of qualitative and quantitative
      data about the use of the platform, the training and information content
      used and the SDGs processed.</span
    >
  </p>
  <p class="c1 c3"><span class="c0"></span></p>
  <ul class="c2 lst-kix_list_2-0">
    <li class="c23 li-bullet-0">
      <span class="c5">Recipients of Personal Data</span>
    </li>
  </ul>
  <p class="c4">
    <span class="c7"
      >Your Personal Data may be made accessible for the purposes mentioned
      above:</span
    >
  </p>
  <p class="c16">
    <span class="c7"
      >a) to employees and contractors of the Controller or to Enel Group
      companies present in the European Union;
    </span>
  </p>
  <p class="c4">
    <span class="c7">b) to third parties or other persons (&ldquo;</span
    ><span class="c5">Third Parties</span
    ><span class="c7"
      >&rdquo;) who carry out outsourcing activities on behalf of the
      Controller (such as the Company Aworld S.r.l. Societ&agrave; Benefit) in
      their capacity as external data processors, to natural persons in charge
      of processing.</span
    >
  </p>
  <p class="c4">
    <span class="c7"
      >The Company Aworld S.r.l. Benefit Company will periodically provide
      Enel a report with aggregated qualitative and quantitative data about
      the use of the platform, the training and information content used, and
      the SDGs covered.</span
    >
  </p>
  <ul class="c2 lst-kix_list_2-0">
    <li class="c11 c3 li-bullet-0">
      <span class="c5">Transfer of Personal Data </span>
    </li>
  </ul>
  <p class="c6 c3">
    <span class="c0"
      >Your Personal Data will be processed within the European Union and
      stored on servers located within the European Union. The same data may
      be processed in countries outside the European Union, provided that an
      adequate level of protection is guaranteed, recognized by a specific
      adequacy decision of the European Commission.</span
    >
  </p>
  <p class="c6 c3">
    <span class="c0"
      >Any transfers of Personal Data to non-EU countries, in the absence of a
      European Commission adequacy decision, will only be possible if Data
      Controllers and Data Processors involved provide adequate guarantees of
      contractual nature, including Binding Corporate Rules and Standard
      Contractual Clauses.</span
    >
  </p>
  <p class="c3 c6">
    <span class="c0"
      >The transfer of your Personal Data to third countries outside the
      European Union, in the absence of an adequacy decision or other
      appropriate measures as described above, will be made only if you have
      explicitly consented to it or in the cases provided for by the GDPR and
      will be processed in your interest. In these cases, we inform you that,
      although the Enel Group adopts operating instructions common to all the
      countries in which it operates, the transfer of your Personal Data may
      be exposed to risks related to the peculiarities of local legislation
      regarding the processing of Personal Data.</span
    >
  </p>
  <p class="c3 c10"><span class="c0"></span></p>
  <ul class="c2 lst-kix_list_2-0">
    <li class="c3 c11 li-bullet-0">
      <span class="c5">Period of retention of Personal Data </span>
    </li>
  </ul>
  <p class="c6 c3">
    <span class="c0"
      >The Personal Data being processed for the abovementioned purposes will
      be retained in compliance with the principles of proportionality and
      necessity and, in any case, until the purposes of the processing have
      been achieved. In any case no later than 6 months after the termination
      of the contract between Enel and Aworld.</span
    >
  </p>
  <p class="c10 c3"><span class="c7 c3"></span></p>
  <ul class="c2 lst-kix_list_2-0">
    <li class="c11 c3 li-bullet-0">
      <span class="c5">Data subject rights </span>
    </li>
  </ul>
  <p class="c6 c3">
    <span class="c0"
      >Pursuant to Articles 15 to 21 of the GDPR, in relation to the Personal
      Data provided, you have the right to:
    </span>
  </p>
  <ol class="c2 lst-kix_list_1-0 start" start="1">
    <li class="c13 li-bullet-1">
      <span class="c7">access them and request a copy;</span>
    </li>
    <li class="c13 li-bullet-1">
      <span class="c7">request amendment;</span>
    </li>
    <li class="c13 li-bullet-1">
      <span class="c7">request cancellation;</span>
    </li>
    <li class="c13 li-bullet-2">
      <span class="c7">obtain processing limitation;</span>
    </li>
    <li class="c13 li-bullet-1">
      <span class="c7">oppose processing; </span>
    </li>
    <li class="c13 li-bullet-1">
      <span class="c7"
        >receive your data in a structured, commonly used and machine-readable
        format
      </span>
    </li>
  </ol>
  <p class="c3 c19"><span class="c8 c9"></span></p>
  <p class="c3 c17">
    <span class="c8"
      >For more information relating to your Personal Data, please contact
      Enel&rsquo;s Data Protection Officer at the following email address, and
      you must indicate in the subject line </span
    ><span class="c8 c15">dpoenelhr@enel.com</span>
  </p>
  <p class="c10 c3"><span class="c0"></span></p>
  <p class="c17 c3">
    <span class="c0"
      >You also have the right to submit a complaint to the Italian Data
      Protection Authority, by one of the following procedures:</span
    >
  </p>
  <ul class="c2 lst-kix_list_2-0">
    <li class="c11 c3 li-bullet-0">
      <span class="c7"
        >hand delivery to the offices of the Italian Data Protection Authority
        (at the address indicated below);
      </span>
    </li>
    <li class="c11 c3 li-bullet-0">
      <span class="c7"
        >registered mail with return receipt addressed to: Garante per la
        protezione dei dati personali, Piazza Venezia, 11 - 00187 Roma;</span
      >
    </li>
    <li class="c11 c3 li-bullet-0">
      <span class="c7"
        >certified electronic mail addressed to: protocollo@pec.gpdp.it.</span
      >
    </li>
  </ul>
  <p class="c10 c3"><span class="c8 c12"></span></p>
  <p class="c1 c3"><span class="c0"></span></p>
  <p class="c1 c3"><span class="c0"></span></p>
</body>
</html>
`
export const privacyPolicyIt = `<html>
<head>
  <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
</head>
<body class="c11 c23 doc-content">
  <p class="c11 c21 c25">
    <span class="c1 c28"
      >Informativa privacy ai sensi dell&rsquo;art. 13 del Regolamento UE
      2016/679 (&ldquo;GDPR&rdquo;) e del Codice della Privacy</span
    >
  </p>
  <p class="c7 c10"><span class="c1 c29"></span></p>
  <ul class="c16 lst-kix_list_5-0 start">
    <li class="c4 li-bullet-0">
      <span class="c3 c1">Titolare del trattamento dei Dati Personali</span>
    </li>
  </ul>
  <p class="c18">
    <span class="c6"
      >Enel S.p.A, con sede legale in Roma, Viale Regina Margherita n. 137,
      Partita IVA 15844561009 (di seguito, &ldquo;</span
    ><span class="c1">Enel</span><span class="c6">&rdquo; o il &ldquo;</span
    ><span class="c1">Titolare</span
    ><span class="c6"
      >&rdquo;), in qualit&agrave; di Titolare del trattamento,
      tratter&agrave; i Suoi dati personali in conformit&agrave; al
      Regolamento UE 2016/679 (&ldquo;</span
    ><span class="c1">GDPR</span
    ><span class="c5"
      >&rdquo;), alla normativa vigente in materia di protezione dei dati
      personali e alla presente informativa.</span
    >
  </p>
  <ul class="c16 lst-kix_list_7-0 start">
    <li class="c19 li-bullet-0">
      <span class="c3 c1"
        >Responsabile della Protezione dei dati personali (RPD)</span
      >
    </li>
  </ul>
  <p class="c7">
    <span class="c5"
      >Il Titolare ha nominato un RPD che pu&ograve; essere contatto al
      seguente indirizzo e-mail dpoenelhr@enel.com</span
    >
  </p>
  <p class="c7 c10"><span class="c5"></span></p>
  <ul class="c16 lst-kix_list_8-0 start">
    <li class="c19 li-bullet-0">
      <span class="c3 c1"
        >&nbsp;Oggetto e Modalit&agrave; del Trattamento
      </span>
    </li>
  </ul>
  <p class="c18">
    <span class="c6">Il Titolare tratter&agrave; i Suoi dati personali </span
    ><span class="c1">(</span><span class="c6">come ad esempio:</span
    ><span class="c1">&nbsp;</span
    ><span class="c1 c20"
      >nome, cognome, e-mail, dati personali che rilevano abitudini di vita
      quotidiana e/o preferenze di consumo trattati per finalit&agrave; di
      profilazione..etc</span
    ><span class="c1">)</span
    ><span class="c6"
      >&nbsp;legittimamente reperiti e da Lei comunicati per consentirLe
      l&rsquo;utilizzo di Aworld, la piattaforma innovativa integrata nei
      servizi di ME che ha l&rsquo;obiettivo di diffondere una cultura della
      sostenibilit&agrave; e creare valore condiviso. Attraverso AWORLD, Lei
      potr&agrave; usufruire di diversi servizi, come ad esempio: </span
    ><span class="c6 c20"
      >carbon footprint calculator; contenuti formativi trasversali sui 17 SDG
      (Sustainable Development Goals); suggerimenti di azioni virtuose con
      indicazione di risparmio ottenuti in termini di CO2, acqua e energia;
      challenges per coinvolgere la community, fissando obiettivi chiave da
      raggiungere in termini di azioni positive generate, valori di acqua, CO2
      e energia risparmiati; contenuti formativi e la registrazione di azioni
      virtuose; impatto positivo raggiunto in termini di risparmi
      personali</span
    ><span class="c5">.</span>
  </p>
  <p class="c7">
    <span class="c6"
      >I dati qualitativi e quantitativi circa l&rsquo;utilizzo della
      piattaforma, le ore di formazione e gli SDG (</span
    ><span class="c6 c20">Sustainable Development Goals</span
    ><span class="c6"
      >) saranno resi disponibili e trattati da Enel in forma aggregata.</span
    >
  </p>
  <p class="c13 c10 c21"><span class="c5"></span></p>
  <p class="c13 c21">
    <span class="c5"
      >Per trattamento di Dati Personali ai fini della presente informativa
      &egrave; da intendersi qualsiasi operazione o insieme di operazioni,
      compiute con o senza &nbsp;l&#39;ausilio di processi automatizzati e
      applicate ai Dati Personali, come &nbsp;la raccolta, la registrazione,
      l&#39;organizzazione, la strutturazione, la conservazione,
      l&#39;adattamento o la modifica, l&#39;estrazione, la consultazione,
      l&#39;uso, la comunicazione mediante trasmissione, diffusione o
      qualsiasi altra forma di messa a disposizione, il raffronto o
      l&#39;interconnessione, la limitazione, la cancellazione o la
      distruzione.</span
    >
  </p>
  <p class="c13 c10 c21"><span class="c5"></span></p>
  <p class="c13 c21">
    <span class="c5"
      >La informiamo che tali Dati Personali verranno trattati manualmente e/o
      con il supporto di mezzi informatici o telematici.</span
    >
  </p>
  <p class="c13 c10 c21"><span class="c5"></span></p>
  <p class="c13 c21">
    <span class="c5"
      >Il Titolare ha adottato misure tecniche ed organizzative atte ad
      assicurare un livello di sicurezza adeguato al rischio, secondo quanto
      previsto dall&rsquo;art. 32 del GDPR, volte a prevenire e contrastare la
      perdita dei Dati Personali, nonch&eacute; usi illeciti o non corretti ed
      accessi non autorizzati.</span
    >
  </p>
  <p class="c7 c10"><span class="c5"></span></p>
  <ul class="c16 lst-kix_list_6-0 start">
    <li class="c12 li-bullet-0">
      <span class="c3 c1"
        >&nbsp;Finalit&agrave; e base giuridica del trattamento</span
      >
    </li>
  </ul>
  <p class="c18">
    <span class="c5"
      >Il Titolare tratter&agrave; i Suoi Dati Personali per le
      finalit&agrave; connesse all&rsquo;utilizzo di Aworld, la piattaforma
      innovativa integrata nei servizi di ME che ha l&rsquo;obiettivo di
      diffondere una cultura della sostenibilit&agrave; e creare valore
      condiviso.</span
    >
  </p>
  <p class="c7">
    <span class="c5"
      >La base giuridica del trattamento &egrave; costituita dal consenso che
      viene espresso dall&rsquo;interessato, spuntando l&rsquo;apposito flag,
      in fase di accesso alla piattaforma per le finalit&agrave; di cui
      sopra.</span
    >
  </p>
  <p class="c7">
    <span class="c6"
      >Il consenso potr&agrave; essere revocato in qualsiasi momento alla
      sezione Profilo della piattaforma e la revoca comporter&agrave;
      l&rsquo;eliminazione dell&rsquo;account e l&rsquo;anonimizzazione dei
      dati qualitativi e quantitativi circa l&rsquo;utilizzo della
      piattaforma, i contenuti di formazione e informazione fruiti e gli SDG
      trattati.
    </span>
  </p>
  <p class="c7 c10"><span class="c5"></span></p>
  <ul class="c16 lst-kix_list_10-0 start">
    <li class="c19 li-bullet-0">
      <span class="c3 c1">&nbsp;Destinatari dei Dati Personali</span>
    </li>
  </ul>
  <p class="c18">
    <span class="c2"
      >I Suoi Dati Personali potranno essere resi accessibili, per le
      finalit&agrave; sopra menzionate:</span
    >
  </p>
  <ol class="c16 lst-kix_list_16-0 start" start="1">
    <li class="c17 li-bullet-1">
      <span class="c2"
        >ai dipendenti e ai collaboratori di Enel, in qualit&agrave; di
        persone autorizzate al trattamento dei dati personali;</span
      >
    </li>
    <li class="c17 li-bullet-2">
      <span class="c2"
        >alle societ&agrave; del Gruppo Enel presenti nel territorio
        dell&rsquo;Unione Europea in qualit&agrave; di titolari autonomi o
        responsabili del trattamento nonch&eacute; ai loro dipendenti e
        collaboratori appositamente nominati Persone Autorizzate al
        trattamento;</span
      >
    </li>
    <li class="c17 li-bullet-2">
      <span class="c2"
        >alle societ&agrave; terze o altri soggetti (&ldquo;</span
      ><span class="c3 c1">Terze Parti</span
      ><span class="c2"
        >&rdquo;) che svolgono alcune attivit&agrave; in outsourcing per conto
        del Titolare, come la Societ&agrave; Aworld S.r.l. Societ&agrave;
        Benefit, nella loro qualit&agrave; di responsabili del trattamento,
        nonch&eacute; ai loro dipendenti e collaboratori appositamente
        nominati Persone Autorizzate al trattamento.</span
      >
    </li>
  </ol>
  <p class="c27 c10"><span class="c2"></span></p>
  <p class="c21 c27">
    <span class="c2"
      >La Societ&agrave; Aworld S.r.l. Societ&agrave; Benefit periodicamente
      fornir&agrave; ad Enel una reportistica con dati qualitativi e
      quantitativi aggregati circa l&rsquo;utilizzo della piattaforma, i
      contenuti di formazione e informazione fruiti, gli SDG trattati.</span
    >
  </p>
  <p class="c10 c22"><span class="c2"></span></p>
  <p class="c18">
    <span class="c2"
      >Ai Responsabili e alle Persone Autorizzate al trattamento,
      eventualmente designati, Enel impartisce istruzioni finalizzate
      all&rsquo;adozione delle misure di sicurezza adeguate, al fine di poter
      garantire la riservatezza, la sicurezza e l&rsquo;integrit&agrave; dei
      dati.</span
    >
  </p>
  <ul class="c16 lst-kix_list_11-0 start">
    <li class="c4 li-bullet-0">
      <span class="c3 c1">Trasferimento dei Dati Personali </span>
    </li>
  </ul>
  <p class="c7">
    <span class="c5"
      >I Suoi Dati Personali saranno trattati all&rsquo;interno
      dell&rsquo;Unione Europea e conservati su server ubicati
      all&rsquo;interno dell&rsquo;Unione Europea. Gli stessi dati potranno
      essere trattati in Paesi al di fuori dell&rsquo;Unione Europea, a
      condizione che sia garantito un livello di protezione adeguato,
      riconosciuto da apposita decisione di adeguatezza della Commissione
      Europea.</span
    >
  </p>
  <p class="c7">
    <span class="c5"
      >Eventuali trasferimenti di Dati Personali verso Paesi non UE, in
      assenza di decisione di adeguatezza della Commissione europea, saranno
      possibili solo qualora siano fornite dai Titolari e Responsabili
      coinvolti garanzie adeguate di natura contrattuale o pattizia, fra cui
      norme vincolanti d&rsquo;impresa (&ldquo;Binding Corporate Rules&rdquo;)
      e clausole contrattuali tipo di protezione dei dati.</span
    >
  </p>
  <p class="c7">
    <span class="c5"
      >Il trasferimento dei Suoi Dati Personali verso Paesi terzi al di fuori
      dell&rsquo;Unione Europea, in mancanza di una decisione di adeguatezza o
      di altre misure adeguate come sopra descritte, saranno effettuati solo
      ove Lei vi abbia esplicitamente acconsentito o nei casi previsti dal
      GDPR e saranno trattati nel Suo interesse. In questi casi, La informiamo
      che, nonostante il Gruppo Enel adotti istruzioni operative comuni a
      tutti i Paesi in cui opera, il trasferimento dei Suoi Dati Personali
      potrebbe essere esposto a rischi connessi alle peculiarit&agrave; delle
      legislazioni locali in materia di trattamento dei Dati Personali.</span
    >
  </p>
  <p class="c7 c10"><span class="c5"></span></p>
  <ul class="c16 lst-kix_list_12-0 start">
    <li class="c4 li-bullet-0">
      <span class="c1 c3">Periodo di conservazione dei Dati Personali </span>
    </li>
  </ul>
  <p class="c7">
    <span class="c5"
      >I Dati Personali oggetto di trattamento per le finalit&agrave; di cui
      sopra saranno conservati nel rispetto dei principi di
      proporzionalit&agrave; e necessit&agrave;, e comunque fino a che non
      siano state perseguite le finalit&agrave; del trattamento e nel rispetto
      degli obblighi di legge eventualmente previsti. In ogni caso entro e non
      oltre 6 mesi dalla cessazione del contratto tra Enel e Aworld.</span
    >
  </p>
  <p class="c13 c10 c11"><span class="c2 c11"></span></p>
  <ul class="c16 lst-kix_list_13-0 start">
    <li class="c4 li-bullet-0">
      <span class="c3 c1">&nbsp;Diritti degli interessati </span>
    </li>
  </ul>
  <p class="c7">
    <span class="c5"
      >Ai sensi degli artt. 15 &ndash; 22 del GDPR, in relazione ai Dati
      personali comunicati, Lei ha il diritto di:
    </span>
  </p>
  <ol class="c16 lst-kix_list_1-0 start" start="1">
    <li class="c13 c15 li-bullet-3">
      <span class="c2">accedere e chiederne copia;</span>
    </li>
    <li class="c13 c15 li-bullet-4">
      <span class="c2">richiedere la rettifica;</span>
    </li>
    <li class="c13 c15 li-bullet-3">
      <span class="c2">richiedere la cancellazione;</span>
    </li>
    <li class="c13 c15 li-bullet-4">
      <span class="c2">ottenere la limitazione del trattamento;</span>
    </li>
    <li class="c13 c15 li-bullet-5">
      <span class="c2">opporsi al trattamento; </span>
    </li>
    <li class="c13 c15 li-bullet-4">
      <span class="c2"
        >ricevere in un formato strutturato, di uso comune e leggibile da
        dispositivo automatico e di trasmettere senza impedimenti tali dati a
        un altro titolare del trattamento; ove tecnicamente fattibile.</span
      >
    </li>
  </ol>
  <p class="c13 c10 c11"><span class="c5"></span></p>
  <p class="c13 c11">
    <span class="c5"
      >Per maggiori informazioni relative ai Suoi Dati Personali potr&agrave;
      rivolgersi al Responsabile per la Protezione dei Dati Personali di Enel,
      raggiungibile al seguente indirizzo e-mail dpoenelhr@enel.com</span
    >
  </p>
  <p class="c7 c10"><span class="c5"></span></p>
  <p class="c13 c11">
    <span class="c5"
      >Le ricordiamo infine che qualora ritenga che il trattamento dei suoi
      dati sia avvenuto in modo non conforme al Regolamento Europeo 679/2016
      (GDPR) &egrave; un Suo diritto proporre un reclamo al Garante per la
      Protezione dei Dati Personali, utilizzando la modalit&agrave; ritenuta
      pi&ugrave; opportuna tra le seguenti:</span
    >
  </p>
  <p class="c10 c11 c13"><span class="c5"></span></p>
  <ol class="c16 lst-kix_list_3-0 start" start="1">
    <li class="c9 li-bullet-0">
      <span class="c2"
        >consegna a mano presso gli uffici del Garante (all&#39;indirizzo di
        seguito indicato);
      </span>
    </li>
    <li class="c9 c11 li-bullet-0">
      <span class="c2"
        >raccomandata A/R indirizzata a: Garante per la protezione dei dati
        personali, Piazza Venezia, 11 - 00187 Roma;
      </span>
    </li>
    <li class="c9 c11 li-bullet-0">
      <span class="c2"
        >messaggio di posta elettronica certificata indirizzata a: </span
      ><span class="c0"
        ><a target="_blank" class="c26" href="mailto:protocollo@pec.gpdp.it"
          >protocollo@pec.gpdp.it</a
        ></span
      ><span class="c2">, oppure </span
      ><span class="c0"
        ><a target="_blank" class="c26" href="mailto:protocollo@gpdp.it"
          >protocollo@gpdp.it</a
        ></span
      >
    </li>
  </ol>
  <p class="c13 c10 c11"><span class="c14 c6"></span></p>
  <p class="c7 c10"><span class="c6 c14"></span></p>
  <p class="c8"><span class="c5"></span></p>
</body>
</html>
`

export const privacyPolicyEs = `
<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c23 c28 doc-content">
    <div>
      <p class="c17 c19"><span class="c10 c25"></span></p>
    </div>
    <p class="c19 c33"><span class="c25 c10"></span></p>
    <p class="c23 c18 c32">
      <span class="c7"
        >Informaci&oacute;n de privacidad conforme al art. 13 del Reglamento UE
        2016/679 (&ldquo;GDPR&rdquo;) y del C&oacute;digo de Privacidad</span
      >
    </p>
    <p class="c8 c12"><span class="c11 c10 c27"></span></p>
    <ul class="c5 lst-kix_list_1-0 start">
      <li class="c6 li-bullet-0">
        <span class="c1">Titular del tratamiento de los Datos Personales</span>
      </li>
    </ul>
    <p class="c18 c31">
      <span class="c14"
        >Enel S.p.A, con sede legal en Roma, Viale Regina Margherita n. 137,
        Partita IVA 15844561009 (en adelante &ldquo;</span
      ><span class="c11">Enel</span><span class="c14">&rdquo; o el &ldquo;</span
      ><span class="c11">Titular</span
      ><span class="c14"
        >&rdquo;), en calidad de Titular del tratamiento, tratar&aacute; sus
        datos personales de conformidad con el Reglamento UE 2016/679
        (&ldquo;</span
      ><span class="c11">RGPD</span
      ><span class="c0"
        >&rdquo;), con la normativa vigente en materia de protecci&oacute;n de
        datos personales y la presente informaci&oacute;n.</span
      >
    </p>
    <ul class="c5 lst-kix_list_3-0 start">
      <li class="c21 li-bullet-0">
        <span class="c1 c10">Responsable de</span><span class="c1">&nbsp;</span
        ><span class="c1 c10">la Prote</span><span class="c1">cc</span
        ><span class="c1 c10">i</span><span class="c1">&oacute;</span
        ><span class="c1 c10">n de dat</span><span class="c1">os</span
        ><span class="c1 c10">&nbsp;personal</span><span class="c1">es</span
        ><span class="c1 c10">&nbsp;(</span><span class="c1">DPO</span
        ><span class="c1 c10">)</span>
      </li>
    </ul>
    <p class="c8 c18">
      <span class="c0"
        >El Titular ha designado un DPO con el que se puede contactar en la
        siguiente direcci&oacute;n de correo electr&oacute;nico:
        dpoenelhr@enel.com</span
      >
    </p>
    <p class="c8 c12"><span class="c0"></span></p>
    <ul class="c5 lst-kix_list_5-0 start">
      <li class="c21 li-bullet-0">
        <span class="c1 c10">&nbsp;</span
        ><span class="c1">Objeto y M&eacute;todo de Tratamiento</span
        ><span class="c1 c10">&nbsp;</span>
      </li>
    </ul>
    <p class="c31 c18">
      <span class="c14">El Titular tratar&aacute; sus datos personales </span
      ><span class="c11">(</span><span class="c14">como por ejemplo:</span
      ><span class="c11">&nbsp;</span
      ><span class="c11 c22"
        >nombre, apellidos, correo electr&oacute;nico, datos personales que
        revelen h&aacute;bitos de vida y/o preferencias de consumo con fines de
        elaboraci&oacute;n de perfiles&hellip; etc.</span
      ><span class="c11">)</span
      ><span class="c14"
        >&nbsp;leg&iacute;timamente obtenidos y comunicados por usted para
        permitirle el uso de Aworld, la plataforma innovadora integrada en los
        servicios ME que tiene como objetivo difundir una cultura de la
        sostenibilidad y crear un valor compartido. A trav&eacute;s de AWORLD,
        usted podr&aacute; aprovechar diversos servicios, como por ejemplo: </span
      ><span class="c14 c22"
        >carbon footprint calculator; contenidos educativos transversales sobre
        los 17 ODS (Objetivos de Desarrollo Sostenible); sugerencias de buenas
        acciones con indicaciones de ahorro obtenidos en t&eacute;rminos de CO2,
        agua y energ&iacute;a; desaf&iacute;os para involucrar a la comunidad,
        fijando objetivos clave a alcanzar en t&eacute;rminos de acciones
        positivas generadas, valores de agua, CO2 y energ&iacute;a ahorrada;
        contenidos educativos y el registro de buenas acciones; impacto positivo
        alcanzado en t&eacute;rminos de ahorro personal.
      </span>
    </p>
    <p class="c8 c18">
      <span class="c14"
        >Los datos cualitativos y cuantitativos sobre el uso de la plataforma,
        las horas de formaci&oacute;n y los ODS (</span
      ><span class="c14 c22">Objetivos de Desarrollo Sostenible</span
      ><span class="c14"
        >) ser&aacute;n puestos a disposici&oacute;n y tratados por Enel de
        forma agregada.</span
      >
    </p>
    <p class="c17 c19"><span class="c0"></span></p>
    <p class="c17">
      <span class="c0"
        >El tratamiento de Datos Personales para los fines de esta
        informaci&oacute;n se refiere a cualquier operaci&oacute;n o conjunto de
        operaciones, realizadas con o sin la ayuda de procesos automatizados y
        aplicados a Datos Personales, tales como la recopilaci&oacute;n,
        registro, organizaci&oacute;n, estructuraci&oacute;n,
        conservaci&oacute;n, adaptaci&oacute;n o modificaci&oacute;n,
        extracci&oacute;n, consulta, uso, comunicaci&oacute;n mediante
        transmisi&oacute;n, difusi&oacute;n o cualquier otro forma de puesta a
        disposici&oacute;n, comparaci&oacute;n o interconexi&oacute;n,
        limitaci&oacute;n, eliminaci&oacute;n o destrucci&oacute;n.
      </span>
    </p>
    <p class="c17 c19"><span class="c0"></span></p>
    <p class="c17">
      <span class="c0"
        >Le informamos que dichos Datos Personales ser&aacute;n tratados de
        forma manual y/o con soporte inform&aacute;tico o
        telem&aacute;tico.</span
      >
    </p>
    <p class="c17 c19"><span class="c0"></span></p>
    <p class="c17">
      <span class="c0"
        >El Titular ha adoptado medidas t&eacute;cnicas y organizativas
        encaminadas a asegurar un nivel de seguridad adecuado al riesgo, de
        conformidad con lo dispuesto en el art. &nbsp;32 del RGPD, destinado a
        prevenir y combatir la p&eacute;rdida de Datos Personales as&iacute;
        como los usos il&iacute;citos o incorrectos o los accesos no
        autorizados.</span
      >
    </p>
    <p class="c8 c12"><span class="c0"></span></p>
    <ul class="c5 lst-kix_list_2-0 start">
      <li class="c29 li-bullet-0">
        <span class="c1 c10">&nbsp;Finali</span
        ><span class="c1">dad y base jur&iacute;dica del tratamiento</span
        ><span class="c1 c10">&nbsp;</span>
      </li>
    </ul>
    <p class="c31 c18">
      <span class="c0"
        >El Titular procesar&aacute; sus Datos Personales para fines
        relacionados con el uso de Aworld, la plataforma innovadora integrada en
        los servicios ME que tiene como objetivo difundir una cultura de la
        sostenibilidad y crear un valor compartido.</span
      >
    </p>
    <p class="c8 c18">
      <span class="c0"
        >La base jur&iacute;dica del tratamiento est&aacute; constituida por el
        consentimiento manifestado por el interesado, marcando la
        correspondiente casilla, en la fase de acceso a la plataforma para la
        finalidad arriba mencionada.</span
      >
    </p>
    <p class="c8 c18">
      <span class="c14"
        >El consentimiento podr&aacute; revocarse en cualquier momento en la
        secci&oacute;n Perfil de la plataforma y la revocaci&oacute;n
        dar&aacute; lugar a la eliminaci&oacute;n de la cuenta y la
        anonimizaci&oacute;n de los datos cualitativos y cuantitativos sobre el
        uso de la plataforma, los contenidos de formaci&oacute;n e
        informaci&oacute;n utilizados y los ODS tratados.
      </span>
    </p>
    <p class="c8 c12"><span class="c0"></span></p>
    <ul class="c5 lst-kix_list_4-0 start">
      <li class="c21 li-bullet-0">
        <span class="c1 c10">&nbsp;Destinatarios de Dat</span
        ><span class="c1">os</span><span class="c1 c10">&nbsp;Personal</span
        ><span class="c1">es</span>
      </li>
    </ul>
    <p class="c31 c18">
      <span class="c4"
        >Sus Datos Personales podr&aacute;n ser accesibles para los fines
        mencionados anteriormente:</span
      >
    </p>
    <ol class="c5 lst-kix_list_9-0 start" start="1">
      <li class="c15 li-bullet-1">
        <span class="c3"
          >a los empleados y colaboradores de Enel, en calidad de personas
          autorizadas al tratamiento de datos </span
        ><span class="c4">personales; </span>
      </li>
      <li class="c15 li-bullet-2">
        <span class="c3">a las </span><span class="c4">empresas</span
        ><span class="c3">&nbsp;del Gruppo Enel</span
        ><span class="c4"
          >&nbsp;presentes en el territorio de la Uni&oacute;n Europea en
          calidad de titulares aut&oacute;nomos o responsables del tratamiento,
          as&iacute; como sus empleados y colaboradores espec&iacute;ficamente
          designados como Personas Autorizadas al tratamiento;</span
        >
      </li>
      <li class="c15 li-bullet-2">
        <span class="c3">a</span
        ><span class="c4">&nbsp;terceras empresas u otros sujetos</span
        ><span class="c3">&nbsp;(&ldquo;</span><span class="c1 c10">Ter</span
        ><span class="c1">ceros</span
        ><span class="c3"
          >&rdquo;) que realicen algunas actividades subcontratad</span
        ><span class="c4">as por cuenta del Titular, como la </span
        ><span class="c3"
          >Societ&agrave; Aworld S.r.l. Societ&agrave; Benefit, en calidad de </span
        ><span class="c4"
          >responsables del tratamiento, as&iacute; como a sus empleados y
          colaboradores espec&iacute;ficamente designados como Personas
          Autorizadas al tratamiento.</span
        >
      </li>
    </ol>
    <p class="c19 c24"><span class="c3"></span></p>
    <p class="c24 c18">
      <span class="c3"
        >La Societ&agrave; Aworld S.r.l. Societ&agrave; Benefit pro</span
      ><span class="c4">porcionar&aacute; peri&oacute;dicamente</span
      ><span class="c3">&nbsp;a Enel </span
      ><span class="c4"
        >informes con datos cualitativos y cuantitativos agregados sobre el uso
        de la plataforma, los contenidos de formaci&oacute;n e
        informaci&oacute;n utilizados y los ODS tratados</span
      ><span class="c3">.</span>
    </p>
    <p class="c13"><span class="c3"></span></p>
    <p class="c31 c18">
      <span class="c4">Los</span><span class="c3">&nbsp;Responsab</span
      ><span class="c4">les</span><span class="c3">&nbsp;</span
      ><span class="c4">y</span><span class="c3">&nbsp;</span
      ><span class="c4">las</span><span class="c3">&nbsp;</span
      ><span class="c4">Personas</span><span class="c3">&nbsp;Autori</span
      ><span class="c4">zadas para el tratamiento, </span
      ><span class="c3">&nbsp;designadas eventu</span
      ><span class="c4"
        >almente, recibir&aacute;n instrucciones de Enel encaminadas a la
        adopci&oacute;n de las medidas de seguridad adecuadas, con el fin de
        garantizar la confidencialidad, seguridad e integridad de los
        datos</span
      ><span class="c3">.</span>
    </p>
    <ul class="c5 lst-kix_list_6-0 start">
      <li class="c6 li-bullet-0">
        <span class="c1 c10">Trasfer</span><span class="c1">encia</span
        ><span class="c1 c10">&nbsp;de Dat</span><span class="c1">os</span
        ><span class="c1 c10">&nbsp;Personal</span><span class="c1">es</span
        ><span class="c1 c10">&nbsp;</span>
      </li>
    </ul>
    <p class="c8 c18">
      <span class="c0"
        >Sus Datos Personales se procesar&aacute;n dentro de la Uni&oacute;n
        Europea y se almacenar&aacute;n en servidores ubicados dentro de la
        Uni&oacute;n Europea. Los mismos datos podr&aacute;n ser tratados en
        pa&iacute;ses fuera de la Uni&oacute;n Europea, siempre que se garantice
        un nivel adecuado de protecci&oacute;n, reconocido por la
        correspondiente decisi&oacute;n de adecuaci&oacute;n espec&iacute;fica
        de la Comisi&oacute;n Europea.</span
      >
    </p>
    <p class="c8 c18">
      <span class="c0"
        >Cualquier transferencia de Datos Personales a pa&iacute;ses no
        pertenecientes a la UE, en ausencia de la correspondiente
        decisi&oacute;n de adecuaci&oacute;n de la Comisi&oacute;n Europea, solo
        ser&aacute; posible si los Titulares y Responsables involucrados
        proporcionan garant&iacute;as adecuadas de naturaleza contractual,
        incluidas normas corporativas vinculantes (&ldquo;Binding Corporate
        Rules&rdquo;) y cl&aacute;usulas contractuales est&aacute;ndar de
        protecci&oacute;n de datos.
      </span>
    </p>
    <p class="c8 c18">
      <span class="c0"
        >La transferencia de sus Datos Personales a terceros pa&iacute;ses fuera
        de la Uni&oacute;n Europea, en ausencia de una decisi&oacute;n adecuada
        u otras medidas adecuadas descritas anteriormente, solo ser&aacute;n
        efectuadas cuando usted haya dado su consentimiento expl&iacute;cito o
        en los casos previstos por el RGPD y tratados en su inter&eacute;s. En
        estos casos, le informamos que, si bien el Gruppo Enel adopta
        instrucciones operativas comunes a todos los pa&iacute;ses en los que
        opera, la transferencia de sus Datos Personales podr&iacute;a ser
        expuesta a riesgos relacionados con las peculiaridades de la
        legislaci&oacute;n local en materia de procesamientos de Datos
        Personales.</span
      >
    </p>
    <p class="c8 c12"><span class="c0"></span></p>
    <ul class="c5 lst-kix_list_7-0 start">
      <li class="c6 li-bullet-0">
        <span class="c1 c10">Periodo di conserva</span
        ><span class="c1">ci&oacute;n de Datos Personales</span>
      </li>
    </ul>
    <p class="c8 c18">
      <span class="c0"
        >Los Datos Personales tratados con las finalidades anteriormente citadas
        se conservar&aacute;n respetando los principios de proporcionalidad y
        necesidad, y en todo caso, hasta la consecuci&oacute;n de los fines del
        tratamiento y en el cumplimientos de las obligaciones legales
        establecidas. En cualquier caso, un m&aacute;ximo de 6 meses desde la
        terminaci&oacute;n del contrato entre Enel y Aworld.</span
      >
    </p>
    <p class="c8 c19"><span class="c3 c23"></span></p>
    <ul class="c5 lst-kix_list_8-0 start">
      <li class="c6 li-bullet-0">
        <span class="c1 c10">&nbsp;D</span
        ><span class="c1">erechos de los interesados</span
        ><span class="c1 c10">&nbsp;</span>
      </li>
    </ul>
    <p class="c8 c18">
      <span class="c0"
        >Seg&uacute;n los art&iacute;culos 15 &ndash; 22 del RGPD, en
        relaci&oacute;n con los Datos Personales comunicados, usted tiene
        derecho a:
      </span>
    </p>
    <ol class="c5 lst-kix_list_10-0 start" start="1">
      <li class="c20 li-bullet-3">
        <span class="c3">acceder y s</span
        ><span class="c4">olicitar una copia;</span>
      </li>
      <li class="c20 li-bullet-3">
        <span class="c4">solicitar rectificaci&oacute;n</span
        ><span class="c3">;</span>
      </li>
      <li class="c20 li-bullet-3">
        <span class="c4">solicitar la eliminaci&oacute;n</span
        ><span class="c3">;</span>
      </li>
      <li class="c20 li-bullet-4">
        <span class="c4">obtener la limitaci&oacute;n del tratamiento</span
        ><span class="c3">;</span>
      </li>
      <li class="c20 li-bullet-4">
        <span class="c4">oponerse al tratamiento</span
        ><span class="c3">; </span>
      </li>
      <li class="c20 li-bullet-4">
        <span class="c4"
          >recibir dichos datos en un formato estructurado, de uso com&uacute;n
          y legibles por un dispositivo autom&aacute;tico y transmitirlos sin
          impedimentos a otro titular del tratamiento cuando sea
          t&eacute;cnicamente factible</span
        ><span class="c3">.</span>
      </li>
    </ol>
    <p class="c8 c19"><span class="c0"></span></p>
    <p class="c8">
      <span class="c0"
        >Para obtener m&aacute;s informaci&oacute;n sobre sus Datos Personales,
        puede ponerse en contacto con el Delegado de Protecci&oacute;n de Datos
        Personales de Enel, a quien puede contactar en la siguiente
        direcci&oacute;n de correo electr&oacute;nico: dpoenelhr@enel.com</span
      >
    </p>
    <p class="c8 c12"><span class="c0"></span></p>
    <p class="c8">
      <span class="c0"
        >Finalmente le recordamos que si cree que el tratamiento de sus datos se
        ha realizado de forma uqe no se ajusta al Reglamento Europeo
        &nbsp;679/2016 (RGPD) tiene derecho a presentar una reclamaci&oacute;n
        ante el Garante para la Protecci&oacute;n de Datos Personales,
        utilizando el m&eacute;todo que se considere m&aacute;s adecuado entre
        los siguientes:</span
      >
    </p>
    <p class="c8 c19"><span class="c0"></span></p>
    <ol class="c5 lst-kix_list_11-0 start" start="1">
      <li class="c26 li-bullet-0">
        <span class="c4">entrega en mano en las oficinas del</span
        ><span class="c3">&nbsp;Garante (</span
        ><span class="c4"
          >en la direcci&oacute;n que se indica a continuaci&oacute;n</span
        ><span class="c3">); </span>
      </li>
      <li class="c26 c23 li-bullet-0">
        <span class="c4">carta certificada con acuse de recibo dirigida a</span
        ><span class="c3">: Garante p</span
        ><span class="c4">ara la protecci&oacute;n de datos personales,</span
        ><span class="c3">&nbsp;Piazza Venezia, 11 - 00187 Roma; </span>
      </li>
      <li class="c26 c23 li-bullet-0">
        <span class="c4"
          >mensaje de correo electr&oacute;nico certificado dirigido a</span
        ><span class="c3">: </span
        ><span class="c30 c14"
          ><a class="c2" href="mailto:protocollo@pec.gpdp.it"
            >protocollo@pec.gpdp.it</a
          ></span
        ><span class="c3">, o </span
        ><span class="c14 c30"
          ><a class="c2" href="mailto:protocollo@gpdp.it"
            >protocollo@gpdp.it</a
          ></span
        >
      </li>
    </ol>
    <p class="c8 c19"><span class="c9"></span></p>
    <p class="c8 c12"><span class="c9"></span></p>
    <p class="c31 c19"><span class="c0"></span></p>
  </body>
</html>
`
