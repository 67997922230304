import { LinearProgress } from "@mui/material"
import { useState } from "react"
import { Textfit } from "react-textfit"
import EmbedSlide from "../../../../models/slides/embedSlide"
import {
  numberOfColumns,
  numberOfRows,
  slideHeight,
  slideWidth,
} from "../../../../services/config/constants"
import "../../../../styles/loading.css"

const EmbedLayout = ({ slide }: { slide: EmbedSlide }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundImage: `url(${slide.background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 30,
        boxSizing: "border-box",
      }}
    >
      <div
        style={{
          width: slideWidth,
          maxWidth: slideWidth,
          height: slideHeight,
          maxHeight: slideHeight,
          position: "relative",
        }}
      >
        <Text slide={slide} />
        <Embed slide={slide} />
        <CaptionTitle slide={slide} />
        <Caption slide={slide} />
      </div>
    </div>
  )
}

const Text = ({ slide }: { slide: EmbedSlide }) => {
  return (
    <Textfit
      style={{
        color: slide.textColor,
        position: "absolute",
        top: `calc(${slideHeight}px / ${numberOfRows} * 11)`,
        left: `calc(${slideWidth}px / ${numberOfColumns} * 2)`,
        right: `calc(${slideWidth}px / ${numberOfColumns} * 2)`,
        fontWeight: 600,
        textAlign: "center",
        lineHeight: 1.2,
        height: `calc(${slideHeight}px / ${numberOfRows} * 5)`,
      }}
      min={19}
      max={40}
      mode="multi"
    >
      <div dangerouslySetInnerHTML={{ __html: slide.text }} />
    </Textfit>
  )
}

const Embed = ({ slide }: { slide: EmbedSlide }) => {
  // video loading
  const [loading, setLoading] = useState<boolean>(true)

  return (
    <div
      style={{
        position: "absolute",
        top: `calc(${slideHeight}px / ${numberOfRows} * 17)`,
        left: `calc(${slideWidth}px / ${numberOfColumns} * 1)`,
        right: `calc(${slideWidth}px / ${numberOfColumns} * 1)`,
        width: `calc(${slideWidth}px / ${numberOfColumns} * 13)`,
        height: `calc(${slideHeight}px / ${numberOfRows} * 10)`,
        zIndex: 2,
      }}
    >
      {loading && (
        <LinearProgress
          style={{ position: "absolute", top: 0, width: "100%" }}
          classes={{
            colorPrimary: "linear-progress-background",
            barColorPrimary: "linear-progress-bar",
          }}
        />
      )}
      <iframe
        allowFullScreen
        onLoad={() => {
          setLoading(false)
        }}
        src={
          "https://www.youtube.com/embed/" +
          slide.embedUrl.slice(slide.embedUrl.indexOf("=") + 1)
        }
        style={{
          width: "100%",
          height: "100%",
        }}
      />
    </div>
  )
}

const CaptionTitle = ({ slide }: { slide: EmbedSlide }) => {
  return (
    <div
      style={{
        position: "absolute",
        top: `calc(${slideHeight}px / ${numberOfRows} * 28.8)`,
        left: `calc(${slideWidth}px / ${numberOfColumns} * 1)`,
        right: `calc(${slideWidth}px / ${numberOfColumns} * 1)`,
        height: `calc(${slideHeight}px / ${numberOfRows} * 1)`,
        fontWeight: 600,
        fontSize: 18,
        lineHeight: "17px",
        color: slide.textColor,
        textAlign: "center",
      }}
    >
      {slide.captionTitle}
    </div>
  )
}

const Caption = ({ slide }: { slide: EmbedSlide }) => {
  return (
    <div
      style={{
        position: "absolute",
        top: `calc(${slideHeight}px / ${numberOfRows} * 30)`,
        left: `calc(${slideWidth}px / ${numberOfColumns} * 1)`,
        right: `calc(${slideWidth}px / ${numberOfColumns} * 1)`,
        fontWeight: 300,
        fontSize: 16,
        lineHeight: "17px",
        color: slide.textColor,
        textAlign: "center",
      }}
    >
      {slide.caption}
    </div>
  )
}

export default EmbedLayout
