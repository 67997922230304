import { motion } from "framer-motion"
import { colors } from "../../../services/config/colors"
import { ButtonBase, Stack } from "@mui/material"
import { Dispatch, SetStateAction, useContext } from "react"
import arrowLeftIcon from "../../../assets/icons/arrow-left.svg"
import menuBackground from "../../../assets/images/menu-background-mobile.png"
import MenuButtonMobile from "./MenuButtonMobile"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { ChallengeContext } from "../../../controllers/challenge"

const MenuMobile = ({
  open,
  setOpen,
}: {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { challenge } = useContext(ChallengeContext)

  return (
    <motion.div
      animate={open ? "open" : "closed"}
      variants={{
        open: { x: 0 },
        closed: { x: "-100%" },
      }}
      aria-hidden={open ? "false" : "true"}
      role="dialog"
      transition={{ type: "spring", damping: 20, stiffness: 200 }}
      initial={false}
      style={{
        width: "calc(100% + 40px)",
        height: "100%",
        backgroundColor: colors.backgroundWhite,
        position: "fixed",
        left: -40,
        zIndex: 15,
        overflowY: "scroll",
      }}
    >
      <ButtonBase
        style={{
          width: 48,
          height: 48,
          borderRadius: 12,
          position: "absolute",
          top: 26,
          left: 56,
          zIndex: 1,
        }}
        onClick={() => {
          setOpen(false)
        }}
        aria-label={t("close")}
        aria-hidden={open ? "false" : "true"}
        tabIndex={open ? 0 : -1}
      >
        <img src={arrowLeftIcon} style={{ width: 14 }} alt="" />
      </ButtonBase>
      <Stack
        style={{
          width: "calc(100% - 72px)",
          marginLeft: 56,
          marginTop: 100,
          position: "relative",
          zIndex: 1,
        }}
      >
        <MenuButtonMobile
          title={t("footprint")}
          onClick={() => {
            setOpen(false)
            setTimeout(() => {
              navigate("/footprint")
            }, 200)
          }}
          hidden={open ? "false" : "true"}
        />
        <MenuButtonMobile
          title={t("actions")}
          onClick={() => {
            setOpen(false)
            setTimeout(() => {
              navigate("/actions")
            }, 200)
          }}
          hidden={open ? "false" : "true"}
        />
        <MenuButtonMobile
          title={t("journey")}
          onClick={() => {
            setOpen(false)
            setTimeout(() => {
              navigate("/journey")
            }, 200)
          }}
          hidden={open ? "false" : "true"}
        />
        <MenuButtonMobile
          title={t("challenge")}
          withBorder={false}
          disabled={!challenge}
          onClick={() => {
            setOpen(false)
            setTimeout(() => {
              navigate("/challenge")
            }, 200)
          }}
          hidden={open ? "false" : "true"}
        />
      </Stack>
      <img
        src={menuBackground}
        style={{
          width: "100%",
          position: "absolute",
          bottom: 0,
          objectFit: "cover",
        }}
        alt=""
      />
    </motion.div>
  )
}

export default MenuMobile
