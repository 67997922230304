import EpisodeBody from "./common/EpisodeBody"
import EpisodeTimeline from "./common/EpisodeTimeline"
import Slide from "../../models/slide"
import { CSSProperties, useContext } from "react"
import { motion } from "framer-motion"
import { MainContext } from "../../controllers/main"
import { Alert, ButtonBase } from "@mui/material"
import { availableLanguages } from "../../services/config/constants"
import FadeFromBottom from "../animations/FadeFromBottom"
import { useTranslation } from "react-i18next"
import { JourneysContext } from "../../controllers/journeys"
import QuizResult from "../../models/quizResult"
import { useNavigate } from "react-router-dom"
import closeIcon from "../../assets/icons/close.png"
import { colors } from "../../services/config/colors"
import DailyEpisodeFeedback from "../feedbacks/common/DailyEpisodeFeedback"
import { focusElement } from "../../services/utils/utils"

const DailyEpisodeMobile = ({
  currentSlide,
  currentSlideIndex,
  numberOfSlides,
  numberOfQuiz,
  totalSlides,
  currentSlideQuizResult,
  style,
}: {
  currentSlide: Slide
  currentSlideIndex: number
  numberOfSlides: number
  numberOfQuiz: number
  totalSlides: number
  currentSlideQuizResult?: QuizResult
  style?: CSSProperties
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { isMobile } = useContext(MainContext)
  const { dailyEpisode } = useContext(JourneysContext)

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        position: "relative",
        overflow: "hidden",
        ...style,
      }}
    >
      {/* timeline */}
      <EpisodeTimeline
        currentSlideIndex={currentSlideIndex}
        numberOfSlides={numberOfSlides}
        numberOfQuiz={numberOfQuiz}
      />
      {/* close button */}
      {isMobile && (
        <ButtonBase
          style={{
            width: 32,
            height: 32,
            borderRadius: 16,
            position: "absolute",
            top: 35,
            right: 16,
            zIndex: 2,
          }}
          onClick={() => {
            navigate("/")
          }}
          aria-label={t("close")}
        >
          <div
            style={{
              width: 30,
              height: 30,
              backgroundColor: colors.backgroundWhite,
              borderRadius: 15,
              boxShadow: "0px 0px 24px 0px rgba(17, 67, 97, 0.16)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img src={closeIcon} style={{ width: 9, height: 9 }} alt="" />
          </div>
        </ButtonBase>
      )}
      {/* mobile back button */}
      {isMobile && (
        <ButtonBase
          aria-label={t("previous_slide")}
          disableRipple
          style={{
            width: "30%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 1,
          }}
          onClick={() => {
            if (
              !isMobile ||
              !currentSlide ||
              (currentSlide &&
                currentSlide.slideType === "QuizSlide" &&
                !dailyEpisode!.result.quiz.some(
                  (item) => item.slideId === currentSlide.id
                )) ||
              (currentSlide &&
                currentSlide.slideType === "QuizSlide" &&
                !dailyEpisode!.result.completed)
            ) {
              return
            }

            if (currentSlideIndex > 0) {
              focusElement("slide-container-mobile")

              const pathname = window.location.pathname
              navigate(
                pathname.slice(0, pathname.lastIndexOf("/")) +
                  "/" +
                  (currentSlideIndex - 1)
              )
            }
          }}
        />
      )}
      {/* episode body */}
      <div
        id="slide-container-mobile"
        style={{ width: "100%", height: "100%", minHeight: "100%" }}
        aria-label={
          currentSlideIndex === totalSlides
            ? t("end_of_episode_feedback")
            : t("slide_count_of_total", {
                count: currentSlideIndex + 1,
                total: totalSlides,
              })
        }
        role={isMobile ? "region" : "none"}
        aria-roledescription={isMobile ? t("content") : undefined}
        tabIndex={isMobile ? 0 : undefined}
      >
        {currentSlideIndex === totalSlides && dailyEpisode!.result.completed ? (
          <DailyEpisodeFeedback />
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            style={{ width: "100%", height: "100%" }}
          >
            <EpisodeBody
              currentSlide={currentSlide}
              currentEpisode={dailyEpisode!}
              isLastEpisode={false}
              isDailyEpisode={true}
              quizResult={currentSlideQuizResult}
            />
          </motion.div>
        )}
      </div>
      {/* mobile next button */}
      {isMobile && (
        <ButtonBase
          aria-label={t("next_slide")}
          disableRipple
          style={{
            width: "70%",
            height: "100%",
            position: "absolute",
            top: 0,
            right: 0,
            zIndex: 1,
          }}
          onClick={() => {
            if (
              !isMobile ||
              !currentSlide ||
              (currentSlide &&
                currentSlide.slideType === "QuizSlide" &&
                !dailyEpisode!.result.quiz.some(
                  (item) => item.slideId === currentSlide.id
                ))
            ) {
              return
            }

            if (currentSlideIndex + 1 <= totalSlides) {
              focusElement("slide-container-mobile")

              const pathname = window.location.pathname
              navigate(
                pathname.slice(0, pathname.lastIndexOf("/")) +
                  "/" +
                  (currentSlideIndex + 1)
              )
            }
          }}
        />
      )}
      {/* alert if the user has completed the current quiz in another language (the results are not available) */}
      {currentSlide &&
      currentSlide.slideType === "QuizSlide" &&
      dailyEpisode!.result.completed &&
      !dailyEpisode!.result.quiz.find(
        (item) => item.slideId === currentSlide.id
      ) ? (
        <FadeFromBottom
          style={{
            width: "calc(100% - 32px)",
            position: "absolute",
            bottom: 16,
            left: 16,
            zIndex: 20,
          }}
        >
          <Alert
            severity="warning"
            style={{
              width: "100%",
              fontSize: 13,
            }}
          >
            {t("episode_completed_in_another_language", {
              lang: availableLanguages.find(
                (item) => item.code === dailyEpisode!.result.quiz[0].lang
              )
                ? t(
                    availableLanguages.find(
                      (item) => item.code === dailyEpisode!.result.quiz[0].lang
                    )!.label
                  ).toLowerCase()
                : "unknown",
            })}
          </Alert>
        </FadeFromBottom>
      ) : null}
    </div>
  )
}

export default DailyEpisodeMobile
