import { ButtonBase, Stack } from "@mui/material"
import {
  CSSProperties,
  Dispatch,
  KeyboardEvent,
  SetStateAction,
  useRef,
  useState,
} from "react"
import Text from "../../global/common/Text"
import { colors } from "../../../services/config/colors"
import { motion } from "framer-motion"
import infoPrimaryIcon from "../../../assets/icons/info-primary.svg"
import { useTranslation } from "react-i18next"

const MissionsTabs = ({
  tabs,
  selectedTab,
  setSelectedTab,
  infoButtonOnClick,
  style,
}: {
  tabs: string[]
  selectedTab: number
  setSelectedTab: Dispatch<SetStateAction<number>>
  infoButtonOnClick: () => void
  style?: CSSProperties
}) => {
  const { t } = useTranslation()

  // focus tab content (accessibility)
  const focusTabContent = () => {
    const container = document.getElementById("missions-desktop-container")!
    container.tabIndex = 0
    container.focus()
    setTimeout(() => container.removeAttribute("tabindex"), 500)
  }

  // refs for all tabs
  const tabRefs = useRef<HTMLButtonElement[]>([])

  // focus tab helper
  const [selectedIndex, setSelectedIndex] = useState<number>(0)

  const focusTab = (index: number) => {
    const tab = tabRefs.current[index]
    if (tab) {
      tab.focus()
    }
  }

  // onKeyDown handler for tab elements
  const onKeyDown = (event: KeyboardEvent<HTMLButtonElement>) => {
    const count = tabs.length
    const nextTab = () => focusTab((selectedIndex + 1) % count)
    const prevTab = () => focusTab((selectedIndex - 1 + count) % count)
    const firstTab = () => focusTab(0)
    const lastTab = () => focusTab(count - 1)

    const keyMap = {
      ArrowRight: nextTab,
      ArrowLeft: prevTab,
      Home: firstTab,
      End: lastTab,
    }

    if (
      event.key === "ArrowRight" ||
      event.key === "ArrowLeft" ||
      event.key === "Home" ||
      event.key === "End"
    ) {
      const action = keyMap[event.key]
      if (action) {
        event.preventDefault()
        action()
      }
    }
  }

  // if user is using screen reader, based on "onFocusVisible" event
  const screenReaderEnabled = useRef<boolean>(false)

  return (
    <Stack
      direction="row"
      style={{ width: "100%", height: 37, position: "relative", ...style }}
    >
      {tabs.map((tab, index) => (
        <ButtonBase
          key={index}
          disableRipple
          style={{
            width: 176,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            borderRadius: 4,
          }}
          onClick={() => {
            setSelectedTab(index)

            if (screenReaderEnabled.current) {
              focusTabContent()
            }
          }}
          aria-label={tab}
          role="tab"
          aria-selected={selectedTab === index ? "true" : "false"}
          ref={(element) => (tabRefs.current[index] = element!)}
          onKeyDown={onKeyDown}
          onFocus={() => setSelectedIndex(index)}
          onFocusVisible={() => {
            screenReaderEnabled.current = true
          }}
          onBlur={() => {
            screenReaderEnabled.current = false
          }}
        >
          <Text
            fontSize={20}
            fontWeight={selectedTab === index ? 700 : 400}
            lineHeight="27px"
            color={selectedTab === index ? colors.primary : colors.text}
          >
            {tab}
          </Text>
        </ButtonBase>
      ))}
      <motion.div
        animate={selectedTab.toString()}
        variants={tabs.reduce(
          (obj, current, index) => ({
            ...obj,
            [index]: {
              x: selectedTab * 176,
            },
          }),
          {}
        )}
        transition={{ x: { type: "spring", damping: 18, stiffness: 160 } }}
        initial={false}
        style={{
          width: 176,
          height: 2,
          borderRadius: 10,
          backgroundColor: colors.primary,
          position: "absolute",
          bottom: 0,
        }}
      />
      {/* info button */}
      <ButtonBase
        aria-label={t("what_is_a_mission")}
        style={{
          width: 60,
          height: 60,
          position: "absolute",
          right: 0,
          bottom: 0,
          backgroundColor: colors.background,
          borderRadius: 15,
        }}
        onClick={infoButtonOnClick}
      >
        <img
          src={infoPrimaryIcon}
          style={{ width: 25, height: 25 }}
          alt=""
          aria-hidden="true"
        />
      </ButtonBase>
    </Stack>
  )
}

export default MissionsTabs
