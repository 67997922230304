import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react"
import { deleteMethod, get, put } from "../services/api/api"
import {
  parseAvatarsListData,
  parseUserData,
} from "../services/utils/parseFunctions"
import User from "../models/user"
import { cacheImages } from "../services/utils/utils"
import { MainContext } from "./main"
import Avatar from "../models/avatar"

interface UsersContextInterface {
  leaderboardLoading: boolean
  leaderboardUpdating: boolean
  leaderboardError: boolean
  leaderboard: User[]
  leaderboardNextToken: string | null
  getLeaderboard: (
    withLoading?: boolean,
    withNextToken?: boolean
  ) => Promise<boolean>
  avatarsLoading: boolean
  avatarsError: boolean
  avatars: Avatar[]
  changeAvatar: (newAvatar: string) => Promise<boolean>
  deleteUser: () => Promise<boolean>
}

const UsersContext = createContext<UsersContextInterface>({
  leaderboardLoading: true,
  leaderboardUpdating: false,
  leaderboardError: false,
  leaderboard: [],
  leaderboardNextToken: null,
  getLeaderboard: async () => true,
  avatarsLoading: true,
  avatarsError: false,
  avatars: [],
  changeAvatar: async () => true,
  deleteUser: async () => true,
})

const UsersController = ({ children }: { children: ReactNode }) => {
  const { user, setUser } = useContext(MainContext)

  // loadings
  const [leaderboardLoading, setLeaderboardLoading] = useState<boolean>(true)
  const [leaderboardUpdating, setLeaderboardUpdating] = useState<boolean>(true)
  const [avatarsLoading, setAvatarsLoading] = useState<boolean>(true)

  // errors
  const [leaderboardError, setLeaderboardError] = useState<boolean>(false)
  const [avatarsError, setAvatarsError] = useState<boolean>(false)

  // states
  const [leaderboard, setLeaderboard] = useState<User[]>([])
  const [leaderboardNextToken, setLeaderboardNextToken] = useState<
    string | null
  >(null)
  const [avatars, setAvatars] = useState<Avatar[]>([])

  // get leaderboard
  const getLeaderboard = async (withLoading = true, withNextToken = true) => {
    if (withLoading) {
      setLeaderboardLoading(true)
    }
    setLeaderboardUpdating(true)
    setLeaderboardError(false)

    try {
      const { data } = await get(
        leaderboardNextToken && withNextToken
          ? `/web/mission/point/leaderboard?nextToken=${leaderboardNextToken}`
          : "/web/mission/point/leaderboard"
      )

      // parse data
      data.items.forEach((item: any) => {
        parseUserData(item)
      })

      if (leaderboardNextToken && withNextToken) {
        console.log("leaderboard", [...leaderboard, ...data.items])
        setLeaderboard((current) => [...current, ...data.items])
      } else {
        console.log("leaderboard", data.items)
        setLeaderboard(data.items)
      }
      setLeaderboardNextToken(data.nextToken)

      setLeaderboardLoading(false)
      setLeaderboardUpdating(false)

      return true
    } catch (e) {
      console.log("leaderboard error", e)
      setLeaderboardError(true)
      setLeaderboardUpdating(false)

      return false
    }
  }

  // get avatars list
  const getAvatars = async () => {
    try {
      const { data } = await get("/web/user/avatar/list")
      const dataToSet = parseAvatarsListData(data)
      console.log("avatars list", dataToSet)

      // cache avatars
      await cacheImages(dataToSet.map((item) => item.url))

      setAvatars(dataToSet)
      setAvatarsLoading(false)
    } catch (e) {
      console.log("avatars list error", e)
      setAvatarsLoading(false)
      setAvatarsError(true)
    }
  }

  // change user avatar
  const changeAvatar = async (newAvatar: string) => {
    try {
      await put("/web/user/profileimage", { profileImage: newAvatar })

      // update user locally
      user!.profileImage = newAvatar
      setUser({ ...user! })

      // update leaderboard locally
      if (leaderboard.some((item) => item.sub === user!.sub)) {
        leaderboard.find((item) => item.sub === user!.sub)!.profileImage =
          newAvatar
        setLeaderboard([...leaderboard])
      }

      return true
    } catch (e) {
      console.log("profile image change error", e)

      return false
    }
  }

  // delete user
  const deleteUser = async () => {
    try {
      await deleteMethod("/web/user/delete")

      return true
    } catch (e) {
      console.log("user delete error", e)

      return false
    }
  }

  // initial fetch
  useEffect(() => {
    getLeaderboard()
    getAvatars()
  }, [])

  return (
    <UsersContext.Provider
      value={{
        leaderboardLoading,
        leaderboardUpdating,
        leaderboardError,
        leaderboard,
        leaderboardNextToken,
        getLeaderboard,
        avatarsLoading,
        avatarsError,
        avatars,
        changeAvatar,
        deleteUser,
      }}
    >
      {children}
    </UsersContext.Provider>
  )
}
export { UsersController, UsersContext }
